import { SurveyResponse } from '../../services/model';
import {validateDate} from '../csvUtil';
import {exampleParticipantCsv, participantFormConstants} from '../participantFormConstants'
import { participantFormValidationConstants } from './participantFormValidationConstants';

export const validateParticipant = (data: any) => {
	let isValid: boolean = true;
	const errors: string[] = [];

	const templateKeys = Object.keys(exampleParticipantCsv[0])

	// tslint:disable-next-line: forin
	for (const key in data) {
		const index = templateKeys.indexOf(key);
		if (index > -1) {
			templateKeys.splice(index, 1)
		}
		switch(key) {
			case participantFormConstants.ACTIVITY_ID_KEY:
				const activityId = data[participantFormConstants.ACTIVITY_ID_KEY];
				if (activityId && !participantFormValidationConstants.ACTIVITY_ID_REGEX.test(activityId)) {
					isValid = false;
					errors.push(participantFormValidationConstants.ACTIVITY_ID_ERROR);
				}
				if (!activityId) {
					isValid = false;
					errors.push(participantFormConstants.ACTIVITY_ID_KEY + ' is required');
				}
				break;
			case participantFormConstants.FIRST_NAME_KEY:
				const first = data[participantFormConstants.FIRST_NAME_KEY];
				if (first && !participantFormValidationConstants.FIRST_NAME_REGEX.test(first)) {
					isValid = false;
					errors.push(participantFormValidationConstants.FIRST_NAME_ERROR);
				}
				if (!first) {
					isValid = false;
					errors.push(participantFormConstants.FIRST_NAME_KEY + ' is required');
				}
				break;
			case participantFormConstants.MIDDLE_INITIAL_KEY:
				const middle = data[participantFormConstants.MIDDLE_INITIAL_KEY];
				if (middle && !participantFormValidationConstants.MIDDLE_INITIAL_REGEX.test(middle)) {
					isValid = false;
					errors.push(participantFormValidationConstants.MIDDLE_INITIAL_ERROR);
				}
				break;
			case participantFormConstants.LAST_NAME_KEY:
				const last = data[participantFormConstants.LAST_NAME_KEY];
				if (last && !participantFormValidationConstants.LAST_NAME_REGEX.test(last)) {
					isValid = false;
					errors.push(participantFormValidationConstants.LAST_NAME_ERROR);
				}
				if (!last) {
					isValid = false;
					errors.push(participantFormConstants.LAST_NAME_KEY + ' is required');
				}
				break;
			case participantFormConstants.DATE_OF_BIRTH_KEY:
				const dob = data[participantFormConstants.DATE_OF_BIRTH_KEY];
				
				if (validateDate(dob)) {
					isValid = false;
					errors.push(participantFormValidationConstants.DATE_OF_BIRTH_ERROR);
				}
				break;
			case participantFormConstants.HOMELESS_KEY:
				const homeless = data[participantFormConstants.HOMELESS_KEY];
				if (homeless && !participantFormValidationConstants.HOMELESS_REGEX.test(homeless)) {
					isValid = false;
					errors.push(participantFormValidationConstants.HOMELESS_ERROR);
				}
				break;
			case participantFormConstants.ADDRESS1_KEY:
				const address1 = data[participantFormConstants.ADDRESS1_KEY];
				if (address1 && !participantFormValidationConstants.ADDRESS1_REGEX.test(address1)) {
					isValid = false;
					errors.push(participantFormValidationConstants.ADDRESS1_ERROR);
				}
				break;
			case participantFormConstants.ADDRESS2_KEY:
				const address2 = data[participantFormConstants.ADDRESS2_KEY];
				if (address2 && !participantFormValidationConstants.ADDRESS2_REGEX.test(address2)) {
					isValid = false;
					errors.push(participantFormValidationConstants.ADDRESS2_ERROR);
				}
				break;
			case participantFormConstants.CITY_KEY:
				const city = data[participantFormConstants.CITY_KEY];
				if (city && !participantFormValidationConstants.CITY_REGEX.test(city)) {
					isValid = false;
					errors.push(participantFormValidationConstants.CITY_ERROR);
				}
				break;
			case participantFormConstants.STATE_KEY:
				const state = data[participantFormConstants.STATE_KEY];
				if (state && !participantFormValidationConstants.STATE_REGEX.test(state)) {
					isValid = false;
					errors.push(participantFormValidationConstants.STATE_ERROR);
				}
				break;
			case participantFormConstants.ZIP_KEY:
				const zip = data[participantFormConstants.ZIP_KEY];
				if (zip && !participantFormValidationConstants.ZIP_REGEX.test(zip)) {
					isValid = false;
					errors.push(participantFormValidationConstants.ZIP_ERROR);
				}
				break;
			case participantFormConstants.MAILING_ADDRESS1_KEY:
				const mailingAddress1 = data[participantFormConstants.MAILING_ADDRESS1_KEY];
				if (mailingAddress1 && !participantFormValidationConstants.MAILING_ADDRESS1_REGEX.test(mailingAddress1)) {
					isValid = false;
					errors.push(participantFormValidationConstants.MAILING_ADDRESS1_ERROR);
				}
				break;
			case participantFormConstants.MAILING_ADDRESS2_KEY:
				const mailingAddress2 = data[participantFormConstants.MAILING_ADDRESS2_KEY];
				if (mailingAddress2 && !participantFormValidationConstants.MAILING_ADDRESS2_REGEX.test(mailingAddress2)) {
					isValid = false;
					errors.push(participantFormValidationConstants.MAILING_ADDRESS2_ERROR);
				}
				break;
			case participantFormConstants.MAILING_CITY_KEY:
				const mailingCity = data[participantFormConstants.MAILING_CITY_KEY];
				if (mailingCity && !participantFormValidationConstants.MAILING_CITY_REGEX.test(mailingCity)) {
					isValid = false;
					errors.push(participantFormValidationConstants.MAILING_CITY_ERROR);
				}
				break;
			case participantFormConstants.MAILING_STATE_KEY:
				const mailingState = data[participantFormConstants.MAILING_STATE_KEY];
				if (mailingState && !participantFormValidationConstants.MAILING_STATE_REGEX.test(mailingState)) {
					isValid = false;
					errors.push(participantFormValidationConstants.MAILING_STATE_ERROR);
				}
				break;
			case participantFormConstants.MAILING_ZIP_KEY:
				const mailingZip = data[participantFormConstants.MAILING_ZIP_KEY];
				if (mailingZip && !participantFormValidationConstants.MAILING_ZIP_REGEX.test(mailingZip)) {
					isValid = false;
					errors.push(participantFormValidationConstants.MAILING_ZIP_ERROR);
				}
				break;
			case participantFormConstants.PHONE_KEY:
				const phone = data[participantFormConstants.PHONE_KEY];
				if (phone && !participantFormValidationConstants.PHONE_REGEX.test(phone)) {
					isValid = false;
					errors.push(participantFormValidationConstants.PHONE_ERROR);
				}
				break;
			case participantFormConstants.EMAIL_KEY:
				const email = data[participantFormConstants.EMAIL_KEY];
				if (email && !participantFormValidationConstants.EMAIL_REGEX.test(email)) {
					isValid = false;
					errors.push(participantFormValidationConstants.EMAIL_ERROR);
				}
				break;
			case participantFormConstants.GENDER_KEY:
				const gender = data[participantFormConstants.GENDER_KEY];
				if (gender && !participantFormValidationConstants.GENDER_REGEX.test(gender)) {
					isValid = false;
					errors.push(participantFormValidationConstants.GENDER_ERROR);
				}
				break;
			case participantFormConstants.ETHNICITY_KEY:
				const ethnicity = data[participantFormConstants.ETHNICITY_KEY];
				if (ethnicity && !participantFormValidationConstants.ETHNICITY_REGEX.test(ethnicity)) {
					isValid = false;
					errors.push(participantFormValidationConstants.ETHNICITY_ERROR);
				}
				break;
			case participantFormConstants.RACES_KEY:
				const races = data[participantFormConstants.RACES_KEY];
				if (races && !participantFormValidationConstants.RACES_REGEX.test(races)) {
					isValid = false;
					errors.push(participantFormValidationConstants.RACES_ERROR);
				}
				break;
      case participantFormConstants.DISABILITIES_KEY:
        const disabilityStatus = data[participantFormConstants.DISABILITY_STATUS_KEY]
        const disabilities = data[participantFormConstants.DISABILITIES_KEY] || "";
        if (disabilityStatus === SurveyResponse.YES && !participantFormValidationConstants.DISABILITIES_REGEX.test(disabilities)) {
          isValid = false;
          errors.push(participantFormValidationConstants.DISABILITIES_ERROR);
        }
        break;
			case participantFormConstants.PREFERRED_LANGUAGE_KEY:
				const preferredLanguage = data[participantFormConstants.PREFERRED_LANGUAGE_KEY];
				if (preferredLanguage && !participantFormValidationConstants.PREFERRED_LANGUAGE_REGEX.test(preferredLanguage)) {
					isValid = false;
					errors.push(participantFormValidationConstants.PREFERRED_LANGUAGE_ERROR);
				}
				break;
			case participantFormConstants.CITIZEN_KEY:
				const citizen = data[participantFormConstants.CITIZEN_KEY];
				if (citizen && !participantFormValidationConstants.CITIZEN_REGEX.test(citizen)) {
					isValid = false;
					errors.push(participantFormValidationConstants.CITIZEN_ERROR);
				}
				break;
			case participantFormConstants.VETERAN_KEY:
				const veteran = data[participantFormConstants.VETERAN_KEY];
				if (veteran && !participantFormValidationConstants.VETERAN_REGEX.test(veteran)) {
					isValid = false;
					errors.push(participantFormValidationConstants.VETERAN_ERROR);
				}
				break;
      case participantFormConstants.DISABILITY_STATUS_KEY:
        const disability = data[participantFormConstants.DISABILITY_STATUS_KEY];
        if (disability && !participantFormValidationConstants.DISABILITY_STATUS_REGEX.test(disability)) {
          isValid = false;
          errors.push(participantFormValidationConstants.DISABILITY_STATUS_ERROR);
        }
        break;
			case participantFormConstants.ELL_KEY:
				const ell = data[participantFormConstants.ELL_KEY];
				if (ell && !participantFormValidationConstants.ELL_REGEX.test(ell)) {
					isValid = false;
					errors.push(participantFormValidationConstants.ELL_ERROR);
				}
				break;
			case participantFormConstants.EDUCATION_KEY:
				const education = data[participantFormConstants.EDUCATION_KEY];
				if (education && !participantFormValidationConstants.EDUCATION_REGEX.test(education)) {
					isValid = false;
					errors.push(participantFormValidationConstants.EDUCATION_ERROR);
				}
        if (!education) {
					isValid = false;
					errors.push(participantFormConstants.EDUCATION_KEY + ' is required');
				}
				break;
			case participantFormConstants.HIGHEST_GRADE_KEY:
				const education1 = data[participantFormConstants.EDUCATION_KEY];
				const highestGrade = data[participantFormConstants.HIGHEST_GRADE_KEY];
				if (!highestGrade && (education1 ==='NO_HIGHSCHOOL')) {
					isValid = false;
				  errors.push(participantFormValidationConstants.HIGHEST_GRADE_ERROR2);
				}
				if (highestGrade && !participantFormValidationConstants.HIGHEST_GRADE_REGEX.test(highestGrade)) {
					isValid = false;
					errors.push(participantFormValidationConstants.HIGHEST_GRADE_ERROR);
				}
				break;
			case participantFormConstants.IN_SCHOOL_KEY:
				const inSchool = data[participantFormConstants.IN_SCHOOL_KEY];
				if (inSchool && !participantFormValidationConstants.IN_SCHOOL_REGEX.test(inSchool)) {
					isValid = false;
					errors.push(participantFormValidationConstants.IN_SCHOOL_ERROR);
				}
        if (!inSchool) {
					isValid = false;
					errors.push(participantFormConstants.IN_SCHOOL_KEY + ' is required');
				}
				break;
			case participantFormConstants.CURRENT_SCHOOL_KEY:
				const currentSchool = data[participantFormConstants.CURRENT_SCHOOL_KEY];
				if (currentSchool && !participantFormValidationConstants.CURRENT_SCHOOL_REGEX.test(currentSchool)) {
					isValid = false;
					errors.push(participantFormValidationConstants.CURRENT_SCHOOL_ERROR);
				}
				break;
			case participantFormConstants.WORKING_KEY:
				const working = data[participantFormConstants.WORKING_KEY];
				if (working && !participantFormValidationConstants.WORKING_REGEX.test(working)) {
					isValid = false;
					errors.push(participantFormValidationConstants.WORKING_ERROR);
				}
				break;
			case participantFormConstants.EMPLOYER_KEY:
				const employer = data[participantFormConstants.EMPLOYER_KEY];
				if (employer && !participantFormValidationConstants.EMPLOYER_REGEX.test(employer)) {
					isValid = false;
					errors.push(participantFormValidationConstants.EMPLOYER_ERROR);
				}
				break;
			case participantFormConstants.EMPLOYMENT_TYPE_KEY:
				const employmentType = data[participantFormConstants.EMPLOYMENT_TYPE_KEY];
				if (employmentType && !participantFormValidationConstants.EMPLOYMENT_TYPE_REGEX.test(employmentType)) {
					isValid = false;
					errors.push(participantFormValidationConstants.EMPLOYMENT_TYPE_ERROR);
				}
				break;
			case participantFormConstants.LAID_OFF_KEY:
				const laidOff = data[participantFormConstants.LAID_OFF_KEY];
				if (laidOff && !participantFormValidationConstants.LAID_OFF_REGEX.test(laidOff)) {
					isValid = false;
					errors.push(participantFormValidationConstants.LAID_OFF_ERROR);
				}
				break;
			case participantFormConstants.LAYOFF_DATE_KEY:
				if (validateDate(data[participantFormConstants.LAYOFF_DATE_KEY])) {
					isValid = false;
					errors.push(participantFormValidationConstants.LAYOFF_DATE_ERROR);
				}
				break;
			case participantFormConstants.LAYOFF_EMPLOYER_KEY:
				const layoffEmployer = data[participantFormConstants.LAYOFF_EMPLOYER_KEY];
				if (layoffEmployer && !participantFormValidationConstants.LAYOFF_EMPLOYER_REGEX.test(layoffEmployer)) {
					isValid = false;
					errors.push(participantFormValidationConstants.LAYOFF_EMPLOYER_ERROR);
				}
				break;
			case participantFormConstants.HIGHER_WAGES_KEY:
				const higherWages = data[participantFormConstants.HIGHER_WAGES_KEY];
				if (higherWages && !participantFormValidationConstants.HIGHER_WAGES_REGEX.test(higherWages)) {
					isValid = false;
					errors.push(participantFormValidationConstants.HIGHER_WAGES_ERROR);
				}
				break;
			case participantFormConstants.SKILLS_KEY:
				const skills = data[participantFormConstants.SKILLS_KEY];
				if (skills && !participantFormValidationConstants.SKILLS_REGEX.test(skills)) {
					isValid = false;
					errors.push(participantFormValidationConstants.SKILLS_ERROR);
				}
				break;
			case participantFormConstants.FAMILY_DEPENDENT_KEY:
				const familyDependent = data[participantFormConstants.FAMILY_DEPENDENT_KEY];
				if (familyDependent && !participantFormValidationConstants.FAMILY_DEPENDENT_REGEX.test(familyDependent)) {
					isValid = false;
					errors.push(participantFormValidationConstants.FAMILY_DEPENDENT_ERROR);
				}
				break;
			case participantFormConstants.COVID_KEY:
				const covid = data[participantFormConstants.COVID_KEY];
				if (covid && !participantFormValidationConstants.COVID_REGEX.test(covid)) {
					isValid = false;
					errors.push(participantFormValidationConstants.COVID_ERROR);
				}
				break;
      case participantFormConstants.QUEST_KEY:
        const quest = data[participantFormConstants.QUEST_KEY];
        if (quest && !participantFormValidationConstants.QUEST_REGEX.test(quest)) {
          isValid = false;
          errors.push(participantFormValidationConstants.QUEST_ERROR);
        }
        break;
			// case participantFormConstants.ACCEPTED_COVID_KEY:
			// 	const acceptedCovid = data[participantFormConstants.ACCEPTED_COVID_KEY];
			// 	if (acceptedCovid && !participantFormValidationConstants.ACCEPTED_COVID_REGEX.test(acceptedCovid)) {
			// 		isValid = false;
			// 		errors.push(participantFormValidationConstants.ACCEPTED_COVID_ERROR);
			// 	}
			// 	break;
			default:
				isValid = false;
				errors.push('The column (' + key + ') is not recognized, please compare to downloadable template');
		}
	}

	if (templateKeys.length) {
		isValid = false;
		templateKeys.forEach( key => {
			errors.push('The column (' + key + ') is missing, please compare to downloadable template');
		});
	}

	return {
		isValid,
		errors
	};
};
