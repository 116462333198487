export type Sort = 'asc' | 'desc'

const desc = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export const getSorting = (order: Sort, orderBy: string) => {
    return order === 'desc'
        ? (a: any, b: any) => desc(a, b, orderBy)
        : (a: any, b: any) => -desc(a, b, orderBy)
}

export const stableSort = <T>(array: T[], cmp: any): T[] => {
    const stabilizedThis = array.map((el: any, index: number) => {
      el.quest = el.quest !== null ? el.quest : ""
      return [el, index]
    })

    stabilizedThis.sort((a: any, b: any) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    return stabilizedThis.map((el: any) => el[0])
}

export const sortArrayItems = <T>(array: T[], order: Sort, orderBy: string, page: number, rowsPerPage: number): T[] => {
  return stableSort(array, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  )
}