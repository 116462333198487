import {
	Button,
	Checkbox,
	Collapse,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	TextField,
	Theme,
	Typography,
	WithStyles,
	withStyles,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import moment from 'moment'
import * as R from 'ramda'
import React, {CSSProperties} from 'react'
import InputMask from 'react-input-mask'

import {
	BinaryChoice,
	CheckboxItem,
	Education,
	EmploymentType,
	FullParticipantData,
	Race,
	State,
	StateInitials,
	SurveyResponse
} from '../../../services/model'
import * as regexPatterns from '../../../utils/patterns'
import {nullifyEmptyString} from '../../../utils/stringFormatters'
import DatePicker, {MONTHS, TemporaryDate} from '../../templates/DatePicker/DatePicker'
import {
	BLANK_ADDRESS,
	BLANK_MAILING_ADDRESS,
	EDUCATION_RADIO_OPTIONS,
	ETHNICITY_RADIO_OPTIONS,
	GENDER_RADIO_OPTIONS,
	// QUEST_RADIO_OPTIONS,
	RACE_CHECKBOX_ITEMS,
	SURVEY_RADIO_OPTIONS
} from '../FormItems'
import CustomRadioItem from '../FormTemplates/CustomRadioItem/CustomRadioItem'
import TextItem from '../FormTemplates/CustomTextItem/CustomTextItem'

interface EditActivityParticipantFormProps extends WithStyles<typeof styles> {
	participant: FullParticipantData
	onCancel: (participant: FullParticipantData) => void
	onFormSubmit: (participant: FullParticipantData) => void
	handleDialogOpen?: (isOpen: boolean) => void
}

interface EditActivityParticipantFormState extends FullParticipantData {
	loading?: boolean
	tempDate?: TemporaryDate
	editEmployRIIDDialog: boolean
	editEmployRIID: boolean
}

const NUMBER_OF_YEARS = 100
const CURRENT_YEAR = new Date().getFullYear()
const TOP_YEAR = CURRENT_YEAR - 16

export class EditActivityParticipantForm extends React.Component<EditActivityParticipantFormProps, EditActivityParticipantFormState> {
	public readonly state: EditActivityParticipantFormState = { ...this.props.participant, editEmployRIIDDialog: false, editEmployRIID: false }

	private employRIIDInputRef: React.RefObject<HTMLFormElement> = React.createRef()
	private formRef: React.RefObject<HTMLFormElement> = React.createRef()
	private firstNameInputRef: React.RefObject<HTMLFormElement> = React.createRef()
	private lastNameInputRef: React.RefObject<HTMLFormElement> = React.createRef()
	private phoneInputRef: React.RefObject<HTMLFormElement> = React.createRef()

	handleTextInputChange = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		// @ts-ignore
		this.setState({ [field]: e.target.value })
	}

	handleDateChange = (prop: string) => (date: TemporaryDate) => {
		const { day, month, year } = date

		this.setState((prevState: EditActivityParticipantFormState) => {
			const newState = {
				...prevState,
				tempDate: { day, month, year },
				[prop]: day && month && year ? new Date(`${year}/${MONTHS.indexOf(month) + 1}/${day}`) : prevState[prop],
			}

			return newState
		})
	}

	handlePhoneChange = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ phoneNumber: e.target.value.replace(/-/g, '') })
	}

	handleRadioSelect = (event: React.ChangeEvent<{}>) => {
		const { name, value } = event.target as HTMLSelectElement;

		this.setState((prevState: EditActivityParticipantFormState) => {
			return {
				...prevState,
				[name]: value,
			}
		})
	}

	handleCheckboxSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked, value } = e.target
		let races: Race[]

		if (checked) {
			if (value === Race.NA) {
				races = [];
			} else {
				races = this.state.races.filter((r: Race) => {
					return r !== Race.NA;
				})
			}
			races.push(Race[value])
		} else {
			races = this.state.races.filter((r) => r !== value)
		}

		this.setState({ races })
	}

	toggleHomelessStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState((prevState: EditActivityParticipantFormState) => {
			const ifHomelessNewState = {
				...prevState,
				...BLANK_ADDRESS,
				homeless: !prevState.homeless,
			}

			const ifNotHomelessState = {
				...prevState,
				address1: prevState.address1,
				address2: prevState.address2,
				city: prevState.city,
				state: prevState.state,
				zip: prevState.zip,
				homeless: !prevState.homeless,
			}

			return !prevState.homeless ? ifHomelessNewState : ifNotHomelessState
		})
	}

	toggleMailingAddressStatus = () => {
		this.setState((prevState: EditActivityParticipantFormState) => {
			const ifSameAsAddressNewState = {
				...prevState,
				...BLANK_MAILING_ADDRESS,
				sameAsHomeAddress: !prevState.sameAsHomeAddress,
			}

			const ifNotSameAsAddressState = {
				...prevState,
				mailingAddress1: prevState.address1,
				mailingAddress2: prevState.address2,
				mailingCity: prevState.city,
				mailingState: prevState.state,
				mailingZip: prevState.zip,
				sameAsHomeAddress: !prevState.sameAsHomeAddress,
			}

			return prevState.sameAsHomeAddress ? ifSameAsAddressNewState : ifNotSameAsAddressState
		})
	}

	handleStateInputChange = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target

		// @ts-ignore
		this.setState((prevState: EditActivityParticipantFormState) => ({ ...prevState, [field]: State[State[value]] }))
	}

	onBinaryRadioChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target

		// @ts-ignore
		this.setState({ [name]: value === BinaryChoice[BinaryChoice.YES] })
	}

	onEducationRadioChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement

		this.setState(() => {
			return Education[value] !== Education.NO_HIGHSCHOOL ? { education: Education[value], highestGrade: undefined } : { education: Education[value] }
		})
	}

  /* onQuestRadioChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement

		this.setState(() => {
			return SurveyResponse[value] === SurveyResponse.YES ? {quest: true} : {quest:false}
		})
	} */

	onCurrentlyEmployedRadioChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement

		this.setState({
			working: SurveyResponse[value],
			employer: SurveyResponse[value] === SurveyResponse.YES ? this.state.employer : '',
			employmentType: SurveyResponse[value] === SurveyResponse.YES ? this.state.employmentType : undefined,
		})
	}

	onEmploymentTypeChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement

		this.setState({ employmentType: EmploymentType[value] })
	}

	onBeingLaidOffRadioChange = (e: React.ChangeEvent<{}>) => {
		const { value } = e.target as HTMLSelectElement

		this.setState({
			laidOff: SurveyResponse[value],
			layoffEmployer: SurveyResponse[value] === SurveyResponse.YES ? this.state.layoffEmployer : '',
			layoffDate: SurveyResponse[value] === SurveyResponse.YES ? this.state.layoffDate : undefined,
		})
	}

	phoneMaskCustom = (props: any) => {
		return <InputMask {...props} ref={this.phoneInputRef} mask="999-999-9999" value={props.value} />
	}

	validate = () => {
		const form = this.formRef.current

		return form ? form.reportValidity() : false
	}

	handleCancel = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()
		const { loading, ...localParticipant } = this.state

		if (R.equals(localParticipant, this.props.participant)) {
			this.props.onCancel(localParticipant)
		} else {
			this.props.handleDialogOpen!(true)
		}
	}

	nullifyEmpty = (participant: FullParticipantData): FullParticipantData => {
		// @ts-ignore
		return R.mapObjIndexed(nullifyEmptyString, participant)
	}

	handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const isFormValid = this.validate()

		if (isFormValid) {
			const { loading, ...localParticipant } = this.state

			this.props.onFormSubmit(this.nullifyEmpty(localParticipant))
		}
	}

	hideEdit = (): CSSProperties => {
		if (this.state.editEmployRIID) {
			return {display: 'none'}
		}
		return {}
	}

	render() {
		const { classes } = this.props
		const { editEmployRIIDDialog, editEmployRIID, loading, ...participant } = this.state

		return (
			<div className={classes.formContainer}>
				<Dialog
					open={editEmployRIIDDialog}
					keepMounted={true}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Edit EmployRI ID</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to edit this field? Changes made will not be reflected in EmployRI and may result in system continuity issues.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => {this.setState({ editEmployRIID: true, editEmployRIIDDialog: false })}} color="primary">
							OK
						</Button>
						<Button onClick={() => {this.setState({ editEmployRIID: false, editEmployRIIDDialog: false })}} color="primary">
							CANCEL
						</Button>
					</DialogActions>
				</Dialog>
				<form onSubmit={this.handleSubmit} className={classes.form} ref={this.formRef} data-auto="edit-participant-form">
					<Grid container={true} wrap="wrap" spacing={16}>
						<Grid item={true} md={4}>
							<TextItem
								label="EmployRI ID"
								prop="externalId"
								value={participant.externalId}
								required={false}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									ref: this.employRIIDInputRef,
									pattern: "^[A-Za-z0-9]+$"
								}}
								disabled={!this.state.editEmployRIID}
							/>
						</Grid>
						<Grid item={true} md={4} style={this.hideEdit()}>
							<Button
								variant="contained"
								color="default"
								style={{'margin': '24px 0 0 0'}}
								onClick={() => {this.setState({ editEmployRIIDDialog: true })}} >
								<EditIcon />
								Edit
							</Button>
						</Grid>
					</Grid>
					<Grid container={true} wrap="wrap" spacing={16}>
						<Grid item={true} md={4}>
							<TextItem
								label="DMS Activity Participant ID"
								prop="activityParticipantId"
								value={participant.activityParticipantId}
								required={false}
								onChange={this.handleTextInputChange}
								disabled={true}
							/>
						</Grid>
					</Grid>
					<Grid container={true} wrap="wrap" spacing={16}>
						<Grid item={true} md={4}>
							<TextItem
								label="DMS Participant ID"
								prop="participantId"
								value={participant.id}
								required={false}
								onChange={this.handleTextInputChange}
								disabled={true}
							/>
						</Grid>
					</Grid>
					<Grid container={true} wrap="wrap" spacing={16}>
						<Grid item={true} md={4}>
							<TextItem
								label="First Name"
								prop="firstName"
								value={participant.firstName}
								required={true}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									ref: this.firstNameInputRef,
									pattern: regexPatterns.textInput,
									autoFocus: true,
								}}
							/>
						</Grid>
						<Grid item={true} md={4}>
							<TextItem
								label="Middle Initial"
								prop="middleInitial"
								value={participant.middleInitial || ''}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 1,
									pattern: regexPatterns.singleLetter,
								}}
							/>
						</Grid>
						<Grid item={true} md={4}>
							<TextItem
								label="Last Name"
								prop="lastName"
								value={participant.lastName}
								required={true}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									ref: this.lastNameInputRef,
									pattern: regexPatterns.textInput,
								}}
							/>
						</Grid>

						<Grid item={true} xs={12} sm={6} md={4}>
							<DatePicker
								label="Date of Birth"
								onDateChange={this.handleDateChange('dateOfBirth')}
								date={moment(participant.dateOfBirth).format('YYYY-MM-DD') || ''}
								noOfYears={NUMBER_OF_YEARS}
								topYear={TOP_YEAR}
								sortAsc={true}
								required={true}
							/>
						</Grid>
						<Grid item={true} md={4}>
							<TextItem
								label="Email Address"
								prop="email"
								value={participant.email || ''}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									type: 'email',
									placeholder: 'email@domain.com',
								}}
							/>
						</Grid>
						<Grid item={true} md={4}>
							<TextItem
								label="Phone Number"
								prop="phoneNumber"
								value={participant.phoneNumber || ''}
								onChange={this.handlePhoneChange}
								otherInputProps={{
									type: 'tel',
									placeholder: '123-456-7890',
									pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
								}}
								textFieldProps={{
									inputComponent: this.phoneMaskCustom,
								}}
							/>
						</Grid>
					</Grid>

					<Divider className={classes.divider} />

					<Grid container={true} wrap="wrap" spacing={16}>
						<Grid item={true} sm={6} md={4}>
							<CustomRadioItem
								title="Gender"
								propName="gender"
								onRadioChange={this.handleRadioSelect}
								column={true}
								radioOptions={GENDER_RADIO_OPTIONS}
								value={participant.gender || ''}
							/>
						</Grid>

						<Grid item={true} sm={6} md={4}>
							<CustomRadioItem
								column={true}
								title="Ethnicity"
								propName="ethnicity"
								onRadioChange={this.handleRadioSelect}
								radioOptions={ETHNICITY_RADIO_OPTIONS}
								value={participant.ethnicity || ''}
							/>
						</Grid>
						<Grid item={true} sm={6} md={4}>
							<FormControl>
								<FormLabel component="label">Race:</FormLabel>

								<FormGroup className={classes.selectGroup} id="races">
									{RACE_CHECKBOX_ITEMS.map((raceItem: CheckboxItem, index: number) => (
										<FormControlLabel
											key={index}
											control={
												<Checkbox
													checked={participant.races.includes(Race[raceItem.value])}
													onChange={this.handleCheckboxSelect}
													name={`race-${raceItem.value}`}
													title={`race-${raceItem.value}`}
													value={raceItem.value}
												/>
											}
											label={raceItem.label}
										/>
									))}
								</FormGroup>
							</FormControl>
						</Grid>
					</Grid>

					<Divider className={classes.divider} />

					<Grid container={true} wrap="wrap" spacing={16}>
						<Grid item={true} md={6}>
							<Typography variant="h6" component="h3" align="center">
								Home Address
							</Typography>

							<FormGroup row={true}>
								<FormControlLabel
									control={<Checkbox checked={participant.homeless === true} onChange={this.toggleHomelessStatus} color="primary" id="homeless" />}
									label="I am homeless"
									title="I am homeless"
								/>
							</FormGroup>

							<TextItem
								label="Address Line 1"
								prop="address1"
								value={participant.address1 || ''}
								required={!participant.homeless}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
								}}
							/>

							<TextItem
								label="Address Line 2"
								prop="address2"
								value={participant.address2 || ''}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
								}}
							/>

							<TextItem
								label="City"
								prop="city"
								value={participant.city || ''}
								required={!participant.homeless}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									pattern: regexPatterns.textInput,
								}}
							/>

							<Grid container={true} spacing={16}>
								<Grid item={true} md={8}>
									<TextItem
										label="Zip Code"
										prop="zip"
										value={participant.zip || ''}
										required={!participant.homeless}
										onChange={this.handleTextInputChange}
										otherInputProps={{
											maxLength: 15,
											pattern: '[0-9]{5}(?:-[0-9]{4})?$',
										}}
									/>
								</Grid>
								<Grid item={true} md={4}>
									<TextField
										required={!participant.homeless}
										select={true}
										fullWidth={true}
										value={participant.state || State[State.RI]}
										id="state"
										label="State"
										placeholder="State"
										name="state"
										margin="normal"
										variant="outlined"
										onChange={this.handleStateInputChange('state')}
										SelectProps={{
											native: true,
										}}
									>
										{StateInitials.map((stateId, index) => (
											<option key={index} value={stateId} data-auto={`state-${stateId}`}>
												{stateId}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Grid>

						<Grid item={true} md={6}>
							<Typography variant="h6" component="h3" align="center">
								Mailing Address
							</Typography>

							<FormGroup row={true}>
								<FormControlLabel
									control={<Checkbox checked={participant.sameAsHomeAddress === true} onChange={this.toggleMailingAddressStatus} color="primary" />}
									label="Check if same as Home Address"
									title="Check if same as Home Address"
								/>
							</FormGroup>

							<TextItem
								label="Mailing Address Line 1"
								prop="mailingAddress1"
								value={participant.mailingAddress1 || ''}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									disabled: participant.sameAsHomeAddress,
								}}
							/>

							<TextItem
								label="Mailing Address Line 2"
								prop="mailingAddress2"
								value={participant.mailingAddress2 || ''}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									maxLength: 255,
									disabled: participant.sameAsHomeAddress,
								}}
							/>

							<TextItem
								label="Mailing City"
								prop="mailingCity"
								value={participant.mailingCity || ''}
								onChange={this.handleTextInputChange}
								otherInputProps={{
									disabled: participant.sameAsHomeAddress,
									maxLength: 255,
									pattern: '^[a-zA-Z]+(?:[\\s-][a-zA-Z]+)*$',
								}}
							/>

							<Grid container={true} spacing={16}>
								<Grid item={true} md={8}>
									<TextItem
										label="Mailing Zip Code"
										prop="mailingZip"
										value={participant.mailingZip || ''}
										onChange={this.handleTextInputChange}
										otherInputProps={{
											disabled: participant.sameAsHomeAddress,
											maxLength: 15,
											pattern: '[0-9]{5}(?:-[0-9]{4})?$',
										}}
									/>
								</Grid>
								<Grid item={true} md={4}>
									<TextField
										disabled={participant.sameAsHomeAddress}
										select={true}
										fullWidth={true}
										value={participant.mailingState || State[State.RI]}
										id="mailing-state"
										label="Mailing State"
										placeholder="Mailing State"
										name="mailingState"
										margin="normal"
										variant="outlined"
										onChange={this.handleStateInputChange('mailingState')}
										SelectProps={{
											native: true,
											id: 'mailingState',
										}}
										inputProps={{
											name: 'mailingState',
											title: 'Mailing State',
										}}
									>
										{StateInitials.map((stateId, index) => (
											<option key={index} value={stateId} data-auto={`mailing-state-${stateId}`}>
												{stateId}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider className={classes.divider} />

					<Grid container={true} wrap="wrap" spacing={16}>
						<Typography variant="h6" component="h3" align="center">
							Demographics
						</Typography>

						<Grid item={true} md={12}>
							<div className={classes.surveyItems}>
								<CustomRadioItem
									title="Are you a US Citizen?"
									propName="citizen"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.citizen || ''}
								/>
								<CustomRadioItem
									title="Are you a Veteran?"
									propName="veteran"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.veteran || ''}
								/>

                {/* <CustomRadioItem
									title="Disability Status"
									propName="disabilityStatus"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.disabilityStatus || ''}
								/> */}

								<CustomRadioItem
									title="Are you an English Language Learner (ELL)?"
									propName="ell"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.ell || ''}
								/>

								<CustomRadioItem
									title="Highest level of education"
									propName="education"
									onRadioChange={this.onEducationRadioChange}
									radioOptions={EDUCATION_RADIO_OPTIONS}
									value={participant.education ? Education[participant.education] : Education[Education.NA]}
								/>

								<Collapse in={!!participant.education && participant.education === Education.NO_HIGHSCHOOL}>
									<Grid item={true} md={4}>
										<TextItem
											label="Last grade completed"
											prop="highestGrade"
											value={participant.highestGrade ? participant.highestGrade.toString() : ''}
											onChange={this.handleTextInputChange}
											otherInputProps={{
												maxLength: 2,
												pattern: '[0-9][0-3]?',
											}}
										/>
									</Grid>
								</Collapse>

								<CustomRadioItem
									title="Are you currently attending school?"
									propName="attendingSchool"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.attendingSchool || ''}
								/>

								<Collapse in={!!participant.attendingSchool && participant.attendingSchool === SurveyResponse.YES}>
									<Grid item={true} md={4}>
										<TextItem
											label="Current School Name"
											prop="currentSchool"
											value={participant.currentSchool || ''}
											onChange={this.handleTextInputChange}
											otherInputProps={{
												maxLength: 255,
												pattern: regexPatterns.textInput,
											}}
										/>
									</Grid>
								</Collapse>

								<CustomRadioItem
									title="Are you currently employed?"
									propName="currentlyEmployed"
									onRadioChange={this.onCurrentlyEmployedRadioChange}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.working || ''}
								/>

								<Collapse in={!!participant.working && participant.working === SurveyResponse.YES}>
									<CustomRadioItem
										title="Employment type?"
										propName="employmentType"
										onRadioChange={this.onEmploymentTypeChange}
										radioOptions={[{ value: EmploymentType.FULL_TIME, label: 'Full time' }, { value: EmploymentType.PART_TIME, label: 'Part time' }]}
										value={participant.employmentType || ''}
									/>

									<Grid item={true} md={4}>
										<TextItem
											label="Current Employer Name"
											prop="employer"
											value={participant.employer || ''}
											onChange={this.handleTextInputChange}
											otherInputProps={{
												maxLength: 255,
												pattern: regexPatterns.textInput,
											}}
										/>
									</Grid>
								</Collapse>

								<CustomRadioItem
									title="Have you been laid off, or received notice that you will be laid off?"
									propName="beingLaidOff"
									onRadioChange={this.onBeingLaidOffRadioChange}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.laidOff || ''}
								/>

								<Collapse in={!!participant.laidOff && participant.laidOff === SurveyResponse.YES}>
									<Grid container={true} wrap="wrap" spacing={16}>
										<Grid item={true} md={4}>
											<DatePicker
												label="Layoff Date"
												onDateChange={this.handleDateChange('layoffDate')}
												date={participant.laidOff ? moment(participant.layoffDate).format('YYYY-MM-DD') : ''}
												noOfYears={NUMBER_OF_YEARS}
												topYear={CURRENT_YEAR + 3}
											/>
										</Grid>
										<Grid item={true} xs={false} md={8} />

										<Grid item={true} md={4}>
											<TextItem
												label="Employer name"
												prop="layoffEmployer"
												value={participant.layoffEmployer || ''}
												onChange={this.handleTextInputChange}
												otherInputProps={{
													maxLength: 255,
													pattern: regexPatterns.textInput,
												}}
											/>
										</Grid>
									</Grid>
								</Collapse>

								<CustomRadioItem
									title="Have you ever earned higher wages than you earn now?"
									propName="higherWage"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.higherWage || ''}
								/>

								<CustomRadioItem
									title="Are you looking to make better use of your skills and/or education?"
									propName="betterSkills"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.betterSkills || ''}
								/>

								<CustomRadioItem
									title="Have you experienced a loss of income from a family member that is still impacting your financial situation?"
									propName="familyDependent"
									onRadioChange={this.handleRadioSelect}
									radioOptions={SURVEY_RADIO_OPTIONS}
									value={participant.familyDependent || ''}
								/>

                {/* <CustomRadioItem
									title="Quest"
									propName="quest"
									onRadioChange={this.onQuestRadioChange}
									radioOptions={QUEST_RADIO_OPTIONS}
									value={participant.quest === true ? SurveyResponse.YES : (participant.quest === false) ? SurveyResponse.NO : ''}
								/> */}
							</div>
						</Grid>
					</Grid>

					<Divider className={classes.divider} />

					<div className={classes.formActions}>
						<Button variant="contained" color="secondary" className={classes.button} data-auto="cancel" onClick={this.handleCancel}>
							<CancelIcon className={classes.icon} />
							Cancel
						</Button>
						<Button
							aria-disabled={R.equals(participant, this.props.participant)}
							disabled={R.equals(participant, this.props.participant)}
							variant="contained"
							color="primary"
							className={classes.button}
							data-auto="submit"
							type="submit"
						>
							<SaveIcon className={classes.icon} aria-disabled={R.equals(participant, this.props.participant)} />
							Save
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		formContainer: {},
		form: {},
		divider: {
			margin: `${theme.spacing.unit * 1.5}px 0`,
		},
		selectGroup: {
			paddingLeft: `${theme.spacing.unit * 3}px`,
		},
		surveyItems: {
			paddingLeft: `${theme.spacing.unit * 3}px`,
		},
		formActions: {
			flex: 1,
			justifyContent: 'flex-end',
			display: 'flex',
			flexDirection: 'row',
		},
		icon: {
			marginRight: `${theme.spacing.unit / 2}px`,
		},
		button: {
			width: 'auto',
			marginLeft: `${theme.spacing.unit * 2}px`,
		},
	})

export default withStyles(styles)(EditActivityParticipantForm)
