import { OutcomeStatus } from './Outcomes'
import {SurveyData, SurveyResponse} from "./Survey";

export interface FullParticipantData extends Participant, SurveyData {}

export interface Participant extends ParticipantData {
	id: string
}

export interface ParticipantData {
	firstName: string
	middleInitial: string
	lastName: string
	dateOfBirth: Date
	homeless: boolean
	address1: string
	address2?: string
	city: string
	state: State
	zip: string
	mailingAddress1?: string
	mailingAddress2?: string
	mailingCity?: string
	mailingState?: State
	mailingZip?: string
	sameAsHomeAddress?: boolean
	phoneNumber: string
	email: string
	gender: Gender
	ethnicity: Ethnicity
	externalId: string
	races: Race[]
}

export interface ParticipantActivity {
	activityDate: Date
	activityId: string
	activityName: string
	addendumId: string
	partnershipId: string
	partnershipName: string
}

export interface ActivityParticipant {
  quest: SurveyResponse;
	enrollDate: Date
	firstName: string
	id: string
	lastName: string
	lastUpdatedDate: Date
	status: OutcomeStatus
}

export interface ActivityParticipantType {
	activityParticipantId: number
	participantEnrollmentTypeId: number
	activityParticipantTypeId: number
	activityServiceTypeId: number
	activityParticipantEnabled: boolean
	participantEnabled: boolean
	cohortEnabled: boolean
	activityEnabled: boolean
}

export interface BulkActivityParticipantTypes {
	found: ActivityParticipantType[]
	notFound: number[]
}

export interface ActivityType {
	activityId: number
	activityParticipantTypeId: number
	activityServiceTypeId: number
	cohortEnabled: boolean
	activityEnabled: boolean
}

export interface BulkActivityTypes {
	found: ActivityType[]
	notFound: number[]
}

export enum Race {
	ASIAN = 'ASIAN',
	ISLANDER = 'ISLANDER',
	INDIGENOUS = 'INDIGENOUS',
	WHITE = 'WHITE',
	BLACK = 'BLACK',
	NA = 'NA',
}

export enum Disability {
  PHYSICAL = 'PHYSICAL',
  HEARING = 'HEARING',
  VISION= 'VISION',
  SOCIAL_EMOTIONAL = 'SOCIAL_EMOTIONAL',
  INTELLECTUAL_DEVELOPMENTAL_COGNITIVE = 'INTELLECTUAL_DEVELOPMENTAL_COGNITIVE',
  DND = 'DND'
}

export const RaceMapper = new Map<Race, string>([
	[Race.ASIAN, 'Asian'],
	[Race.ISLANDER, 'Hawaiian or Pacific Islander'],
	[Race.INDIGENOUS, 'Native American or Alaska Native'],
	[Race.WHITE, 'White'],
	[Race.BLACK, 'Black'],
	[Race.NA, 'Did not disclose']
])

export enum Gender {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
	NA = 'NA',
	TRANS_MALE = 'TRANS_MALE',
	TRANS_FEMALE = 'TRANS_FEMALE',
	NON_BINARY = 'NON_BINARY',
	OTHER = 'OTHER',
	DND = 'DND',
}

export const GenderMapper = new Map<Gender, string>([
	[Gender.MALE, 'Male'],
	[Gender.FEMALE, 'Female'],
	[Gender.NA, 'Not Applicable'],
	[Gender.TRANS_MALE, 'Transgender Male'],
	[Gender.TRANS_FEMALE, 'Transgender Female'],
	[Gender.NON_BINARY, 'Non-conforming/Non-binary'],
	[Gender.OTHER, 'Other'],
	[Gender.DND, 'Did not disclose'],
])

export enum Ethnicity {
	LATINO = 'LATINO',
	NOT_LATINO = 'NOT-LATINO',
	NA = 'NA',
}

export const EthnicityMapper = new Map<Ethnicity, string>([
	[Ethnicity.LATINO, 'Latino'],
	[Ethnicity.NOT_LATINO, 'Not Latino'],
	[Ethnicity.NA, 'Did not disclose'],
])

export enum State {
	AK,
	AL,
	AR,
	AZ,
	CA,
	CO,
	CT,
	DE,
	FL,
	GA,
	HI,
	IA,
	ID,
	IL,
	IN,
	KS,
	KY,
	LA,
	MA,
	MD,
	ME,
	MI,
	MN,
	MO,
	MS,
	MT,
	NC,
	ND,
	NE,
	NH,
	NJ,
	NM,
	NV,
	NY,
	OH,
	OK,
	OR,
	PA,
	RI,
	SC,
	SD,
	TN,
	TX,
	UT,
	VA,
	VT,
	WA,
	WI,
	WV,
	WY,
}

export const StateInitials: string[] = Object.keys(State).filter((i) => isNaN(+i))

export const ParticipantCSVUploadHeaders: string[] = ["address1",
  "address2",
  "city",
  "dateOfBirth",
  "email",
  "ethnicity",
  "firstName",
  "gender",
  "homeless",
  "lastName",
  "mailingAddress1",
  "mailingAddress2",
  "mailingCity",
  "mailingState",
  "mailingZip",
  "middleInitial",
  "phoneNumber",
  "races",
  "state",
  "zip",
  "citizen",
  "veteran",
  "disabilityStatus",
  "disabilities",
  "ell",
  "education",
  "attendingSchool",
  "currentSchool",
  "working",
  "laidOff",
  "higherWage",
  "betterSkills",
  "familyDependent",
  "highestGrade",
  "employmentType",
  "layoffDate",
  "layoffEmployer",
  "employer",
  "Quest"
]
