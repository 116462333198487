import { createStyles, Paper, Theme, withStyles, WithStyles } from '@material-ui/core'
import { indigo } from '@material-ui/core/colors'
import ArrowRight from '@material-ui/icons/ArrowRight'
import HomeIcon from '@material-ui/icons/Home'
import * as React from 'react'
import { Breadcrumbs, BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'

import NotFoundLanding from '../../templates/NotFoundLanding'
import ParticipantsLanding from '../../templates/ParticipantsLanding'
import PartnershipsLanding from '../../templates/PartnershipsLanding'

interface MainProps extends WithStyles<typeof styles> {}

export const Main: React.SFC<MainProps> = (props: MainProps) => {
	const { classes } = props

	return (
		<div className={classes.main} role="main">
			<Breadcrumbs
				item={NavLink}
				separator={<ArrowRight />}
				container="div"
				finalProps={{
					className: classes.lastBreadcrumbItem,
				}}
				containerProps={{
					className: classes.breadcrumbsContainer,
					'data-auto': 'breadcrumbs-container',
				}}
			/>
			<BreadcrumbsItem to={'/'} data-auto="breadcrumbs-item">
				<HomeIcon className={classes.breadcrumbsIcon} data-auto="home-icon" titleAccess="Link to Home" />
			</BreadcrumbsItem>

			<Paper elevation={2} square={true} className={classes.content}>
				<Switch>
					<Route exact={true} path="/">
						<Redirect to="/partnerships" />
					</Route>
					<Route path="/partnerships" component={PartnershipsLanding} />
					<Route path="/participants" component={ParticipantsLanding} />
					<Route component={NotFoundLanding} />
				</Switch>
			</Paper>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		main: {
			margin: '0 auto',
			overflow: 'auto',
			minHeight: '85vh',

			[theme.breakpoints.down('sm')]: {
				width: '95%',
				paddingTop: '5px',
			},
			[theme.breakpoints.up('md')]: {
				width: '90%',
				paddingTop: '10px',
			},
			[theme.breakpoints.up('lg')]: {
				width: '80%',
			},
		},
		content: {
			flex: '1 0 0',
		},
		breadcrumbsContainer: {
			alignContent: 'center',
			height: '100%',
			display: 'inline-flex',
			alignItems: 'center',
			color: theme.palette.grey[600],
			[theme.breakpoints.down('sm')]: {
				'& span:nth-child(n+2)': {
					display: 'none',
				},
				'& span:nth-last-child(2)': {
					display: 'inline-flex',

					'& svg': {
						display: 'none',
					},
				},
			},

			'& span': {
				minHeight: '100%',
				display: 'inline-flex',
				alignItems: 'center',

				'& a': {
					textDecoration: 'none',
					color: theme.palette.grey[700],
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					maxWidth: '200px',

					'&:hover': {
						color: indigo[500],
					},

					[theme.breakpoints.down('md')]: {
						maxWidth: '25ch',
					},
				},
			},
		},
		lastBreadcrumbItem: {
			minHeight: '100%',
			display: 'inline-flex',
			alignItems: 'center',
			textDecoration: 'none',
			color: theme.palette.grey[700],
			textOverflow: 'ellipsis',

			'&:hover': {
				color: indigo[500],
			},
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		breadcrumbsIcon: {
			color: theme.palette.grey[700],
			'&:hover': {
				color: indigo[500],
			},
		},
	})

export default withStyles(styles)(Main)
