import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import React from 'react'
import { ActivityParticipant, Participant, SurveyResponse, TableHeader, TableItem } from '../../../services/model'
import { OutcomeStatusMapper } from '../../../services/model/Outcomes'
import { getSorting, Sort, stableSort } from '../../../utils/sortingService'
import TableWrapper from '../TableWrapper'

interface ActivityParticipantsTableProps extends WithStyles<typeof styles> {
	participants: Participant[]
	headers: TableHeader[]
	participantUrl: string
	tableContentFormatter?: (participants: Participant[]) => TableItem[]
}

interface ActivityParticipantsTableState {
	order: Sort
	orderBy: string
	page: number
	rowsPerPage: number
	sortedParticipants: Participant[]
}

function defaultContentFormatter(content: any[], participantURL: string): TableItem[] {
	const formattedContent = content.map((i: ActivityParticipant) => {
		const name = `${i.lastName}, ${i.firstName}`
    const quest = (i.quest === SurveyResponse.YES) ? 'Y' : (i.quest === SurveyResponse.NO) ? 'N': ''
		return {
			title: {
				label: name,
				url: `${participantURL}/${i.id}`,
			},
			content: [
				{ isDate: true, value: i.enrollDate },
				{ isDate: false, value: OutcomeStatusMapper.get(i.status) || '' },
        {isDate: false, value: quest},
				{ isDate: true, value: i.lastUpdatedDate },
			],
		}
	})

	return formattedContent
}

function getInitialStateFromProps(props: ActivityParticipantsTableProps): ActivityParticipantsTableState {
	const sorted = stableSort(props.participants, getSorting('asc', 'name'))
	return {
		order: 'asc',
		orderBy: 'lastName',
		page: 0,
		rowsPerPage: 10,
		sortedParticipants: sorted,
	}
}

export class ActivityParticipantsTable extends React.Component<ActivityParticipantsTableProps, ActivityParticipantsTableState> {
	public readonly state: ActivityParticipantsTableState = getInitialStateFromProps(this.props)

	handleRequestSort = (currentOrder: string, sortLabel: string) => {
		const { sortedParticipants } = this.state
		const order: Sort = currentOrder === 'asc' ? 'desc' : 'asc'
		const orderBy: string = sortLabel

		const participants = stableSort(sortedParticipants, getSorting(order, orderBy))

		this.updateSorting(participants, order, orderBy)
	}

	updateSorting = (participants: Participant[], order: Sort, sortLabel: string) => {
		this.setState({
			order,
			orderBy: sortLabel,
			sortedParticipants: participants,
		})
	}

	componentWillReceiveProps(nextProps: ActivityParticipantsTableProps) {
		if (this.props.participants !== nextProps.participants) {
			this.setState({ sortedParticipants: nextProps.participants })
		}
	}

	render() {
		const { classes, headers, tableContentFormatter, participantUrl } = this.props
		const { order, orderBy, sortedParticipants } = this.state
		const formattedParticipants = tableContentFormatter
			? tableContentFormatter(sortedParticipants)
			: defaultContentFormatter(sortedParticipants, participantUrl)

		return (
			<div className={classes.root} data-auto="participants-table">
				<TableWrapper
					headers={headers}
					rowItems={formattedParticipants}
					loading={!formattedParticipants}
					sortDirection={order}
					orderBy={orderBy}
					onSortClick={this.handleRequestSort}
					paginationFooter={formattedParticipants.length > 10}
					itemsLabel="participants"
				/>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing.unit * 3,
			overflowX: 'auto',
			padding: '10px',
		},
	})

export default withStyles(styles)(ActivityParticipantsTable)
