import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service'
import { Addendum } from './model'
import { PARTNERSHIPS_URL } from './partnerships.service'

export const getAddendaByPartnershipID = async (partnershipID: string): Promise<Addendum[]> => {
	try {
		const { data } = await axios.get('/', {
			baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda`,
		})

		return data
	}catch (e) {
		authorizationCheck(e.response.status)
		return e
	}
}

export const getAddendum = async (partnershipID: string, addendumID: string): Promise<Addendum> => {

	try {
		const { data } = await axios.get('/', {
			baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}`,
		})

		return data
	} catch (e) {
		authorizationCheck(e.status)
		return e
	}

}
