import { validateOpcOutcome, validateOutcome } from "./outcomeValidator";
import {validateParticipant} from "./participantValidator";

const validate = (data: any, validators: any[]) => {

  return validators
    .map(validator => {
      return validator(data);
    })
    .reduce((accumulator, currentValue) => {
      if (!currentValue.isValid) {
        accumulator.isValid = false;
        accumulator.errors = accumulator.errors.concat(currentValue.errors);
      }

      return accumulator;
    }, {isValid: true, errors: []});
}

export const validateOutcomes = (data: any) => {

  return validate(data, [validateOutcome])
}

export const validateOpcOutcomes = (data: any) => {

  return validate(data, [validateOpcOutcome])
}

export const validateParticipants = (data: any) => {

  return validate(data, [validateParticipant])
}
