import { Tab, Tabs } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface NavProps extends WithStyles<typeof styles> {
	navLinks: NavigationLink[]
}

export type RouterProps = NavProps & RouteComponentProps<{}> & WithStyles<{}>

interface NavState {
	activeLink: NavigationLink
}

export interface NavigationLink {
	title: string
	url: string
}

export class NavBar extends React.Component<RouterProps, NavState> {
	public readonly state: NavState = {
		activeLink: this.props.navLinks[0],
	}

	findLink = (title: string): NavigationLink | undefined => {
		return this.props.navLinks.find((link: NavigationLink) => link.title.toLowerCase() === title.toLowerCase())
	}

	getRouteChangePath = (): string => {
		const path = this.props.location.pathname

		return `/${path.split('/').filter((item) => item !== '')[0]}`
	}

	mapToUrl = (url: string): NavigationLink => {
		const { navLinks } = this.props

		return navLinks.find((link: NavigationLink) => link.url.toLowerCase() === url.toLowerCase()) || this.state.activeLink
	}

	onNavTabChange = (event: React.ChangeEvent<{}>, title: string) => {
		const linkRoute = this.findLink(title)

		if (linkRoute) {
			this.pushRouteToHistory(linkRoute.url)
			this.setActiveTab(title)
		}
	}

	pushRouteToHistory = (url: string): void => {
		if (url) this.props.history.push(url)
	}

	setActiveTab = (linkTitle: string) => {
		this.setState({
			activeLink: this.findLink(linkTitle) || this.state.activeLink,
		})
	}

	updateRoute = () => {
		const newPath = this.getRouteChangePath()

		if (newPath) {
			this.setState({
				activeLink: this.mapToUrl(newPath),
			})
		}
	}

	componentDidUpdate(prevProps: RouterProps) {
		const currentHome = prevProps.location.pathname.split('/').filter((i) => i.trim())[0]
		const updatedHome = this.props.location.pathname.split('/').filter((i) => i.trim())[0]

		if (currentHome !== updatedHome) {
			this.updateRoute()
		}
	}

	componentDidMount() {
		this.updateRoute()
	}

	render() {
		const { classes, navLinks } = this.props
		const { activeLink } = this.state

		return (
			!!navLinks.length && (
				<Tabs
					indicatorColor="secondary"
					textColor="inherit"
					fullWidth={true}
					className={classes.navContainer}
					value={activeLink.title}
					onChange={this.onNavTabChange}
					centered={true}
					data-auto="app-navbar"
					role="menubar"
				>
					{navLinks.map((section: NavigationLink, index: number) => (
						<Tab
							key={index}
							label={section.title}
							value={section.title}
							className={classes.navItem}
							fullWidth={true}
							data-auto={`app-navbar-item-${section.title}`}
							role="menuitem"
						/>
					))}
				</Tabs>
			)
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		navContainer: {
			display: 'flex',

			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
		},
		navItem: {
			color: theme.palette.grey['800'],
			[theme.breakpoints.down('sm')]: {
				display: 'none',
				backgroundColor: 'red',
			},
		},
	})

export const StyledNavBar = withStyles(styles)(NavBar)
export default withRouter(StyledNavBar)
