import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

import { FullParticipantData } from '../../../services/model/index'
import EditActivityParticipantForm from '../../Forms/EditActivityParticipantForm/EditActivitiyParticipantForm'
import ConfirmAlertDialog from '../ConfirmAlertDialog/ConfirmAlertDialog'
import TypographyHeader from '../TypographyHeader/TypographyHeader'

interface EditParticipantFormWrapperProps extends WithStyles<typeof styles> {
	participant: FullParticipantData
	onCancel: () => void
	handleParticipantUpdate: (participant: FullParticipantData) => void
}

interface EditParticipantFormWrapperState extends FullParticipantData {
	showConfirmDialog: boolean
	editedParticipant?: FullParticipantData
}

export class EditParticipantFormWrapper extends React.Component<EditParticipantFormWrapperProps, EditParticipantFormWrapperState> {
	public readonly state: EditParticipantFormWrapperState = {
		...this.props.participant,
		showConfirmDialog: false,
	}

	onEditParticipantCancel = (editingParticipant: FullParticipantData) => {
		this.props.onCancel()
	}

	onDialogConfirm = () => {
		if (this.state.editedParticipant !== this.props.participant) {
			this.setState(() => ({ editedParticipant: this.props.participant }))
			this.props.onCancel()
		}
	}

	onDialogCancel = () => {
		this.setState(() => ({ showConfirmDialog: false }))
	}

	toggleDialogOpen = (val: boolean) => {
		this.setState(() => ({ showConfirmDialog: val }))
	}

	render() {
		const { classes } = this.props
		const { showConfirmDialog, ...participant } = this.state
		const fullName = `${participant.firstName} ${participant.middleInitial || ''} ${participant.lastName} `

		return (
			<div className={classes.root}>
				<TypographyHeader
					type="title"
					label={`Editing:  ${this.props.participant.firstName} ${this.props.participant.middleInitial || ''}  ${this.props.participant.lastName}`}
				/>

				<EditActivityParticipantForm
					participant={participant}
					onFormSubmit={this.props.handleParticipantUpdate}
					onCancel={this.onEditParticipantCancel}
					handleDialogOpen={this.toggleDialogOpen}
				/>

				<ConfirmAlertDialog
					open={showConfirmDialog}
					dialogTitle="Discard changes?"
					dialogContent={`Are you sure you want to cancel editing ${fullName}?`}
					handleConfirm={this.onDialogConfirm}
					handleCancel={this.onDialogCancel}
				/>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
	})

export default withStyles(styles)(EditParticipantFormWrapper)
