import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

interface TypographyHeaderProps extends WithStyles<typeof styles> {
	label: string
	extraProps?: { [key: string]: any }
	type: 'title' | 'subtitle'
	inline?: boolean
}

export const TypographyHeader: React.SFC<TypographyHeaderProps> = (props: TypographyHeaderProps) => {
	const { classes, extraProps, inline, label, type } = props

	return (
		<Typography
			variant={type === 'title' ? 'h5' : 'h6'}
			component={type === 'title' ? 'h2' : 'h3'}
			className={`${type === 'title' ? classes.header : classes.subHeader} ${inline ? classes.inline : ''}`}
			{...extraProps}
		>
			{label}
		</Typography>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		header: {
			padding: `${theme.spacing.unit * 2}px 0`,
			textShadow: '0 0 2px rgba(0,0,0,0.2)',
			fontWeight: 500,
			[theme.breakpoints.down('sm')]: {
				padding: `${theme.spacing.unit}px 0`,
			},
		},
		subHeader: {
			color: '#36454f',
			textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
			fontWeight: 500,
			// paddingBottom: `${theme.spacing.unit}px`,
			[theme.breakpoints.down('sm')]: {
				padding: `${theme.spacing.unit}px 0`,
			},
		},
		inline: {
			display: 'inline-flex',
		},
	})

export default withStyles(styles)(TypographyHeader)
