import { ValidationKeys } from '../../interface/validationKeys';


export const partOfList = (options: string) => {
	return ' must be one of the following ' + options;
}
export const validationConstants: ValidationKeys = {
	'DATE_ERROR': ' must be formatted YYYY-MM-DD and valid',
	'DATE_EXAMPLE': '2019-06-21',
	'DATE_FORMAT': 'YYYY-MM-DD',
	'DATE_REGEX': /^\d{4}-\d{2}-\d{2}$/i,
	'EMPLOYER_EXAMPLE': 'ABC Inc.',
	'FLOAT_ERROR': ' must be (an integer or a decimal with up to two digits) greater than zero',
	'FLOAT_EXAMPLE': '15.00',
	'FLOAT_REGEX': /^\d*(\.\d{1,2})?$/i,
	'INDUSTRY_EXAMPLE': 'UNKNOWN | ACCOMODATION AND FOOD SERVICES | ADMIN AND SUPPORT, WASTE MANAGEMENT | AGRICULTURE, FORESTRY, FISHING AND HUNTING | ARTS, ENTERTAINMENT, AND RECREATION | CONSTRUCTION | EDUCATIONAL SERVICES | FINANCE AND INSURANCE | HEALTH CARE AND SOCIAL ASSISTANCE | INFORMATION | MANAGEMENT OF COMPANIES AND ENTERPRISES | MANUFACTURING | MINING | OTHER SERVICES (EXCEPT PUBLIC ADMINISTRATION) | PROFESSIONAL, SCIENTIFIC, AND TECHNICAL SERVICES | PUBLIC ADMINISTRATION | REAL ESTATE RENTAL AND LEASING | RETAIL TRADE | TRANSPORTATION AND WAREHOUSING | UTILITIES | WHOLESALE TRADE',
	'INDUSTRY_REGEX': /^(UNKNOWN|ACCOMODATION AND FOOD SERVICES|ADMIN AND SUPPORT, WASTE MANAGEMENT|AGRICULTURE, FORESTRY, FISHING AND HUNTING|ARTS, ENTERTAINMENT, AND RECREATION|CONSTRUCTION|EDUCATIONAL SERVICES|FINANCE AND INSURANCE|HEALTH CARE AND SOCIAL ASSISTANCE|INFORMATION|MANAGEMENT OF COMPANIES AND ENTERPRISES|MANUFACTURING|MINING|OTHER SERVICES (EXCEPT PUBLIC ADMINISTRATION)|PROFESSIONAL, SCIENTIFIC, AND TECHNICAL SERVICES|PUBLIC ADMINISTRATION|REAL ESTATE RENTAL AND LEASING|RETAIL TRADE|TRANSPORTATION AND WAREHOUSING|UTILITIES|WHOLESALE TRADE)$/i,
	'NAME_50_ERROR': ' must contain only letters, numbers, spaces, commas, #&\'-.(), and less than 51 characters',
	'NAME_50_REGEX': /^[a-zA-Z0-9 ,#&'\-.()]{1,50}$/i,
	'NAME_100_ERROR': ' must contain only letters, numbers, spaces, commas, #&\'-.(), and less than 101 characters',
	'NAME_100_REGEX': /^[a-zA-Z0-9 ,#&'\-.()]{1,100}$/i,
	'NUMBER_ERROR': ' must be an integer greater than zero',
	'NUMBER_EXAMPLE': '12345',
	'NUMBER_REGEX': /^\d+$/,
	'OCCUPATION_EXAMPLE': 'UNKNOWN | ARCHITECTURE AND ENGINEERING OCCUPATIONS | ARTS, DESIGN, ENTERTAINMENT, SPORTS, AND MEDIA OCCUPATIONS | BUILDING AND GROUNDS CLEANING AND MAINTENANCE OCCUPATIONS | BUSINESS AND FINANCIAL OPERATIONS OCCUPATIONS | COMMUNITY AND SOCIAL SERVICE OCCUPATIONS | COMPUTER AND MATHEMATICAL OCCUPATIONS | CONSTRUCTION AND EXTRACTION OCCUPATIONS | EDUCATION, TRAINING, AND LIBRARY OCCUPATIONS | FARMING, FISHING, AND FORESTRY OCCUPATIONS | FOOD PREPARATION AND SERVING RELATED OCCUPATIONS | HEALTHCARE PRACTITIONERS AND TECHNICAL OCCUPATIONS | HEALTHCARE SUPPORT OCCUPATIONS | INSTALLATION, MAINTENANCE, AND REPAIR OCCUPATIONS | LEGAL OCCUPATIONS | LIFE, PHYSICAL, AND SOCIAL SCIENCE OCCUPATIONS | MANAGEMENT OCCUPATIONS | OFFICE AND ADMINISTRATIVE SUPPORT OCCUPATIONS | PERSONAL CARE AND SERVICE OCCUPATIONS | PRODUCTION OCCUPATIONS | PROTECTIVE SERVICE OCCUPATIONS | SALES AND RELATED OCCUPATIONS | TRANSPORTATION AND MATERIAL MOVING OCCUPATIONS',
	'OCCUPATION_REGEX': /^(UNKNOWN|ARCHITECTURE AND ENGINEERING OCCUPATIONS|ARTS, DESIGN, ENTERTAINMENT, SPORTS, AND MEDIA OCCUPATIONS|BUILDING AND GROUNDS CLEANING AND MAINTENANCE OCCUPATIONS|BUSINESS AND FINANCIAL OPERATIONS OCCUPATIONS|COMMUNITY AND SOCIAL SERVICE OCCUPATIONS|COMPUTER AND MATHEMATICAL OCCUPATIONS|CONSTRUCTION AND EXTRACTION OCCUPATIONS|EDUCATION, TRAINING, AND LIBRARY OCCUPATIONS|FARMING, FISHING, AND FORESTRY OCCUPATIONS|FOOD PREPARATION AND SERVING RELATED OCCUPATIONS|HEALTHCARE PRACTITIONERS AND TECHNICAL OCCUPATIONS|HEALTHCARE SUPPORT OCCUPATIONS|INSTALLATION, MAINTENANCE, AND REPAIR OCCUPATIONS|LEGAL OCCUPATIONS|LIFE, PHYSICAL, AND SOCIAL SCIENCE OCCUPATIONS|MANAGEMENT OCCUPATIONS|OFFICE AND ADMINISTRATIVE SUPPORT OCCUPATIONS|PERSONAL CARE AND SERVICE OCCUPATIONS|PRODUCTION OCCUPATIONS|PROTECTIVE SERVICE OCCUPATIONS|SALES AND RELATED OCCUPATIONS|TRANSPORTATION AND MATERIAL MOVING OCCUPATIONS)$/i,
	'STATE_INITIALS_EXAMPLE': 'AK | AL | AR | AZ | CA | CO | CT | DE | FL | GA | HI | IA | ID | IL | IN | KS | KY | LA | MA | MD | ME | MI | MN | MO | MS | MT | NC | ND | NE | NH | NJ | NM | NV | NY | OH | OK | OR | PA | RI | SC | SD | TN | TX | UT | VA | VT | WA | WI | WV | WY',
	'STATE_INITIALS_REGEX': /^(AK|AL|AR|AZ|CA|CO|CT|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NC|ND|NE|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)$/i,
	'STRING_255_REGEX': /^[\w ,#&'\-.()!@$%*+?/]{1,255}$/i,
	'STRING_255_ERROR': ' must contain only letters, numbers, spaces, commas, #&\'-.()_!@$%*+?/, and less than 256 characters',
	'SURVEY_EXAMPLE': 'YES | NO | DND | NA',
	'SURVEY_REGEX': /^(YES|NO|DND|NA)$/i,
	'WAGE_FREQUENCY_EXAMPLE': 'HOUR | WEEK | YEAR',
	'WAGE_FREQUENCY_REGEX': /^(HOUR|WEEK|YEAR)$/i,
	'YES_NO_ERROR': ' must be YES or NO',
	'YES_NO_EXAMPLE': 'YES or NO',
	'YES_NO_REGEX': /^(YES|NO)$/i,
	'ZIP_ERROR': ' must be 5 or 9 digits',
	'ZIP_EXAMPLE': '02860 | 02860-0234',
	'ZIP_REGEX': /^\d{5}(-\d{4})?$/i,
}
