import { OutcomeFormValidationKeys } from '../../interface/outcomeFormValidationKeys';
import { outcomeFormConstants } from '../outcomeFormConstants';
import { partOfList, validationConstants } from '../validation/validationConstants';

export const outcomeFormValidationConstants: OutcomeFormValidationKeys = {
	'ACTIVITY_PARTICIPANT_ID_REGEX': validationConstants.NUMBER_REGEX,
	'ACTIVITY_PARTICIPANT_ID_ERROR': outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY + validationConstants.NUMBER_ERROR,
	'STATUS_REGEX': /^(ENROLLED|COMPLETED|DROPPED_OUT)$/i,
	'STATUS_ERROR': outcomeFormConstants.STATUS_KEY + partOfList(outcomeFormConstants.STATUS_EXAMPLE),
	'DROP_REASON_REGEX': /^(UNKNOWN|ACCEPTED AN OUTSIDE\/UNRELATED EMPLOYMENT OPPORTUNITY|ARRESTED, ON TRIAL OR INCARCERATED|CHALLENGE WITH GOVERNMENT BENEFIT\/SOCIAL ASSISTANCE|CHANGE IN CHILD OR OTHER DEPENDENT CARE|CONFLICT OR CHANGE IN WORK SCHEDULE|DEATH OF A FAMILY MEMBER|DECEASED|DECIDED THE PROGRAM WASN’T FOR THEM|DISCHARGED DUE TO UNSATISFACTORY PERFORMANCE|EXTENDED ILLNESS \(PARTICIPANT OR FAMILY MEMBER\)|FINANCIAL DIFFICULTIES|MILITARY ACTIVATION OR RELOCATION \(PARTICIPANT OR FAMILY MEMBER\)|NO CALL\/NO SHOW \(ADDITIONAL INFORMATION UNAVAILABLE\)|RELOCATION|TRANSPORTATION CHALLENGES|WITHDREW BEFORE START OF TRAINING)$/i,
	'DROP_REASON_ERROR': outcomeFormConstants.DROP_REASON_KEY + partOfList(outcomeFormConstants.DROP_REASON_EXAMPLE),
	'ACTUAL_START_DATE_ERROR': outcomeFormConstants.ACTUAL_START_DATE_KEY + validationConstants.DATE_ERROR,
	'ACTUAL_END_DATE_ERROR': outcomeFormConstants.ACTUAL_END_DATE_KEY + validationConstants.DATE_ERROR,
	'WBL_TYPE_REGEX': /^(INTERNSHIP|INDUSTRY PROJECT|SERVICE LEARNING PROJECT|SCHOOL-BASED ENTERPRISE|APPRENTICESHIP|PRE-APPRENTICESHIP)$/i,
	'WBL_TYPE_ERROR': outcomeFormConstants.WBL_TYPE_KEY + partOfList(outcomeFormConstants.WBL_TYPE_EXAMPLE),
	'UNPAID_HOURS_REGEX': validationConstants.NUMBER_REGEX,
	'UNPAID_HOURS_ERROR': outcomeFormConstants.UNPAID_HOURS_KEY + validationConstants.NUMBER_ERROR,
	'PAID_HOURS_REGEX': validationConstants.NUMBER_REGEX,
	'PAID_HOURS_ERROR': outcomeFormConstants.PAID_HOURS_KEY + validationConstants.NUMBER_ERROR,
	'INTERNSHIP_OCCUPATION_REGEX': validationConstants.OCCUPATION_REGEX,
	'INTERNSHIP_OCCUPATION_ERROR': outcomeFormConstants.INTERNSHIP_OCCUPATION_KEY + partOfList(validationConstants.OCCUPATION_EXAMPLE),
	'INTERNSHIP_EMPLOYER_REGEX': validationConstants.STRING_255_REGEX,
	'INTERNSHIP_EMPLOYER_ERROR': outcomeFormConstants.INTERNSHIP_EMPLOYER_KEY + validationConstants.STRING_255_ERROR,
	'INTERNSHIP_INDUSTRY_REGEX': validationConstants.INDUSTRY_REGEX,
	'INTERNSHIP_INDUSTRY_ERROR': outcomeFormConstants.INTERNSHIP_INDUSTRY_KEY + partOfList(validationConstants.INDUSTRY_EXAMPLE),
	'TRAINING_HOURS_REGEX': validationConstants.NUMBER_REGEX,
	'TRAINING_HOURS_ERROR': outcomeFormConstants.TRAINING_HOURS_KEY + validationConstants.NUMBER_ERROR,
	'PLACED_JOB_REGEX': validationConstants.YES_NO_REGEX,
	'PLACED_JOB_ERROR': outcomeFormConstants.PLACED_JOB_KEY + validationConstants.YES_NO_ERROR,
	'PLACED_JOB_DATE_ERROR': outcomeFormConstants.PLACED_JOB_DATE_KEY + validationConstants.DATE_ERROR,
	'PLACED_JOB_EMPLOYER_REGEX': validationConstants.STRING_255_REGEX,
	'PLACED_JOB_EMPLOYER_ERROR': outcomeFormConstants.PLACED_JOB_EMPLOYER_KEY + validationConstants.STRING_255_ERROR,
	'PLACED_JOB_ADDRESS_1_REGEX': validationConstants.STRING_255_REGEX,
	'PLACED_JOB_ADDRESS_1_ERROR': outcomeFormConstants.PLACED_JOB_ADDRESS_1_KEY + validationConstants.STRING_255_ERROR,
	'PLACED_JOB_ADDRESS_2_REGEX': validationConstants.STRING_255_REGEX,
	'PLACED_JOB_ADDRESS_2_ERROR': outcomeFormConstants.PLACED_JOB_ADDRESS_2_KEY + validationConstants.STRING_255_ERROR,
	'PLACED_JOB_CITY_REGEX': validationConstants.STRING_255_REGEX,
	'PLACED_JOB_CITY_ERROR': outcomeFormConstants.PLACED_JOB_CITY_KEY + validationConstants.STRING_255_ERROR,
	'PLACED_JOB_STATE_REGEX': validationConstants.STATE_INITIALS_REGEX,
	'PLACED_JOB_STATE_ERROR': outcomeFormConstants.PLACED_JOB_STATE_KEY + partOfList(outcomeFormConstants.PLACED_JOB_STATE_EXAMPLE),
	'PLACED_JOB_ZIP_REGEX': validationConstants.ZIP_REGEX,
	'PLACED_JOB_ZIP_ERROR': outcomeFormConstants.PLACED_JOB_ZIP_KEY + validationConstants.ZIP_ERROR,
	'PLACED_JOB_TITLE_REGEX': validationConstants.STRING_255_REGEX,
	'PLACED_JOB_TITLE_ERROR': outcomeFormConstants.PLACED_JOB_TITLE_KEY + validationConstants.STRING_255_ERROR,
	'PLACED_JOB_OCCUPATION_REGEX': validationConstants.OCCUPATION_REGEX,
	'PLACED_JOB_OCCUPATION_ERROR': outcomeFormConstants.PLACED_JOB_OCCUPATION_KEY + partOfList(validationConstants.OCCUPATION_EXAMPLE),
	'PLACED_JOB_INDUSTRY_REGEX': validationConstants.INDUSTRY_REGEX,
	'PLACED_JOB_INDUSTRY_ERROR': outcomeFormConstants.PLACED_JOB_INDUSTRY_KEY + partOfList(validationConstants.INDUSTRY_EXAMPLE),
	'PLACED_JOB_WAGE_REGEX': validationConstants.FLOAT_REGEX,
	'PLACED_JOB_WAGE_ERROR': outcomeFormConstants.PLACED_JOB_WAGE_KEY + validationConstants.FLOAT_ERROR,
	'PLACED_JOB_WAGE_FREQUENCY_REGEX': validationConstants.WAGE_FREQUENCY_REGEX,
	'PLACED_JOB_WAGE_FREQUENCY_ERROR': outcomeFormConstants.PLACED_JOB_WAGE_FREQUENCY_KEY + partOfList(validationConstants.WAGE_FREQUENCY_EXAMPLE),
	'PLACED_JOB_WEEKLY_HOURS_REGEX': validationConstants.NUMBER_REGEX,
	'PLACED_JOB_WEEKLY_HOURS_ERROR': outcomeFormConstants.PLACED_JOB_WEEKLY_HOURS_KEY + validationConstants.NUMBER_ERROR,
	'BENEFITS_REGEX': validationConstants.YES_NO_REGEX,
	'BENEFITS_ERROR': outcomeFormConstants.BENEFITS_KEY + validationConstants.YES_NO_ERROR,
	'PER_DIEM_REGEX': validationConstants.YES_NO_REGEX,
	'PER_DIEM_ERROR': outcomeFormConstants.PER_DIEM_KEY + validationConstants.YES_NO_ERROR,
	'INCUMBENT_EMPLOYER_REGEX': validationConstants.STRING_255_REGEX,
	'INCUMBENT_EMPLOYER_ERROR': outcomeFormConstants.INCUMBENT_EMPLOYER_KEY + validationConstants.STRING_255_ERROR,
	'ACTUAL_WAGE_INCREASE_REGEX': validationConstants.YES_NO_REGEX,
	'ACTUAL_WAGE_INCREASE_ERROR': outcomeFormConstants.ACTUAL_WAGE_INCREASE_KEY + validationConstants.YES_NO_ERROR,
	'ACTUAL_PROMOTION_REGEX': validationConstants.YES_NO_REGEX,
	'ACTUAL_PROMOTION_ERROR': outcomeFormConstants.ACTUAL_PROMOTION_KEY + validationConstants.YES_NO_ERROR,
	'ACUTAL_POSITION_BACKFILLED_REGEX': validationConstants.YES_NO_REGEX,
	'ACUTAL_POSITION_BACKFILLED_ERROR': outcomeFormConstants.ACUTAL_POSITION_BACKFILLED_KEY + validationConstants.YES_NO_ERROR,
	'ACTUAL_EARNED_DEGREE_REGEX': validationConstants.YES_NO_REGEX,
	'ACTUAL_EARNED_DEGREE_ERROR': outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY + validationConstants.YES_NO_ERROR,
	'ACTUAL_EARNED_CREDITS_REGEX': validationConstants.FLOAT_REGEX,
	'ACTUAL_EARNED_CREDITS_ERROR': outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY + validationConstants.FLOAT_ERROR,
	'CREDIT_TYPE_REGEX': /^(HIGH SCHOOL CREDIT|COLLEGE CREDIT)$/i,
	'CREDIT_TYPE_ERROR': outcomeFormConstants.CREDIT_TYPE_KEY + partOfList(outcomeFormConstants.CREDIT_TYPE_EXAMPLE),
	'CREDENTIAL_TYPE_REGEX': /^(UNKNOWN|INDUSTRY|NON-INDUSTRY|PRE-APPRENTICESHIP|APPRENTICESHIP|NONE)$/i,
	'CREDENTIAL_TYPE_ERROR': outcomeFormConstants.CREDENTIAL_TYPE_KEY + partOfList(outcomeFormConstants.CREDENTIAL_TYPE_EXAMPLE),
	'CREDENTIAL_1_REGEX': validationConstants.STRING_255_REGEX,
	'CREDENTIAL_1_ERROR': outcomeFormConstants.CREDENTIAL_1_KEY + validationConstants.STRING_255_ERROR,
	'CREDENTIAL_2_REGEX': validationConstants.STRING_255_REGEX,
	'CREDENTIAL_2_ERROR': outcomeFormConstants.CREDENTIAL_2_KEY + validationConstants.STRING_255_ERROR,
	'CREDENTIAL_3_REGEX': validationConstants.STRING_255_REGEX,
	'CREDENTIAL_3_ERROR': outcomeFormConstants.CREDENTIAL_3_KEY + validationConstants.STRING_255_ERROR,
	'CREDENTIAL_4_REGEX': validationConstants.STRING_255_REGEX,
	'CREDENTIAL_4_ERROR': outcomeFormConstants.CREDENTIAL_4_KEY + validationConstants.STRING_255_ERROR,
	'CREDENTIAL_5_REGEX': validationConstants.STRING_255_REGEX,
	'CREDENTIAL_5_ERROR': outcomeFormConstants.CREDENTIAL_5_KEY + validationConstants.STRING_255_ERROR,
	'ACADEMIC_AWARD_REGEX': validationConstants.STRING_255_REGEX,
	'ACADEMIC_AWARD_ERROR': outcomeFormConstants.ACADEMIC_AWARD_KEY + validationConstants.STRING_255_ERROR,
	'STIPEND_RECEIVED_REGEX': validationConstants.YES_NO_REGEX,
	'STIPEND_RECEIVED_ERROR': outcomeFormConstants.STIPEND_RECEIVED_KEY + validationConstants.YES_NO_ERROR,
	'STIPEND_AMOUNT_REGEX': validationConstants.FLOAT_REGEX,
	'STIPEND_AMOUNT_ERROR': outcomeFormConstants.STIPEND_AMOUNT_KEY + validationConstants.FLOAT_ERROR,
	'INCENTIVE_EARNED_REGEX': /^(INCENTIVE|STIPEND|WAGE)$/i,
	'INCENTIVE_EARNED_ERROR': outcomeFormConstants.INCENTIVE_EARNED_KEY + partOfList(outcomeFormConstants.INCENTIVE_EARNED_EXAMPLE),
	'INCENTIVE_TYPE_REGEX': /^(BOOKS|CLOTHING|FOOD|GIFT_CARD|TRANSPORTATION)$/i,
	'INCENTIVE_TYPE_ERROR': outcomeFormConstants.INCENTIVE_TYPE_KEY + partOfList(outcomeFormConstants.INCENTIVE_TYPE_EXAMPLE),
	'STIPEND_FREQUENCY_REGEX': /^(BEGINNING|MULTIPLE|COMPLETION)$/i,
	'STIPEND_FREQUENCY_ERROR': outcomeFormConstants.STIPEND_FREQUENCY_KEY + partOfList(outcomeFormConstants.STIPEND_FREQUENCY_EXAMPLE),
	'WAGE_AMOUNT_REGEX': validationConstants.FLOAT_REGEX,
	'WAGE_AMOUNT_ERROR': outcomeFormConstants.WAGE_AMOUNT_KEY + validationConstants.FLOAT_ERROR,
	'WAGE_FREQUENCY_REGEX': validationConstants.WAGE_FREQUENCY_REGEX,
	'WAGE_FREQUENCY_ERROR': outcomeFormConstants.WAGE_FREQUENCY_KEY + partOfList(validationConstants.WAGE_FREQUENCY_EXAMPLE),
	'PLACED_TRAINING_REGEX': validationConstants.YES_NO_REGEX,
	'PLACED_TRAINING_ERROR': outcomeFormConstants.PLACED_TRAINING_KEY + validationConstants.YES_NO_ERROR,
	'PLACED_DIRECT_REGEX': validationConstants.YES_NO_REGEX,
	'PLACED_DIRECT_ERROR': outcomeFormConstants.PLACED_DIRECT_KEY + validationConstants.YES_NO_ERROR,
	'CLASSROOM_HOURS_REGEX': validationConstants.NUMBER_REGEX,
	'CLASSROOM_HOURS_ERROR': outcomeFormConstants.CLASSROOM_HOURS_KEY + validationConstants.NUMBER_ERROR,
	'SERVICE_PROVIDED_REGEX': validationConstants.YES_NO_REGEX,
	'SERVICE_PROVIDED_ERROR': outcomeFormConstants.SERVICE_PROVIDED_KEY + validationConstants.YES_NO_ERROR,
	'SERVICE_START_DATE_ERROR': outcomeFormConstants.SERVICE_START_DATE_KEY + validationConstants.DATE_ERROR,
	'SERVICE_END_DATE_ERROR': outcomeFormConstants.SERVICE_END_DATE_KEY + validationConstants.DATE_ERROR,
	'SERVICES_REGEX': /^(APPEARANCE|BILLS|CHILDCARE|COUNSELING|FINANCE|FOOD|HOUSING|LEGAL|MEDICAL|OTHER|SNAP|SOCIAL|TECHNOLOGY|TRANSPORTATION)$/i,
	'SERVICES_ERROR': outcomeFormConstants.SERVICES_KEY + ' must be a comma separated list of one or more of the following ' + outcomeFormConstants.SERVICES_EXAMPLE,
}
