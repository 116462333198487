import { ParticipantFormValidationKeys } from '../../interface/participantFormValidationKeys';
import { participantFormConstants } from '../participantFormConstants';
import {partOfList, validationConstants} from '../validation/validationConstants';

export const participantFormValidationConstants: ParticipantFormValidationKeys = {
	ACTIVITY_ID_REGEX: validationConstants.NUMBER_REGEX,
	ACTIVITY_ID_ERROR: participantFormConstants.ACTIVITY_ID_KEY + validationConstants.NUMBER_ERROR,
	FIRST_NAME_REGEX: validationConstants.NAME_50_REGEX,
	FIRST_NAME_ERROR: participantFormConstants.FIRST_NAME_KEY + validationConstants.NAME_50_ERROR,
	MIDDLE_INITIAL_REGEX: /^[A-Za-z]$/i,
	MIDDLE_INITIAL_ERROR: participantFormConstants.MIDDLE_INITIAL_KEY + ' must be a single letter',
	LAST_NAME_REGEX: validationConstants.NAME_50_REGEX,
	LAST_NAME_ERROR: participantFormConstants.LAST_NAME_KEY + validationConstants.NAME_50_ERROR,
	DATE_OF_BIRTH_ERROR: participantFormConstants.DATE_OF_BIRTH_KEY + validationConstants.DATE_ERROR,
	HOMELESS_REGEX: validationConstants.YES_NO_REGEX,
	HOMELESS_ERROR: participantFormConstants.HOMELESS_KEY + validationConstants.YES_NO_ERROR,
	ADDRESS1_REGEX: validationConstants.NAME_100_REGEX,
	ADDRESS1_ERROR: participantFormConstants.ADDRESS1_KEY + validationConstants.NAME_100_ERROR,
	ADDRESS2_REGEX: validationConstants.NAME_100_REGEX,
	ADDRESS2_ERROR: participantFormConstants.ADDRESS2_KEY + validationConstants.NAME_100_ERROR,
	CITY_REGEX: validationConstants.NAME_50_REGEX,
	CITY_ERROR: participantFormConstants.CITY_KEY + validationConstants.NAME_50_ERROR,
	STATE_REGEX: validationConstants.STATE_INITIALS_REGEX,
	STATE_ERROR: participantFormConstants.STATE_KEY + partOfList(participantFormConstants.STATE_EXAMPLE),
	ZIP_REGEX: validationConstants.ZIP_REGEX,
	ZIP_ERROR: participantFormConstants.ZIP_KEY + validationConstants.ZIP_ERROR,
	MAILING_ADDRESS1_REGEX: validationConstants.NAME_100_REGEX,
	MAILING_ADDRESS1_ERROR: participantFormConstants.MAILING_ADDRESS1_KEY + validationConstants.NAME_100_ERROR,
	MAILING_ADDRESS2_REGEX: validationConstants.NAME_100_REGEX,
	MAILING_ADDRESS2_ERROR: participantFormConstants.MAILING_ADDRESS2_KEY + validationConstants.NAME_100_ERROR,
	MAILING_CITY_REGEX: validationConstants.NAME_50_REGEX,
	MAILING_CITY_ERROR: participantFormConstants.MAILING_CITY_EXAMPLE + validationConstants.NAME_50_ERROR,
	MAILING_STATE_REGEX: validationConstants.STATE_INITIALS_REGEX,
	MAILING_STATE_ERROR: participantFormConstants.MAILING_STATE_KEY + partOfList(participantFormConstants.STATE_EXAMPLE),
	MAILING_ZIP_REGEX: validationConstants.ZIP_REGEX,
	MAILING_ZIP_ERROR: participantFormConstants.MAILING_ZIP_KEY + validationConstants.ZIP_ERROR,
	PHONE_REGEX: /^\d{10}$/i,
	PHONE_ERROR: participantFormConstants.PHONE_KEY + ' must be a 10 digit number',
	EMAIL_REGEX: /^.{1,100}$/i,
	EMAIL_ERROR: participantFormConstants.EMAIL_KEY + ' must be less than 100 characters in length',
	GENDER_REGEX: /^(FEMALE|MALE|NA|TRANS_FEMALE|TRANS_MALE|NON_BINARY|OTHER|DND)$/i,
	GENDER_ERROR: participantFormConstants.GENDER_KEY + partOfList(participantFormConstants.GENDER_EXAMPLE),
	ETHNICITY_REGEX: /^(LATINO|NOT-LATINO|NA)$/i,
	ETHNICITY_ERROR: participantFormConstants.ETHNICITY_KEY + partOfList(participantFormConstants.ETHNICITY_EXAMPLE),
	RACES_REGEX: /^(((ASIAN|BLACK|INDIGENOUS|ISLANDER|WHITE)+(,(ASIAN|BLACK|INDIGENOUS|ISLANDER|WHITE)+)*)|NA)$/i,
	RACES_ERROR: participantFormConstants.RACES_KEY + ' must be ' + participantFormConstants.RACES_EXAMPLE,
	PREFERRED_LANGUAGE_REGEX: /^(ARABIC|CHINESE|ENGLISH|FRENCH|HAITIAN CREOLE|ITALIAN|KHMER|PORTUGUESE|SPANISH|OTHER)$/i,
	PREFERRED_LANGUAGE_ERROR: participantFormConstants.PREFERRED_LANGUAGE_KEY + partOfList(participantFormConstants.PREFERRED_LANGUAGE_EXAMPLE),
	CITIZEN_REGEX: validationConstants.SURVEY_REGEX,
	CITIZEN_ERROR: participantFormConstants.CITIZEN_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	VETERAN_REGEX: validationConstants.SURVEY_REGEX,
	VETERAN_ERROR: participantFormConstants.VETERAN_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
  DISABILITY_STATUS_REGEX: validationConstants.SURVEY_REGEX,
  DISABILITY_STATUS_ERROR: participantFormConstants.DISABILITY_STATUS_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
  DISABILITIES_REGEX: /^(((PHYSICAL|HEARING|VISION|SOCIAL\/EMOTIONAL|INTELLECTUAL\/DEVELOPMENTAL\/COGNITIVE)+(,(PHYSICAL|HEARING|VISION|SOCIAL\/EMOTIONAL|INTELLECTUAL\/DEVELOPMENTAL\/COGNITIVE)+)*)|DND)$/i,
	DISABILITIES_ERROR: participantFormConstants.DISABILITIES_KEY + ' must be ' + participantFormConstants.DISABILITIES_EXAMPLE,
	ELL_REGEX: validationConstants.SURVEY_REGEX,
	ELL_ERROR: participantFormConstants.ELL_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	EDUCATION_REGEX: /^(NO_HIGHSCHOOL|HIGH_SCHOOL|GED|SOME_COLLEGE|VOCATIONAL|ASSOCIATE|BACHELORS|MASTERS|PHD|NA)$/i,
	EDUCATION_ERROR: participantFormConstants.EDUCATION_KEY + partOfList(participantFormConstants.EDUCATION_EXAMPLE),
	HIGHEST_GRADE_REGEX: /^([1-9]|1[0-2]?)$/i,
	HIGHEST_GRADE_ERROR: participantFormConstants.HIGHEST_GRADE_KEY + ' must be a number 1-12',
	HIGHEST_GRADE_ERROR2: participantFormConstants.HIGHEST_GRADE_KEY + ' is required when No_HighSchool selected for Highest level of education',
	IN_SCHOOL_REGEX: validationConstants.SURVEY_REGEX,
	IN_SCHOOL_ERROR: participantFormConstants.IN_SCHOOL_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	CURRENT_SCHOOL_REGEX: validationConstants.NAME_100_REGEX,
	CURRENT_SCHOOL_ERROR: participantFormConstants.CURRENT_SCHOOL_KEY + validationConstants.NAME_100_ERROR,
	WORKING_REGEX: validationConstants.SURVEY_REGEX,
	WORKING_ERROR: participantFormConstants.WORKING_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	EMPLOYER_REGEX: validationConstants.NAME_100_REGEX,
	EMPLOYER_ERROR: participantFormConstants.EMPLOYER_KEY + validationConstants.NAME_100_ERROR,
	EMPLOYMENT_TYPE_REGEX: /^(FULL_TIME|PART_TIME)$/i,
	EMPLOYMENT_TYPE_ERROR: participantFormConstants.EMPLOYMENT_TYPE_KEY + partOfList(participantFormConstants.EMPLOYMENT_TYPE_EXAMPLE),
	LAID_OFF_REGEX: validationConstants.SURVEY_REGEX,
	LAID_OFF_ERROR: participantFormConstants.LAID_OFF_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	LAYOFF_DATE_ERROR: participantFormConstants.LAYOFF_DATE_KEY + validationConstants.DATE_ERROR,
	LAYOFF_EMPLOYER_REGEX: validationConstants.NAME_100_REGEX,
	LAYOFF_EMPLOYER_ERROR: participantFormConstants.LAYOFF_EMPLOYER_KEY + validationConstants.NAME_100_ERROR,
	HIGHER_WAGES_REGEX: validationConstants.SURVEY_REGEX,
	HIGHER_WAGES_ERROR: participantFormConstants.HIGHER_WAGES_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	SKILLS_REGEX: validationConstants.SURVEY_REGEX,
	SKILLS_ERROR: participantFormConstants.SKILLS_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	FAMILY_DEPENDENT_REGEX: validationConstants.SURVEY_REGEX,
	FAMILY_DEPENDENT_ERROR: participantFormConstants.FAMILY_DEPENDENT_KEY + partOfList(validationConstants.SURVEY_EXAMPLE),
	COVID_REGEX: validationConstants.YES_NO_REGEX,
	COVID_ERROR: participantFormConstants.COVID_KEY + validationConstants.YES_NO_ERROR,
  QUEST_REGEX: validationConstants.YES_NO_REGEX,
	QUEST_ERROR: participantFormConstants.QUEST_KEY + validationConstants.YES_NO_ERROR,
	// ACCEPTED_COVID_REGEX: validationConstants.YES_NO_REGEX,
	// ACCEPTED_COVID_ERROR: participantFormConstants.ACCEPTED_COVID_KEY + validationConstants.YES_NO_ERROR,
}
