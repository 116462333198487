import { Menu, MenuItem, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import MenuIcon from '@material-ui/icons/Menu'
import * as React from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'

import { NavigationLink } from '../NavBar/NavBar'

interface NavProps extends WithStyles<typeof styles> {
	navLinks: NavigationLink[]
}

interface NavState {
	anchorEl: any
	mobileMoreAnchorEl: any
}

export type RouterProps = NavProps & RouteComponentProps<{}> & WithStyles<{}>

export class MobileNavBar extends React.Component<RouterProps, NavState> {
	state = {
		anchorEl: null,
		mobileMoreAnchorEl: null,
	}

	handleMobileMenuOpen = (event: any) => {
		this.setState({ mobileMoreAnchorEl: event.currentTarget })
	}

	handleMobileMenuClose = () => {
		this.setState({ mobileMoreAnchorEl: null })
	}

	handleNavItemClick = () => {
		this.handleMobileMenuClose()
	}

	render() {
		const { classes } = this.props
		const { mobileMoreAnchorEl } = this.state
		const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

		return (
			<div className={classes.navContainer} data-auto="app-mobile-navbar-wrapper">
				<IconButton
					className={classes.menuButton}
					color="inherit"
					aria-label="Menu"
					onClick={this.handleMobileMenuOpen}
					data-auto="mobile-app-menu-icon"
				>
					<MenuIcon />
				</IconButton>

				<Menu
					anchorEl={mobileMoreAnchorEl}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={isMobileMenuOpen}
					onClose={this.handleMobileMenuClose}
					data-auto="app-mobile-navbar"
				>
					{this.props.navLinks.map((link, index) => (
						<NavLink to={link.url} className={classes.navItem} key={index}>
							<MenuItem onClick={this.handleNavItemClick} data-auto="mobile-app-navbar-item">
								<Typography variant="button" color="inherit" align="justify" data-auto="mobile-app-navbar-item">
									{link.title}
								</Typography>
							</MenuItem>
						</NavLink>
					))}
				</Menu>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		menuButton: {},

		navContainer: {
			height: '100%',
			display: 'none',

			[theme.breakpoints.down('sm')]: {
				display: 'flex',
			},
		},
		navItem: {
			textDecoration: 'none',
			color: theme.palette.grey[800],
			fontWeight: 'bold',
			transition: 'all .2s ease-in-out',

			'&:hover': {
				color: blue[900],
				transform: 'scale(1.2)',
			},
		},
	})

export const StyledNavBar = withStyles(styles)(MobileNavBar)

export default withRouter(StyledNavBar)
