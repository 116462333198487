export interface Partnership {
    createdDate: Date
    effectiveDate: Date
    endDate: Date
    id: string
    lastModifiedDate: Date
    leadApplicantId: string
    name: string
    leadApplicantName: string
    programType: ProgramType
}

export enum ProgramType {
    REAL_JOBS = "REAL_JOBS",
    REAL_SKILLS = "REAL_SKILLS",
    REAL_PATHWAYS = "REAL_PATHWAYS"
}

export const ProgramTypeMapper = new Map<ProgramType, string>([
    [ProgramType.REAL_JOBS, 'Real Jobs'],
    [ProgramType.REAL_SKILLS, 'Real Skills'],
    [ProgramType.REAL_PATHWAYS, 'Real Pathways']
]);