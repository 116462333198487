export const simpleErrorLogger = (e: Error) => {console.log('simple', e)}

export const nullableReturn = (e: Error) => null

export function genericReturnWithLogger<T>(arg: T): (e: Error) => T {
	return (e: Error) => {
		console.log(e.message)
		return arg
	}
}

export const preventFormDefault = (e: any) => {
	// @ts-ignore
	e.returnValue = false
	e.preventDefault()
}