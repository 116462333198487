import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import Participant from '../Participant/Participant'
import Participants from '../Participants/Participants'

interface ParticipantsLandingProps extends WithStyles<typeof styles> {}
export type RouterProps = ParticipantsLandingProps & RouteComponentProps<{}> & WithStyles<{}>

export const ParticipantsLanding: React.SFC<RouterProps> = (props: RouterProps) => {
	const { classes, match } = props

	return (
		<div className={classes.root} data-auto="partners-content-wrapper">
			<Route exact={true} path={match.url} component={Participants} />
			<Route exact={true} path={`${match.url}/:participantID`} component={Participant} />
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing.unit * 3,
			overflowX: 'auto',
			padding: '10px',
		},
	})

export default withStyles(styles)(ParticipantsLanding)
