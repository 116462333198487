import { Button, Card, CardActions, CardContent, Divider, Hidden } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import React from 'react'

import {EthnicityMapper, GenderMapper, Participant, Race} from '../../services/model'
import {RaceMapper} from "../../services/model/Participant";
import { phoneNumberFormatter } from '../../utils/stringFormatters'
import InfoItem from '../templates/InfoItem/InfoItem'

interface ParticipantInfoProps extends WithStyles<typeof styles> {
	participant: Participant
	handleEditClick: () => void
}

export const participantObjectMapper = [
	{
		label: 'DMS Participant ID',
		prop: 'id',
	},
	{
		label: 'First Name',
		prop: 'firstName',
	},
	{
		label: 'Middle Initial',
		prop: 'middleInitial',
	},
	{
		label: 'Last Name',
		prop: 'lastName',
	},
	{
		label: 'Gender',
		prop: 'gender',
	},
	{
		label: 'Ethnicity',
		prop: 'ethnicity',
	},
	{
		label: 'Race',
		prop: 'races',
	},
	{
		label: 'Date of Birth',
		prop: 'dateOfBirth',
	},
	{
		label: 'Phone',
		prop: 'phoneNumber',
	},
	{
		label: 'Email',
		prop: 'email',
	},
]

const ADDRESS_ITEMS = [
	{
		prop: 'homeless',
		label: 'Homeless',
	},
	{
		prop: 'address1',
		label: 'Address Line 1',
	},
	{
		prop: 'address2',
		label: 'Address Line 2',
	},
	{
		prop: 'city',
		label: 'City',
	},
	{
		prop: 'state',
		label: 'State',
	},
	{
		prop: 'zip',
		label: 'Zip',
	},
]

const SECONDARY_ADDRESS_ITEMS = [
	{
		prop: 'mailingAddress1',
		label: 'Mailing Address Line 1',
	},
	{
		prop: 'mailingAddress2',
		label: 'Mailing Address Line 2',
	},
	{
		prop: 'mailingCity',
		label: 'Mailing City',
	},
	{
		prop: 'mailingState',
		label: 'Mailing State',
	},
	{
		prop: 'mailingZip',
		label: 'Mailing Zip',
	},
]

const enrollmentDataParser = (prop: any, participant: Participant) => {
	let passValue

	switch (prop) {
		case 'gender':
			passValue = GenderMapper.get(participant[prop]) || ''
			break
		case 'ethnicity':
			passValue = EthnicityMapper.get(participant[prop]) || ''
			break
		case 'phoneNumber':
			passValue = participant[prop] ? phoneNumberFormatter(participant[prop]) : ''
			break
		case 'dateOfBirth':
			passValue = participant[prop] ? moment(participant[prop]).format('L') : ''
			break
		case 'homeless':
			passValue = participant[prop] ? 'Yes' : 'No'
			break
		case 'races':
			passValue = participant[prop] ? participant[prop].map((r: Race) => RaceMapper.get(r)).join(' / ') : ''
			break
		case 'sameAsAddress':
			passValue = participant[prop] ? 'Yes' : 'No'
			break
		default:
			passValue = participant[prop] || ''
	}

	return passValue
}

export const ParticipantInfo: React.SFC<ParticipantInfoProps> = (props: ParticipantInfoProps) => {
	const { classes, participant } = props
	const localParticipant = { ...participant }

	return (
		<div className={classes.root} data-auto="participant-info">
			{localParticipant && (
				<Card className={classes.card} elevation={0}>
					<CardContent>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							{participantObjectMapper.map((item) => {
								if (item.label === 'DMS Participant ID') {
									return <InfoItem label={item.label} value={enrollmentDataParser(item.prop, localParticipant)} dualCol={false} key={item.prop} />
								} else {
									return <InfoItem label={item.label} value={enrollmentDataParser(item.prop, localParticipant)} dualCol={true} key={item.prop} />
								}
							})}
						</div>

						<Divider className={classes.divider} />
						{[ADDRESS_ITEMS, SECONDARY_ADDRESS_ITEMS].map((parent, index) => (
							<React.Fragment key={index}>
								<div style={{ display: 'flex', flexWrap: 'wrap' }}>
									{parent.map((item) => {
										return <InfoItem label={item.label} value={enrollmentDataParser(item.prop, localParticipant)} dualCol={true} key={item.prop} />
									})}
								</div>
							</React.Fragment>
						))}
					</CardContent>
					<CardActions>
						<Hidden xsDown={true}>
							<Button variant="contained" color="default" className={classes.editButton} onClick={props.handleEditClick}>
								<EditIcon className={classes.editIcon} />
								Edit
							</Button>
						</Hidden>
					</CardActions>
				</Card>
			)}
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		card: {},
		editButton: {},
		editIcon: {},
		itemLink: {
			textDecoration: 'none',
		},
		divider: {
			margin: `${theme.spacing.unit}px 0`,
		},
	})

export default withStyles(styles)(ParticipantInfo)
