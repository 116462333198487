import { Disability } from "./Participant"

export interface SurveyData {
	activityParticipantId: string
	citizen?: SurveyResponse
	veteran?: SurveyResponse
  disabledStatus?: SurveyResponse
  disabilities?: Disability[]
	ell?: SurveyResponse
	education?: Education
	highestGrade?: number
	attendingSchool?: SurveyResponse
	currentSchool?: string
	working?: SurveyResponse
	employer?: string
	employmentType?: EmploymentType
	laidOff?: SurveyResponse
	layoffDate?: Date
	layoffEmployer?: string
	higherWage?: SurveyResponse
	betterSkills?: SurveyResponse
	familyDependent?: SurveyResponse,
  covid: SurveyResponse,
  quest: SurveyResponse
}

export enum SurveyResponse {
	YES = 'YES',
	NO = 'NO',
	DND = 'DND',
	NA = 'NA',
}

export const SurveyResponseMapper = new Map<SurveyResponse, string>([
	[SurveyResponse.YES, 'Yes'],
	[SurveyResponse.NO, 'No'],
	[SurveyResponse.DND, 'Did not disclose'],
	[SurveyResponse.NA, 'Not Applicable'],
])

export enum EmploymentType {
	FULL_TIME = 'FULL_TIME',
	PART_TIME = 'PART_TIME',
}

export enum Education {
	NO_HIGHSCHOOL = 'NO_HIGHSCHOOL',
	HIGH_SCHOOL = 'HIGH_SCHOOL',
	GED = 'GED',
	SOME_COLLEGE = 'SOME_COLLEGE',
	VOCATIONAL = 'VOCATIONAL',
	ASSOCIATE = 'ASSOCIATE',
	BACHELORS = 'BACHELORS',
	MASTERS = 'MASTERS',
	PHD = 'PHD',
	NA = 'NA',
}

export const EducationMapper = new Map<Education, string>([
	[Education.NO_HIGHSCHOOL, 'Did not complete High School'],
	[Education.HIGH_SCHOOL, 'High School Diploma'],
	[Education.GED, 'GED'],
	[Education.SOME_COLLEGE, 'Some College'],
	[Education.VOCATIONAL, 'Vocational School Certificate'],
	[Education.ASSOCIATE, "Associate's Degree"],
	[Education.BACHELORS, "Bachelor's Degree"],
	[Education.MASTERS, "Master's Degree"],
	[Education.PHD, 'PhD'],
	[Education.NA, 'Did not disclose'],
])
