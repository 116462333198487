import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import AllPartnerships from '../AllPartnerships/AllPartnerships'
import Partnership from '../SinglePartnership/Partnership'
import AddendumLanding from './AddendumLanding'
import NotFound from './NotFound/NotFound'

interface PartnershipsProps extends WithStyles<typeof styles> {}

export type RouterProps = PartnershipsProps & RouteComponentProps<{}> & WithStyles<{}>

export const PartnershipsLanding: React.SFC<RouterProps> = (props: RouterProps) => {
	const { classes } = props

	return (
		<div className={classes.root} data-auto="partnerships-content-wrapper">
			<BreadcrumbsItem to={'/partnerships'}>Partnerships</BreadcrumbsItem>
			<Switch>
				<Redirect exact={true} strict={true} from="/partnerships/" to="/partnerships" />
				<Route exact={true} path="/partnerships" component={AllPartnerships} />
				<Redirect exact={true} strict={true} from="/partnerships/:partnershipID/" to="/partnerships/:partnershipID" />
				<Route exact={true} path="/partnerships/:partnershipID" component={Partnership} />
				<Redirect exact={true} strict={true} from="/partnerships/:partnershipID/addenda/:addendumID/" to="/partnerships/:partnershipID/addenda/:addendumID" />
				<Route path="/partnerships/:partnershipID/addenda/:addendumID" component={AddendumLanding} />
				<Route component={() => <NotFound item="Page" />} />
			</Switch>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing.unit * 3,
			overflowX: 'auto',
			padding: '20px',
		},
	})

export default withStyles(styles)(PartnershipsLanding)
