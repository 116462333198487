import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

interface ConfirmAlertDialogProps extends WithStyles<typeof styles> {
	open: boolean
	handleConfirm: () => void
	handleCancel: () => void
	dialogTitle: string
	dialogContent: string
}

const ConfirmAlertDialog: React.SFC<ConfirmAlertDialogProps> = (props: ConfirmAlertDialogProps) => {
	const { classes, open, dialogContent, dialogTitle, handleConfirm, handleCancel } = props

	return (
		<Dialog
			open={open}
			onClose={handleConfirm}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={classes.root}
			data-auto="confirm-dialog"
			disableBackdropClick={true}
		>
			<DialogTitle id="alert-dialog-title" data-auto="confirm-dialog-title">
				{dialogTitle}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" data-auto="confirm-dialog-content">
					{dialogContent}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary" autoFocus={true} data-auto="confirm-dialog-cancel-button">
					Cancel
				</Button>
				<Button onClick={handleConfirm} color="primary" data-auto="confirm-dialog-confirm-button">
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
	})

export default withStyles(styles)(ConfirmAlertDialog)
