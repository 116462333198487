import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service'

const { REACT_APP_HOSTNAME } = process.env

export const getActivityCodeById = async (id: string): Promise<string> => {
	if (process.env.NODE_ENV !== 'production') {
		return await '1234567' // recognizable default when testing
	} else {
		try {
			const { data, status } = await axios.get(`${REACT_APP_HOSTNAME}/activities/${id}/code` || '/')
			authorizationCheck(status)
			return data.activityCode
		} catch (err) {
			throw Error(err)
		}
	}
}

export const changeCodeById = async (id: string): Promise<string> => {
	if (process.env.NODE_ENV !== 'production') {
		// randomly generate one when testing so you can see the change each time
		return Math.round(Math.pow(36, 7 + 1) - Math.random() * Math.pow(36, 7))
			.toString(36)
			.slice(1)
			.toUpperCase()
	} else {
		try {
			const { data, status } = await axios.post(`${REACT_APP_HOSTNAME}/activities/${id}/code` || '/')
			authorizationCheck(status)
			return data.activityCode
		} catch (err) {
			throw Error(err)
		}
	}
}
