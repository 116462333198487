import moment from 'moment'

export const generateFakeTTLCookie = () => {
	const ttl = moment(new Date())
		.add(60, 'minutes')
		.unix() * 1000

	document.cookie = `at_ttl=${ttl};`
}


export const unregisterServiceWorker = (navigator: Navigator) => {
	navigator.serviceWorker.ready.then((registration: {unregister: () => void}) => {
		registration.unregister()
	})
}
