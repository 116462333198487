import * as React from 'react'

export type NotificationType = 'fail' | 'success' | 'warn'

export interface Notification {
	type: NotificationType
	message: string
}

export interface NotificationInterface {
	handleNotification: (notification: Notification) => void
}

const context = React.createContext<NotificationInterface | null>(null)

export const NotificationProvider = context.Provider

export const NotificationConsumer = context.Consumer

export const withNotificationContext = (Component: React.ComponentType<any>) => {
	return function ComponentBoundWithAppContext(props: any) {
		return (
			<NotificationConsumer>{(appContext) => <Component {...props} appContext={appContext} />}</NotificationConsumer>
		)
	}
}
