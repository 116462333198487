import {ActivityParticipantType, EnrollParticipantRequest, UpdateOutcomeRequest} from '../../services/model'
import {OutcomeStatus} from '../../services/model/Outcomes'
import {ActivityType} from "../../services/model/Participant";

import {
  formatStandardDate,
  formatUpperCase,
  trimAndSplitDisabilities,
  trimAndSplitRaces,
  trimAndSplitSupportiveServices,
  yesNoToBoolean,
  yesNoToNullableBoolean
} from '../../utils/csvUtil'
import {outcomeFormConstants} from '../../utils/outcomeFormConstants'
import {participantFormConstants} from "../../utils/participantFormConstants";
import {outcomeFormValidationConstants} from '../../utils/validation/outcomeFormValidationConstants'
import {participantFormValidationConstants} from "../../utils/validation/participantFormValidationConstants";

import {validateOpcOutcomes, validateOutcomes, validateParticipants} from '../../utils/validation/validator'

const reduceByParticipantId = (accumulator: any, currentValue: any) => {
  const activityParticipantId = currentValue[outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY];
  if (!activityParticipantId) {
    currentValue[outcomeFormConstants.ERROR_KEY] = [outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY + ' is required'];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  } else if (!outcomeFormValidationConstants.ACTIVITY_PARTICIPANT_ID_REGEX.test(activityParticipantId)) {
    currentValue[outcomeFormConstants.ERROR_KEY] = [outcomeFormValidationConstants.ACTIVITY_PARTICIPANT_ID_ERROR];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  } else if (accumulator.cleanData[activityParticipantId]) { // If duplicate
    currentValue[outcomeFormConstants.ERROR_KEY] = [outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY + ' is in use by a prior entry'];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  }

  accumulator.cleanData[activityParticipantId] = currentValue;
  return accumulator;
}

const reduceByActivityIdAndFirstLast = (accumulator: any, currentValue: any) => {
  const activityId = currentValue[participantFormConstants.ACTIVITY_ID_KEY];
  const firstName = currentValue[participantFormConstants.FIRST_NAME_KEY];
  const lastName = currentValue[participantFormConstants.LAST_NAME_KEY];
  const refId = activityId + firstName + lastName;

  if (!activityId || !firstName || !lastName) {
    currentValue[participantFormConstants.ERROR_KEY] = [participantFormConstants.ACTIVITY_ID_KEY + ', ' + participantFormConstants.FIRST_NAME_KEY + ', and ' + participantFormConstants.LAST_NAME_KEY + ' are required'];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  } else if (!participantFormValidationConstants.ACTIVITY_ID_REGEX.test(activityId)) {
    currentValue[participantFormConstants.ERROR_KEY] = [participantFormValidationConstants.ACTIVITY_ID_ERROR];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  } else if (!participantFormValidationConstants.FIRST_NAME_REGEX.test(firstName)) {
    currentValue[participantFormConstants.ERROR_KEY] = [participantFormValidationConstants.FIRST_NAME_ERROR];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  } else if (!participantFormValidationConstants.LAST_NAME_REGEX.test(lastName)) {
    currentValue[participantFormConstants.ERROR_KEY] = [participantFormValidationConstants.LAST_NAME_ERROR];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  } else if (accumulator.cleanData[activityId] && accumulator.cleanData[activityId][refId]) { // If duplicate
    currentValue[outcomeFormConstants.ERROR_KEY] = ['This line is in use by a prior entry'];
    accumulator.dirtyData.push(currentValue);
    return accumulator;
  }
  if (!accumulator.cleanData[activityId]) {
    accumulator.cleanData[activityId] = {};
  }
  accumulator.cleanData[activityId][refId] = currentValue;
  return accumulator;
}

const filterDisabled = (cleanData: any, dirtyData: any) => {
  return (activityParticipantType: ActivityParticipantType) => {

    const activityParticipantTypeData = cleanData[activityParticipantType.activityParticipantId];
    let isEnabled = true;
    const errors: string[] = [];

    if (!activityParticipantType.activityParticipantEnabled) {
      errors.push('This ActivityParticipant has been disabled');
      isEnabled = false;
    }

    if (!activityParticipantType.participantEnabled) {
      errors.push('This Participant has been disabled');
      isEnabled = false;
    }

    if(!activityParticipantType.cohortEnabled) {
      errors.push('This Cohort has been disabled');
      isEnabled = false;
    }

    if (!activityParticipantType.activityEnabled) {
      errors.push('This Activity has been disabled');
      isEnabled = false;
    }

    if(!isEnabled) {
      activityParticipantTypeData[outcomeFormConstants.ERROR_KEY] = errors;
      dirtyData.push(activityParticipantTypeData);
      return false;
    }

    return true;
  }

}

const filterActivityDisabled = (cleanData: any, dirtyData: any) => {
  return (activityType: ActivityType) => {

    let isEnabled = true;
    const errors: string[] = [];

    if(!activityType.cohortEnabled) {
      errors.push('This Cohort has been disabled');
      isEnabled = false;
    }

    if (!activityType.activityEnabled) {
      errors.push('This Activity has been disabled');
      isEnabled = false;
    }

    if(!isEnabled) {
      Object.keys(cleanData[activityType.activityId]).map(value => {
        cleanData[activityType.activityId][value][outcomeFormConstants.ERROR_KEY] = errors;
        dirtyData.push(cleanData[activityType.activityId][value])
      })
      return false;
    }
    return true;
  }

}

const outcomeValidate = (cleanData: any, dirtyData: any) => {
  return (activityParticipantType: ActivityParticipantType) => {
    const activityParticipantTypeData = cleanData[activityParticipantType.activityParticipantId];

    const validationResults = validateOutcomes(activityParticipantTypeData);

    if (!validationResults.isValid) {
      activityParticipantTypeData[outcomeFormConstants.ERROR_KEY] = validationResults.errors;
      dirtyData.push(activityParticipantTypeData);
      return false;
    }

    return true;
  }

}

const opcOutcomeValidate = (cleanData: any, dirtyData: any) => {

  return (activityParticipantType: ActivityParticipantType) => {

    const activityParticipantTypeData = cleanData[activityParticipantType.activityParticipantId];

    const validationResults = validateOpcOutcomes(
      activityParticipantTypeData
    );

    if (!validationResults.isValid) {
      activityParticipantTypeData[outcomeFormConstants.ERROR_KEY] = validationResults.errors;
      dirtyData.push(activityParticipantTypeData);
      return false;
    }

    return true;
  }
}

const participantValidate = (cleanData: any, dirtyData: any, validated: any) => {
  return (activityType: ActivityType) => {
    Object.keys(cleanData[activityType.activityId]).map(value => {
      const entry = cleanData[activityType.activityId][value];

      const validationResults = validateParticipants(entry);

      if (!validationResults.isValid) {
        cleanData[activityType.activityId][value][outcomeFormConstants.ERROR_KEY] = validationResults.errors;
        dirtyData.push(cleanData[activityType.activityId][value])
      } else {
        validated.push(cleanData[activityType.activityId][value])
      }
    })
  }
}

const buildUpdateOutcomeRequest = (cleanData: any) => {

  return (activityParticipantType: ActivityParticipantType) => {

    const activityParticipantTypeData: string = cleanData[activityParticipantType.activityParticipantId];

    const updateOutcomeRequest: UpdateOutcomeRequest = {
      status: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.STATUS_KEY]) as OutcomeStatus,
      dropReason: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.DROP_REASON_KEY]),
      startDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.ACTUAL_START_DATE_KEY]),
      endDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.ACTUAL_END_DATE_KEY]),
      trainingHours: activityParticipantTypeData[outcomeFormConstants.TRAINING_HOURS_KEY],
      placedJob: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_KEY]),
      placedTraining: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.PLACED_TRAINING_KEY]),
      placedDirect: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.PLACED_DIRECT_KEY]),
      placedJobDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_DATE_KEY]),
      incumbentWorkerEmployerPlacement: activityParticipantTypeData[outcomeFormConstants.INCUMBENT_EMPLOYER_KEY],
      jobPlacementEmployer: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_EMPLOYER_KEY],
      jobPlacementEmployerCity: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_CITY_KEY],
      jobPlacementEmployerState: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_STATE_KEY]),
      jobPlacementOccupation: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_OCCUPATION_KEY]),
      jobPlacementIndustry: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_INDUSTRY_KEY]),
      jobPlacementWage: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_WAGE_KEY],
      jobPlacementWageFrequency: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_WAGE_FREQUENCY_KEY]),
      jobPlacementWeeklyHours: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_WEEKLY_HOURS_KEY],
      benefitsProvided: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.BENEFITS_KEY]),
      perDiem: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.PER_DIEM_KEY]),
      wageIncrease: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.ACTUAL_WAGE_INCREASE_KEY]),
      promotion: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.ACTUAL_PROMOTION_KEY]),
      earnedDegreeOrCredit: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY]),
      creditsEarned: activityParticipantTypeData[outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY],
      credential1: activityParticipantTypeData[outcomeFormConstants.CREDENTIAL_1_KEY],
      credential2: activityParticipantTypeData[outcomeFormConstants.CREDENTIAL_2_KEY],
      credential3: activityParticipantTypeData[outcomeFormConstants.CREDENTIAL_3_KEY],
      credential4: activityParticipantTypeData[outcomeFormConstants.CREDENTIAL_4_KEY],
      credential5: activityParticipantTypeData[outcomeFormConstants.CREDENTIAL_5_KEY],
      wblType: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.WBL_TYPE_KEY]),
      classroomHours: activityParticipantTypeData[outcomeFormConstants.CLASSROOM_HOURS_KEY],
      unpaidHours: activityParticipantTypeData[outcomeFormConstants.UNPAID_HOURS_KEY],
      paidHours: activityParticipantTypeData[outcomeFormConstants.PAID_HOURS_KEY],
      stipendReceived: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.STIPEND_RECEIVED_KEY]),
      stipendAmount: activityParticipantTypeData[outcomeFormConstants.STIPEND_AMOUNT_KEY],
      academicCredit: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.CREDIT_TYPE_KEY]),
      academicAwardDetail: activityParticipantTypeData[outcomeFormConstants.ACADEMIC_AWARD_KEY],
      internshipOccupation: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.INTERNSHIP_OCCUPATION_KEY]),
      internshipEmployer: activityParticipantTypeData[outcomeFormConstants.INTERNSHIP_EMPLOYER_KEY],
      positionBackfilled: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.ACUTAL_POSITION_BACKFILLED_KEY]),
      jobPlacementEmployerAddress1: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_ADDRESS_1_KEY],
      jobPlacementEmployerAddress2: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_ADDRESS_2_KEY],
      jobPlacementEmployerZip: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_ZIP_KEY],
      jobTitle: activityParticipantTypeData[outcomeFormConstants.PLACED_JOB_TITLE_KEY],
      credentialType: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.CREDENTIAL_TYPE_KEY]),
      incentiveEarned: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.INCENTIVE_EARNED_KEY]),
      incentiveType: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.INCENTIVE_TYPE_KEY]),
      internshipIndustry: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.INTERNSHIP_INDUSTRY_KEY]),
      stipendFrequency: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.STIPEND_FREQUENCY_KEY]),
      wageAmount: activityParticipantTypeData[outcomeFormConstants.WAGE_AMOUNT_KEY],
      wageFrequency: formatUpperCase(activityParticipantTypeData[outcomeFormConstants.WAGE_FREQUENCY_KEY]),
      serviceProvided: yesNoToNullableBoolean(activityParticipantTypeData[outcomeFormConstants.SERVICE_PROVIDED_KEY]),
      serviceStartDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.SERVICE_START_DATE_KEY]),
      serviceEndDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.SERVICE_END_DATE_KEY]),
      supportiveServices: trimAndSplitSupportiveServices(activityParticipantTypeData[outcomeFormConstants.SERVICES_KEY])
    };

    return {
      activityParticipantId: activityParticipantType.activityParticipantId,
      updateOutcomeRequest
    };
  }
}

const buildOpcOutcomeRequest = (cleanData: any) => {

  return (activityParticipantType: ActivityParticipantType) => {

    const activityParticipantTypeData: string = cleanData[activityParticipantType.activityParticipantId];

    return {
      activityParticipantId: activityParticipantType.activityParticipantId,
      opcOutcomeRequest: {
        serviceProvided: yesNoToBoolean(activityParticipantTypeData[outcomeFormConstants.SERVICE_PROVIDED_KEY]),
        serviceStartDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.SERVICE_START_DATE_KEY]),
        serviceEndDate: formatStandardDate(activityParticipantTypeData[outcomeFormConstants.SERVICE_END_DATE_KEY]),
        supportiveServices: trimAndSplitSupportiveServices(activityParticipantTypeData[outcomeFormConstants.SERVICES_KEY])
      }
    };
  }
}

const buildEnrollParticipantRequest = (validated: any) => {

  const enrollParticipantRequest: EnrollParticipantRequest = {
    activityId: validated[participantFormConstants.ACTIVITY_ID_KEY],
    firstName: validated[participantFormConstants.FIRST_NAME_KEY],
    middleInitial: formatUpperCase(validated[participantFormConstants.MIDDLE_INITIAL_KEY]),
    lastName: validated[participantFormConstants.LAST_NAME_KEY],
    dateOfBirth: formatStandardDate(validated[participantFormConstants.DATE_OF_BIRTH_KEY]),
    homeless: yesNoToNullableBoolean(validated[participantFormConstants.HOMELESS_KEY]),
    address1: validated[participantFormConstants.ADDRESS1_KEY],
    address2: validated[participantFormConstants.ADDRESS2_KEY],
    city: validated[participantFormConstants.CITY_KEY],
    state: formatUpperCase(validated[participantFormConstants.STATE_KEY]),
    zip: validated[participantFormConstants.ZIP_KEY],
    mailingAddress1: validated[participantFormConstants.MAILING_ADDRESS1_KEY],
    mailingAddress2: validated[participantFormConstants.MAILING_ADDRESS2_KEY],
    mailingCity: validated[participantFormConstants.MAILING_CITY_KEY],
    mailingState: formatUpperCase(validated[participantFormConstants.MAILING_STATE_KEY]),
    mailingZip: validated[participantFormConstants.MAILING_ZIP_KEY],
    phoneNumber: validated[participantFormConstants.PHONE_KEY],
    email: validated[participantFormConstants.EMAIL_KEY],
    gender: validated[participantFormConstants.GENDER_KEY],
    ethnicity: validated[participantFormConstants.ETHNICITY_KEY],
    races: trimAndSplitRaces(validated[participantFormConstants.RACES_KEY]),
    disabilities: trimAndSplitDisabilities(validated[participantFormConstants.DISABILITIES_KEY]),
    preferredLanguage: formatUpperCase(validated[participantFormConstants.PREFERRED_LANGUAGE_KEY]),
    citizen: validated[participantFormConstants.CITIZEN_KEY],
    veteran: validated[participantFormConstants.VETERAN_KEY],
    disabilityStatus: validated[participantFormConstants.DISABILITY_STATUS_KEY],
    ell: validated[participantFormConstants.ELL_KEY],
    education: formatUpperCase(validated[participantFormConstants.EDUCATION_KEY]),
    highestGrade: validated[participantFormConstants.HIGHEST_GRADE_KEY],
    inSchool: validated[participantFormConstants.IN_SCHOOL_KEY],
    currentSchool: validated[participantFormConstants.CURRENT_SCHOOL_KEY],
    working: validated[participantFormConstants.WORKING_KEY],
    employer: validated[participantFormConstants.EMPLOYER_KEY],
    employmentType: formatUpperCase(validated[participantFormConstants.EMPLOYMENT_TYPE_KEY]),
    laidOff: validated[participantFormConstants.LAID_OFF_KEY],
    layoffDate: formatStandardDate(validated[participantFormConstants.LAYOFF_DATE_KEY]),
    layoffEmployer: validated[participantFormConstants.LAYOFF_EMPLOYER_KEY],
    higherWages: validated[participantFormConstants.HIGHER_WAGES_KEY],
    skills: validated[participantFormConstants.SKILLS_KEY],
    familyDependent: validated[participantFormConstants.FAMILY_DEPENDENT_KEY],
    covid: validated[participantFormConstants.COVID_KEY],
    quest: validated[participantFormConstants.QUEST_KEY],
    acceptedCovid: null
    };

  return enrollParticipantRequest;
}

const sortNotFound = (cleanData: any, dirtyData: any) => {
  return ( notFoundId: any ) => {
    cleanData[notFoundId][outcomeFormConstants.ERROR_KEY] = [notFoundId + ' was not found in the database.'];
    dirtyData.push(cleanData[notFoundId]);
  }
}

const sortActivityNotFound = (cleanData: any, dirtyData: any) => {
  return ( notFoundId: any ) => {
    Object.keys(cleanData[notFoundId]).map(value => {
      cleanData[notFoundId][value][outcomeFormConstants.ERROR_KEY] = ['Activity ID ' + notFoundId + ' was not found in the database.'];
      dirtyData.push(cleanData[notFoundId][value]);
    });
  }
}

const sanitizeOutcomes = (cleanData:any) =>{
  cleanData.forEach((data:any)=>{
    const updateOutcomeRequest = data.updateOutcomeRequest
    if(![OutcomeStatus.COMPLETED,OutcomeStatus.DROPPED_OUT].includes(updateOutcomeRequest.status)){
      updateOutcomeRequest.endDate = null
    }
    if(!updateOutcomeRequest.earnedDegreeOrCredit){
      updateOutcomeRequest.creditsEarned = null
      updateOutcomeRequest.academicCredit = null
    }
    if(['unknown','no recognized credential','none'].includes(updateOutcomeRequest.credentialType?.toLowerCase())){
      updateOutcomeRequest.credential1 = null
      updateOutcomeRequest.credential2 = null
      updateOutcomeRequest.credential3 = null
      updateOutcomeRequest.credential4 = null
      updateOutcomeRequest.credential5 = null
    }
    if(!updateOutcomeRequest.stipendReceived){
      updateOutcomeRequest.stipendFrequency = null
      updateOutcomeRequest.stipendAmount = null
    }
    if(updateOutcomeRequest.incentiveEarned?.toUpperCase() !== 'INCENTIVE'){
      updateOutcomeRequest.incentiveType = null
    }
    if(updateOutcomeRequest.incentiveEarned?.toUpperCase() !== 'WAGE'){
      updateOutcomeRequest.wageAmount = null
      updateOutcomeRequest.wageFrequency = null
    }
    if(!updateOutcomeRequest.serviceProvided){
      updateOutcomeRequest.serviceStartDate = null
      updateOutcomeRequest.serviceEndDate = null
      updateOutcomeRequest.supportiveServices = []
    }
  })
}

export default {
  buildOpcOutcomeRequest,
  buildUpdateOutcomeRequest,
  buildEnrollParticipantRequest,
  filterDisabled,
  filterActivityDisabled,
  outcomeValidate,
  opcOutcomeValidate,
  participantValidate,
  reduceByActivityIdAndFirstLast,
  reduceByParticipantId,
  sortNotFound,
  sortActivityNotFound,
  sanitizeOutcomes
}
