import moment from 'moment'
import {CheckboxItem, Education, Ethnicity, Gender, Race, State, SurveyResponse,} from '../../services/model'

export const RACE_CHECKBOX_ITEMS: CheckboxItem[] = [
	{ value: Race.WHITE, label: 'White', checked: false },
	{ value: Race.BLACK, label: 'Black', checked: false },
	{ value: Race.ASIAN, label: 'Asian', checked: false },
	{ value: Race.ISLANDER, label: 'Hawaiian or Pacific Islander', checked: false },
	{ value: Race.INDIGENOUS, label: 'Native American or Alaska Native', checked: false },
	{ value: Race.NA, label: 'I do not wish to disclose', checked: false },
]

export const MIN_BIRTH_DATE = moment(new Date())
	.subtract(80, 'years')
	.format('YYYY-MM-DD')
export const MAX_BIRTH_DATE = moment(new Date())
	.subtract(14, 'years')
	.format('YYYY-MM-DD')

export const BLANK_ADDRESS = {
	address1: '',
	address2: '',
	city: '',
	state: State.RI,
	zip: '',
}

export const BLANK_MAILING_ADDRESS = {
	mailingAddress1: '',
	mailingAddress2: '',
	mailingCity: '',
	mailingState: State.RI,
	mailingZip: '',
}

export const GENDER_RADIO_OPTIONS = [
	{ value: Gender.MALE, label: 'Male' },
	{ value: Gender.FEMALE, label: 'Female' },
	{ value: Gender.TRANS_MALE, label: 'Transgender Male' },
	{ value: Gender.TRANS_FEMALE, label: 'Transgender Female' },
	{ value: Gender.NON_BINARY, label: 'Non-conforming/Non-binary' },
	{ value: Gender.OTHER, label: 'Other' },
	{ value: Gender.DND, label: 'I do not wish to disclose' },
	{ value: Gender.NA, label: 'Not Applicable' },
]

export const ETHNICITY_RADIO_OPTIONS = [
	{ value: Ethnicity.LATINO, label: 'Hispanic / Latino' },
	{ value: Ethnicity.NOT_LATINO, label: 'Not Hispanic or Latino' },
	{ value: Ethnicity.NA, label: 'I do not wish to disclose' },
]

export const SURVEY_RADIO_OPTIONS = [
	{ value: SurveyResponse.YES, label: 'Yes' },
	{ value: SurveyResponse.NO, label: 'No' },
	{ value: SurveyResponse.DND, label: 'I do not wish to disclose' },
]

export const EDUCATION_RADIO_OPTIONS = [
	{ value: Education[Education.NO_HIGHSCHOOL], label: 'Did not complete High School' },
	{ value: Education[Education.HIGH_SCHOOL], label: 'High School Diploma' },
	{ value: Education[Education.GED], label: 'General Equivalency Degree (GED)' },
	{
		value: Education[Education.SOME_COLLEGE],
		label: 'Attended some College or Vocational School (non-degree holder)',
	},
	{ value: Education[Education.VOCATIONAL], label: 'Vocational School Certificate' },
	{ value: Education[Education.ASSOCIATE], label: 'Associate Degree' },
	{ value: Education[Education.BACHELORS], label: 'Bachelor’s Degree' },
	{ value: Education[Education.MASTERS], label: 'Master’s Degree ' },
	{ value: Education[Education.PHD], label: 'PhD' },
]

export const QUEST_RADIO_OPTIONS = [
	{ value: SurveyResponse.YES, label: 'Yes' },
	{ value: SurveyResponse.NO, label: 'No' }
]
