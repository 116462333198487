import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service'
import { ActivityParticipantType, OpcOutcomeRequest } from './model'
import { BulkActivityParticipantTypes } from './model/Participant'
import { GetTypesRequest } from './model/ParticipantTypesRequest'
import { UpdateOutcomeRequest } from './model/UpdateOutcomeRequest'

const { REACT_APP_HOSTNAME } = process.env

export async function createOpcOutcome(
	activityParticipant: number,
	opcOutcomeRequest: OpcOutcomeRequest
): Promise<boolean> {
	let success = false
	try {
		const { status } = await axios.post(`${REACT_APP_HOSTNAME}/activityparticipants/${activityParticipant}/opcoutcome`, opcOutcomeRequest)
		authorizationCheck(status)
		success = status === 201
	} catch (e) {
		console.log(e.message)
	}
	return success
}

export async function getActivityParticipantType(
	activityParticipant: string
): Promise<ActivityParticipantType> {
	try {
		const { data } = await axios.get(`${REACT_APP_HOSTNAME}/activityparticipants/${activityParticipant}/type`)
		return data
	} catch (e) {
		authorizationCheck(e.response.status)
    throw e
	}
}

export async function getBulkActivityParticipantType(
	getTypesRequest: GetTypesRequest
): Promise<BulkActivityParticipantTypes> {
	try {
		const { data, status } = await axios.post(`${REACT_APP_HOSTNAME}/activityparticipants/types`, getTypesRequest)
		authorizationCheck(status);
		return data
	} catch (e) {
    throw e
	}
}

export async function updateActivityParticipantType(
	activityParticipant: number,
	updateOutcomeRequest: UpdateOutcomeRequest
): Promise<boolean> {
	let success = false
	try {
		const { status } = await axios.put(`${REACT_APP_HOSTNAME}/activityparticipants/${activityParticipant}/outcome`, updateOutcomeRequest)
		authorizationCheck(status)
		success = status === 204
	} catch (e) {
		console.log(e.message)
	}
	return success;
}