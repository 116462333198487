import axios from 'axios'
import { parse } from 'cookie';
import moment from 'moment'

const { REACT_APP_LOGIN_URL, REACT_APP_REFRESH_URL } = process.env

export const getAuthCookies = () => {
	return {at_ttl: parse(document.cookie).at_ttl}
}

export const forceLogOutRedirect = () => {
	window.location.assign(REACT_APP_LOGIN_URL || '')
}

export const authorizationCheck = (status: number) => {
	if(status === 401) return forceLogOutRedirect()
}

export const refreshTTLCookie = async () => {
	try {
		const { data } = await axios.get(`/`, {
			baseURL: REACT_APP_REFRESH_URL,
		})

		return data
	}catch (e) {
		return e.response.status !== 200 ? forceLogOutRedirect() : e
	}
}


export const timeTillRedirect = (bufferInMilliseconds: number = 120000) => {
	const {at_ttl} = parse(document.cookie)
	const now = moment(new Date()).valueOf()
	const ttlCookie = moment(+at_ttl).valueOf()
	const currentDiff = ttlCookie - now
	const timeout = currentDiff - bufferInMilliseconds

	return timeout;
}