import { AppBar, createStyles, Hidden, Theme, Toolbar, WithStyles, withStyles } from '@material-ui/core/'
import React from 'react'
import { NavLink } from 'react-router-dom'

import Logo from '../../../Assets/Logo.png'
import Quicksight from '../../../components/Quicksight/Quicksight'
import MobileNavBar from '../../MobileNavBar/MobileNavBar'
import NavBar, { NavigationLink } from '../../NavBar/NavBar'

export interface HeaderProps extends WithStyles<typeof styles> {
	navLinks: NavigationLink[]
}

export const Header: React.SFC<HeaderProps> = (props: HeaderProps) => {
	const { classes, navLinks } = props

	return (
		<AppBar color="default" className={classes.root} data-auto="main-header" aria-label="RI Department of labor and training" position="sticky">
			<Toolbar variant="dense" className={classes.toolbar}>
				<nav className={classes.navigation} role="navigation">
					<NavLink to="/" className={classes.branding}>
						<div className={classes.logoContainer}>
							<img className={classes.logo} src={Logo} alt="dms logo" />
						</div>
						{/* <Hidden smDown={true}>
							<div className={classes.titleContainer}>
								<Typography className={`${classes.title} ${classes.titleHead}`} component="h1" variant="h6">
									Governor's Workforce Board
								</Typography>
								<Typography className={`${classes.title}  ${classes.titleMid}`}>RHODE ISLAND</Typography>
								<Typography className={`${classes.title}  ${classes.titleBottom}`}>train for success &#8226; connect for growth</Typography>
							</div>
						</Hidden> */}
					</NavLink>

					<div className={classes.navSide}>
						<NavBar navLinks={navLinks} />
						<MobileNavBar navLinks={navLinks} />
						<Hidden smDown={true}>
							<Quicksight />
						</Hidden>
					</div>
				</nav>
			</Toolbar>
		</AppBar>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		toolbar: {
      minHeight:"66px",
			margin: '0 auto',
			maxHeight: '66px',
			[theme.breakpoints.up('lg')]: {
				width: '83%',
			},
			[theme.breakpoints.down('md')]: {
				width: '95%',
			},
		},
		navigation: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
		},
		branding: {
			display: 'flex',
			alignItems: 'center',
			height: '50px',
			textDecoration: 'none',

			[theme.breakpoints.up('sm')]: {
				flexBasis: 'auto',
			},
		},
		navSide: {
			display: 'flex',
			alignItems: 'center',
		},
		titleContainer: {
			maxHeight: '50px',
			overflow: 'hidden',
		},
		title: {
			fontSize: `${theme.typography.fontSize * 0.75}px`,
			lineHeight: 1.25,
			color: 'primary',
		},
		titleHead: {
			fontSize: `${theme.typography.fontSize}px`,
			letterSpacing: '2px',
			fontWeight: 'bolder',
			color: 'primary',
		},
		titleMid: {
			fontStyle: 'oblique',
			textTransform: 'uppercase',
			color: 'primary',
		},
		titleBottom: {
			fontWeight: 500,
		},
		logoContainer: {
			height: '60px',
		},
		logo: {
			height: '100%',
			width: 'auto',
			transform: 'scale(1.25, 1.25)',
		},
	})

export default withStyles(styles)(Header)
