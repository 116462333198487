import { createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import TypographyHeader from '../../../templates/TypographyHeader/TypographyHeader'

interface TopLevelTargetProps extends WithStyles<typeof styles> {
	target: string | number
	targetHeader: string
	actual: string | number
}

export const TopLevelTarget: React.SFC<TopLevelTargetProps> = (props: TopLevelTargetProps) => {
	const { actual, classes, target, targetHeader } = props

	return (
		<div className={classes.outcomesItem}>
			<TypographyHeader inline={true} type="subtitle" label={`${targetHeader}`} />
			<div className={classes.outcomesTargetActual}>
				<Typography variant="body1" className={classes.header}>
					Target&#58;
					<span className={classes.value}>{target}</span>
				</Typography>
				<Typography variant="body1" className={classes.header}>
					Actual&#58;
					<span className={classes.value}>{actual}</span>
				</Typography>
			</div>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		header: {
			fontWeight: 600,
			textTransform: 'uppercase',
		},
		value: {
			marginLeft: '.5em',
			fontWeight: 400,
			textTransform: 'capitalize',
		},

		outcomesItem: {
			display: 'block',
			padding: `10px`,
		},

		outcomesTargetActual: {
			marginLeft: `5px`,
		},
	})

export default withStyles(styles)(TopLevelTarget)
