import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ActivityDetails from '../ActivitiyDetails/ActivityDetails'

interface AddendaActivitiesProps extends WithStyles<typeof styles> {
	addendumID: string
	partnershipID: string
}

export type RouterProps = AddendaActivitiesProps &
	RouteComponentProps<{
		activityID: string
	}>

export const AddendaActivities: React.SFC<RouterProps> = (props: RouterProps) => {
	const { classes, match, addendumID, partnershipID } = props
	const { activityID } = match.params

	return (
		<div className={classes.root}>
			<ActivityDetails activityID={activityID} addendumID={addendumID} partnershipID={partnershipID} />
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			overflowX: 'auto',
		},
	})

export default withStyles(styles)(AddendaActivities)
