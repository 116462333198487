import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import React from 'react'
import { Participant, TableItem } from '../../../services/model'
import { Sort, sortArrayItems } from '../../../utils/sortingService'
import TableWrapper from '../TableWrapper'

export interface CustomParticipant extends Participant {
	name: string
}

interface ParticipantsTableProps extends WithStyles<typeof styles> {
	participants: CustomParticipant[]
}

interface ParticipantsTableState {
	order: Sort
	orderBy: string
	page: number
	rowsPerPage: number
	sortedParticipants: CustomParticipant[]
}

const defaultContentFormatter = (content: any[]): TableItem[] => {
	const formattedContent = content.map((i) => {
		const name = `${i.lastName}, ${i.firstName}`

		return {
			title: {
				label: name,
				url: `/participants/${i.id}`,
			},
			content: [{ isDate: true, value: i.dateOfBirth }, { isDate: false, value: i.address1 }],
		}
	})

	return formattedContent
}

const PARTICIPANTS_TABLE_HEADERS = [
	{
		label: 'Name',
		prop: 'name',
	},
	{
		label: 'Date of Birth',
		prop: 'dateOfBirth',
	},
	{
		label: 'Address',
		prop: 'address1',
	},
]

const setInitialState = (props: ParticipantsTableProps) => {
	return {
		order: 'asc' as Sort,
		orderBy: 'name',
		page: 0,
		rowsPerPage: 10,
		sortedParticipants: sortArrayItems(props.participants, 'asc', 'name', 0, props.participants.length),
	}
}

export class ParticipantsTable extends React.Component<ParticipantsTableProps, ParticipantsTableState> {
	public readonly state: ParticipantsTableState = setInitialState(this.props)

	handleRequestSort = (currentOrder: string, sortLabel: string) => {
		const { sortedParticipants, page } = this.state
		const order: Sort = currentOrder === 'asc' ? 'desc' : 'asc'
		const orderBy: string = sortLabel

		const participants = sortArrayItems(sortedParticipants, order, orderBy, page, sortedParticipants.length)

		this.updateSorting(participants, order, orderBy)
	}

	updateSorting = (participants: CustomParticipant[], order: Sort, sortLabel: string) => {
		this.setState({
			order,
			orderBy: sortLabel,
			sortedParticipants: participants,
		})
	}

	componentWillReceiveProps(nextProps: ParticipantsTableProps) {
		if (this.props.participants !== nextProps.participants) {
			this.setState({ sortedParticipants: nextProps.participants })
		}
	}

	render() {
		const { classes } = this.props
		const { order, orderBy, sortedParticipants } = this.state
		const formattedParticipants = defaultContentFormatter(sortedParticipants)

		return (
			<div className={classes.root} data-auto="participants-table">
				<TableWrapper
					headers={PARTICIPANTS_TABLE_HEADERS}
					rowItems={formattedParticipants}
					loading={!sortedParticipants.length}
					sortDirection={order}
					orderBy={orderBy}
					onSortClick={this.handleRequestSort}
					paginationFooter={formattedParticipants.length > 10}
					itemsLabel="participants"
				/>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing.unit * 3,
			overflowX: 'auto',
			padding: '10px',
		},
	})

export default withStyles(styles)(ParticipantsTable)
