import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service';
import {GetActivityTypesRequest} from "./model/ActivityTypesRequest";
import {ActivityType, BulkActivityTypes} from './model/Participant';

const { REACT_APP_HOSTNAME } = process.env

export async function getActivityType(
	activityId: string
): Promise<ActivityType> {
	try {
		const { data, status } = await axios.get(`${REACT_APP_HOSTNAME}/activities/${activityId}/type`)
		authorizationCheck(status)
		return data
	} catch (e) {
    	throw e
	}
}

export async function getBulkActivityType(
	getActivityTypesRequest: GetActivityTypesRequest
): Promise<BulkActivityTypes> {
	try {
		const { data, status } = await axios.post(`${REACT_APP_HOSTNAME}/activities/types`, getActivityTypesRequest)
		authorizationCheck(status);
		return data
	} catch (e) {
    	throw e
	}
}
