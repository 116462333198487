import { createStyles, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Theme, WithStyles, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

interface RadioItemProps extends WithStyles<typeof styles> {
	value: string
	onRadioChange: (event: React.ChangeEvent<{}>) => void
	radioOptions: RadioOption[]
	propName: string
	title: string
	column?: boolean
}

interface RadioOption {
	value: string
	label: string
}

const CustomRadioItem: React.SFC<RadioItemProps> = (props: RadioItemProps) => {
	const { classes, value, onRadioChange, radioOptions, title, propName } = props

	return (
		<div className={classes.radioItem}>
			<FormControl className={classNames(classes.formControl, props.column ? classes.column : '')}>
				<FormLabel>{title}</FormLabel>
				<RadioGroup
					aria-label={propName}
					name={propName}
					className={classNames(classes.radioGroup, props.column && classes.column)}
					value={value}
					onChange={onRadioChange}
					id={propName}
				>
					{radioOptions.map((option: RadioOption, i: number) => (
						<FormControlLabel key={i} value={option.value} control={<Radio checked={value === option.value} />} label={option.label} />
					))}
				</RadioGroup>
			</FormControl>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		radioItem: {
			flex: 1,
		},
		formControl: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
		radioGroup: {
			flexDirection: 'row',
			paddingLeft: `${theme.spacing.unit * 3}px`,
		},
		column: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	})

export default withStyles(styles)(CustomRadioItem)
