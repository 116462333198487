import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import * as React from 'react'

interface NotFoundProps extends WithStyles<typeof styles> {
	item: string
}

const NotFound: React.SFC<NotFoundProps> = (props: NotFoundProps) => {
	const { classes, item } = props

	return (
		<Typography variant="h5" component="h1" align="center" style={{ marginTop: '2rem' }} data-auto="not-found">
			<WarningIcon
				className={classes.icon}
				style={{ color: 'orange', verticalAlign: 'middle', marginRight: '0.5rem' }}
			/>
			No {item} found
		</Typography>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		icon: {
			fontSize: theme.typography.fontSize * 1.5,
			padding: `0 ${theme.spacing.unit * 2}`,
		},
		branding: {},
	})

export default withStyles(styles)(NotFound)
