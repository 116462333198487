import { Button, createStyles, Divider, Hidden, Theme, withStyles, WithStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import * as R from 'ramda'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { getActivityByID, getActivityParticipantById, updateActivityParticipant } from '../../../services/addenda-activity.service'
import { Notification, NotificationInterface, withNotificationContext } from '../../../services/ContextService/NotificationService/NotificationContextService'
import {Activity, Addendum, FullParticipantData, Participant, SurveyData} from '../../../services/model'
import { getParticipantByID, updateParticipant } from '../../../services/participants.service'
import { getAddendum } from '../../../services/partnership-addenda.service'
import ActivityParticipantInformation from '../../ActivityParticipant/ActivityParticipant'
import EditParticipantFormWrapper from '../EditParticipantFormWrapper/EditParticipantFormWrapper'

interface ActivityParticipantWrapperProps extends WithStyles<typeof styles> {
	addendumID: string
	partnershipID: string
	appContext: NotificationInterface
}
export type RouterProps = ActivityParticipantWrapperProps &
	RouteComponentProps<{
		activityID: string
		participantID: string
	}>

interface ActivityParticipantWrapperState {
	participant?: FullParticipantData
	activity?: Activity
	addendum?: Addendum
	editingParticipant: boolean
}

interface UpdateNotification {
	success: Notification
	fail: Notification
}

const NOTIFICATION_ERRORS: UpdateNotification = {
	success: { type: 'success', message: 'Participant updated successfully.' },
	fail: { type: 'fail', message: 'Something went wrong, please try again.' },
}

export class ActivityParticipantWrapper extends React.Component<RouterProps, ActivityParticipantWrapperState> {
	public readonly state: ActivityParticipantWrapperState = {
		editingParticipant: false,
	}

	toggleEditingValue = () => {
		this.setState((prevState: ActivityParticipantWrapperState) => ({
			editingParticipant: !prevState.editingParticipant,
		}))
	}

	getFullParticipantData = async (): Promise<FullParticipantData> => {
		const { addendumID, partnershipID, match } = this.props
		const { activityID, participantID } = match.params

		const activityParticipant: SurveyData = await getActivityParticipantById(partnershipID, addendumID, activityID, participantID)
		const participant: Participant = await getParticipantByID(participantID)

		return { ...activityParticipant, ...participant }
	}

	getBreadcrumbDataItems = async (): Promise<{ activity: Activity; addendum: Addendum }> => {
		const { addendumID, partnershipID, match } = this.props
		const { activityID } = match.params

		try {
			const addendum = await getAddendum(partnershipID, addendumID)
			const activity = await getActivityByID(partnershipID, addendumID, activityID)

			return { activity, addendum }
		} catch (e) {
			console.log('e: ', e)
			return e
		}
	}

	handleParticipantUpdate = async (updatedParticipant: FullParticipantData) => {
		await this.updateParticipantInfo(updatedParticipant).catch((e) => console.log(e.message))
	}

	handleEditParticipantCancel = () => {
		this.setState((prevState: ActivityParticipantWrapperState) => ({ editingParticipant: !prevState.editingParticipant }))
	}

	updateParticipantInfo = async (participantInfo: FullParticipantData) => {
		const { addendumID, partnershipID, match } = this.props
		const { activityID, participantID } = match.params

		try {
			const updatedParticipant = await updateParticipant(participantInfo)
			const updatedActivityParticipant = await updateActivityParticipant(partnershipID, addendumID, activityID, participantID, participantInfo)

			this.setState((prevState: ActivityParticipantWrapperState) => ({
				participant: R.merge(updatedParticipant, updatedActivityParticipant),
				editingParticipant: false,
			}))
			this.notify(NOTIFICATION_ERRORS.success)
		} catch (e) {
			this.notify(NOTIFICATION_ERRORS.fail)
		}
	}

	notify = (notification: Notification) => {
		setTimeout(() => {
			this.props.appContext.handleNotification(notification)
		}, 200)
	}

	async componentDidMount() {
		const breadcrumbsItems = await this.getBreadcrumbDataItems().catch(console.log)
		const participantData = await this.getFullParticipantData().catch(console.log)

		this.setState((prevState: ActivityParticipantWrapperState) => ({
			...prevState,
			participant: participantData || prevState.participant,
			activity: breadcrumbsItems ? breadcrumbsItems.activity : prevState.activity,
			addendum: breadcrumbsItems ? breadcrumbsItems.addendum : prevState.addendum,
		}))
	}

	render() {
		const { classes, addendumID, partnershipID, match } = this.props
		const { activityID } = match.params
		const { participant, activity, addendum, editingParticipant } = this.state
		const addendumURL = `/partnerships/${partnershipID}/addenda/${addendumID}`

		return (
			<div className={classes.root}>
				{addendum && <BreadcrumbsItem to={addendumURL}>{addendum.name}</BreadcrumbsItem>}
				{participant && (
					<section className={classes.activityParticipant}>
						{activity && <BreadcrumbsItem to={`${addendumURL}/activities/${activityID}`}>{activity.name}</BreadcrumbsItem>}
						{editingParticipant ? (
							<EditParticipantFormWrapper
								participant={participant}
								onCancel={this.handleEditParticipantCancel}
								handleParticipantUpdate={this.handleParticipantUpdate}
							/>
						) : (
							<ActivityParticipantInformation participant={participant} />
						)}
					</section>
				)}

				{!editingParticipant && (
					<Hidden smDown={true}>
						<Divider className={classes.divider} />
						<div className={classes.actionItems}>
							<Button variant="contained" onClick={this.toggleEditingValue} data-auto="edit-participant-btn">
								<EditIcon className={classes.icon} />
								Edit Participant
							</Button>
						</div>
					</Hidden>
				)}
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		activityParticipant: {},
		actionItems: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		icon: {
			paddingRight: `${theme.spacing.unit}px`,
		},
		divider: {
			marginBottom: `${theme.spacing.unit * 2}px`,
		},
	})

const styledActivityParticipantWrapper = withStyles(styles)(ActivityParticipantWrapper)

export default withNotificationContext(styledActivityParticipantWrapper)
