import { createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core'
import * as React from 'react'

interface InfoItemProps extends WithStyles<typeof styles> {
	label: string
	value: string
	dualCol?: boolean
}

export const InfoItem: React.SFC<InfoItemProps> = (props: InfoItemProps) => {
	const { classes, dualCol, label, value } = props

	return (
		<div className={dualCol ? classes.double : classes.single}>
			<Typography variant="h6" data-auto={`${label.replace(/\s/g, '')}-label`} component="h3" style={{ display: 'inline-block', paddingRight: '15px' }}>
				{label}
				<span>&#58;</span>
			</Typography>
			<Typography variant="body1" data-auto={`${label.replace(/\s/g, '')}-value`} style={{ display: 'inline-block' }}>
				{value}
			</Typography>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		single: {
			flexBasis: '98%',
		},
		double: {
			flexBasis: '48%',
			[theme.breakpoints.down('sm')]: {
				flexBasis: '98%',
			},
		},
	})

export default withStyles(styles)(InfoItem)
