import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { getAllActivities } from '../../services/addenda-activity.service'
import { Activity, Addendum } from '../../services/model/'
import { getAddendum } from '../../services/partnership-addenda.service'
import { getSorting, Sort, stableSort } from '../../utils/sortingService'
import { HeaderItem } from '../AllPartnerships/AllPartnerships'
import TableWrapper from '../Tables/TableWrapper'
import TypographyHeader from '../templates/TypographyHeader/TypographyHeader'

interface AddendumProps extends WithStyles<typeof styles> {
	partnershipID: string
	addendumID: string
	linkURL: string
}

interface AddendumState {
	loading: boolean
	addendum?: Addendum
	activities: Activity[]
	orderBy: string
	order: Sort
}

const TABLE_HEADERS: HeaderItem[] = [{ label: 'Name', prop: 'name' }, { label: 'Start Date', prop: 'startDate' }, { label: 'End Date', prop: 'endDate' }]

const INITIAL_STATE: AddendumState = {
	addendum: undefined,
	activities: [],
	loading: true,
	orderBy: 'startDate',
	order: 'asc',
}

export class AddendumComponent extends React.Component<AddendumProps, AddendumState> {
	public readonly state: AddendumState = INITIAL_STATE

	async componentDidMount() {
		try {
			const { addendum, activities } = await this.fetchInitialData()

			if (addendum && activities) {
				this.setState({
					addendum,
					activities,
					loading: false,
				})
			}
		} catch (e) {
			this.setState({
				loading: false,
			})
		}
	}

	fetchInitialData = async () => {
		const { partnershipID, addendumID } = this.props

		const addendumPromise = getAddendum(partnershipID, addendumID)
		const activitiesPromise = getAllActivities(partnershipID, addendumID)

		const [addendum, activities] = await Promise.all([addendumPromise, activitiesPromise])

		return {
			addendum,
			activities,
		}
	}

	handleSort = (currentOrder: string, sortLabel: string) => {
		this.setState({
			order: currentOrder === 'asc' ? 'desc' : 'asc',
			orderBy: sortLabel,
		})
	}

	normalizeActivities = (activities: Activity[]) => {
		const { linkURL } = this.props

		return activities.map((activity: Activity) => {
			return {
				title: {
					label: activity.name,
					url: `${linkURL}/${activity.id}`,
				},
				content: [{ isDate: true, value: activity.startDate }, { isDate: true, value: activity.endDate }],
			}
		})
	}

	render() {
		const { classes, partnershipID, addendumID } = this.props
		const { addendum, loading, activities, order, orderBy } = this.state

		const sortedActivities = this.normalizeActivities(stableSort(activities, getSorting(order, orderBy)))

		return (
			<div className={classes.root}>
				{!!addendum && (
					<React.Fragment>
						<BreadcrumbsItem to={`/partnerships/${partnershipID}/addenda/${addendumID}`}>{addendum.name}</BreadcrumbsItem>
						<TypographyHeader label={addendum.name} type="title" />
					</React.Fragment>
				)}

				<div className={classes.addendumContent}>
					<TypographyHeader label="Activities:" type="subtitle" />
					<TableWrapper
						headers={TABLE_HEADERS}
						rowItems={sortedActivities}
						loading={loading}
						sortDirection={order}
						orderBy={orderBy}
						onSortClick={this.handleSort}
						itemsLabel="activities"
						paginationFooter={sortedActivities.length > 10}
					/>
				</div>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		addendumContent: {},
	})

export default withStyles(styles)(AddendumComponent)
