import * as React from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'flex-end',
			margin: `${theme.spacing.unit}px`,
			marginRight: `${theme.spacing.unit * 2}px`,
			padding: 0,
			cursor: 'pointer',
		},
	})

interface QuicksightProps extends WithStyles<typeof styles> {}

const Quicksight: React.SFC<QuicksightProps> = (props: QuicksightProps) => {
	return (
		<a
			title="Go to QuickSight"
			href="https://quicksight.aws.amazon.com/sn/start"
			rel="external"
			target="_blank"
			className={props.classes.root}
		>
			<svg width="40" height="40">
				<path
					d="m37 9-17 17v-14l-17 17v-26h34m-35.254-3a1.758 1.758 0 0 0 -1.746 1.743v36.51c.004.96.787 1.742 1.743 1.747h36.51a1.759 1.759 0 0 0 1.747-1.742v-36.512a1.759 1.759 0 0 0 -1.743-1.746z" // tslint:disable-line
					fill="#00b7f4"
					fillRule="evenodd"
				/>
			</svg>
		</a>
	)
}

export default withStyles(styles)(Quicksight)
