import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'

import Logo from './../../Assets/RealLogo.png'

interface RealLogosProps extends WithStyles<typeof styles> {}

export const RealLogos: React.SFC<RealLogosProps> = (props: RealLogosProps) => {
	const { classes } = props

	return (
		<div className={classes.logoContainer}>
			<img className={classes.logo} src={Logo} alt="Branding, logo" />
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		logoContainer: {
			display: 'flex',
			height: '55px',
		},
		logo: {
			height: '100%',
			width: 'auto',
			transform: 'scale(0.7, 0.7)',
		},
	})

export default withStyles(styles)(RealLogos)
