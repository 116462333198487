import moment from 'moment'
import React from 'react'

import { Grid, Hidden, Typography } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'

import { Activity, ParticipantTypeMapper, ServiceTypeMapper } from '../../services/model'
import EnrollmentStatusToggle from '../EnrollmentStatusToggle/EnrollmentStatusToggle'
import TypographyHeader from '../templates/TypographyHeader/TypographyHeader'

interface ActivityHeaderProps extends WithStyles<typeof styles> {
	activity: Activity
	addendumId: string
	partnershipId: string
}

export const ActivityDetailsHeader: React.SFC<ActivityHeaderProps> = (props: ActivityHeaderProps) => {
	const { classes, activity, addendumId, partnershipId } = props
	const startDate = activity.startDate ? moment(activity.startDate).format('MM/DD/YYYY') : undefined
	const endDate = activity.endDate ? moment(activity.endDate).format('MM/DD/YYYY') : undefined

	return (
		<section className={classes.root} data-auto="activity-header">
			<Grid container={true} justify="space-between" spacing={40}>
				<Grid item={true} xs={12} md={8}>
					<div>
						<TypographyHeader label={activity.name} type="title" />
						<TypographyHeader label={'DMS Cohort ID: ' + activity.id} type="subtitle" />
						<TypographyHeader label={'Service Type: '} type="subtitle" inline={true} />
						<TypographyHeader
							label={ServiceTypeMapper.get(activity.serviceType) || ''}
							type="subtitle"
							inline={true}
							extraProps={{
								style: {
									paddingLeft: '8px',
									textShadow: 'none',
								},
							}}
						/>
					</div>

					<div>
						<TypographyHeader label={'Participant Type: '} type="subtitle" inline={true} />
						<TypographyHeader
							label={ParticipantTypeMapper.get(activity.participantType) || ''}
							type="subtitle"
							inline={true}
							extraProps={{
								style: {
									paddingLeft: '8px',
									textShadow: 'none',
								},
							}}
						/>
					</div>
				</Grid>
				<Grid item={true} xs={12} md={4}>
					{!!startDate && !!endDate && (
						<div className={classes.dateItems}>
							<Typography variant="h5" component="h1" align="right" style={{ display: 'inline-block', padding: '16px 0' }}>
								<time dateTime={startDate}>{startDate}</time>
								<span style={{ padding: '0 5px' }}>{'\u002D'}</span>
							</Typography>
							<Typography variant="h5" component="h1" align="right" style={{ display: 'inline-block' }}>
								<time dateTime={endDate}>{endDate}</time>
							</Typography>
						</div>
					)}
					<Hidden smDown={true}>
						<EnrollmentStatusToggle activityID={activity.id} addendumID={addendumId} partnershipID={partnershipId} />
					</Hidden>

				</Grid>
			</Grid>
		</section>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		dateItems: {
			justifyContent: 'flex-end',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'flex-start',
				marginTop: `${theme.spacing.unit * -3}px`,
			},
		},
	})

export default withStyles(styles)(ActivityDetailsHeader)
