import moment from "moment";
import { Disability } from "src/services/model/Participant";
import {Race} from "../services/model";
import { SupportiveService } from "../services/model/Services";
// import { participantFormConstants } from "./participantFormConstants";
import { validationConstants } from "./validation/validationConstants";
// import {outcomeFormConstants} from './outcomeFormConstants'

export function parseStringAsBool(str: string){
  if (str === 'true' || str === 'TRUE') {
    return true;
  } else if (str === 'false' || str === 'FALSE') {
    return false;
  }
  return null;
}

export function nullOutBlankFieldsOrTrim(obj: any) {
  const objKeys = Object.keys(obj)
  // let example
  // CSV parser defaults to empty strings when no value is passed, so null out empty strings
  for (const k of objKeys) {
    // let key = Object.keys(participantFormConstants).find(y=>participantFormConstants[y] === k)
    // if(key){
    //   example = participantFormConstants[key.replace('KEY','EXAMPLE')]
    // }
    // else{
    //   key = Object.keys(outcomeFormConstants).find(y=>outcomeFormConstants[y] === k)
    //   example = outcomeFormConstants[key!.replace('KEY','EXAMPLE')]
    // }
    if (obj[k]) {
      obj[k] = obj[k].trim()
      if (obj[k] === '') {
        obj[k] = null
      }
    } else {
      obj[k] = null
    }
  }
  return obj;
}

export function yesNoToBoolean( val: string ) {
  return val && val.toUpperCase() === 'YES' ? true : false;
}

export function yesNoToNullableBoolean( val: string ) {
  if (val) {
    if (val.toUpperCase() === 'YES') {
      return true;
    } else if (val.toUpperCase() === 'NO') {
      return false;
    }
  }
  return null;
}

export function formatUpperCase( val: string ) {
  return val && val.toUpperCase();
}

export function formatStandardDate( dateVal: string ) {
  return dateVal && moment(dateVal, validationConstants.DATE_FORMAT).format(validationConstants.DATE_FORMAT);
}

export function trimAndSplitRaces(val: string ): Race[] {
  return val ? val.split(',').map(stringVal => stringVal.trim().toUpperCase() as Race) : []
}

export function trimAndSplitDisabilities(val: string ): Disability[] {
  return val ? val.split(',').map(stringVal => stringVal.trim().toUpperCase().replace(/\//g,'_') as Disability) : []
}

export function trimAndSplitSupportiveServices(val: string ): SupportiveService[] {
  return val ? val.split(',').map(stringVal => stringVal.trim().toUpperCase() as SupportiveService) : []
}

function isValidDateFormat( dateVal: string ) {
  return dateVal && validationConstants.DATE_REGEX.test(dateVal);
}

function isValidDate( dateVal: string ) {
  const result = dateVal && moment(dateVal, validationConstants.DATE_FORMAT).isValid();
  return result
}

export function validateDate(dateVal: string) {
  const result = dateVal && !(isValidDateFormat(dateVal) && isValidDate(dateVal));
  return result
};
