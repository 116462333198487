import {createStyles, Divider, Theme, Typography, withStyles, WithStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {
	EducationMapper,
	EthnicityMapper,
	FullParticipantData,
	GenderMapper,
	RaceMapper,
	// SurveyResponse,
	SurveyResponseMapper
} from "../../services/model";

import {phoneNumberFormatter} from '../../utils/stringFormatters'
import InfoItem from '../templates/InfoItem/InfoItem'

interface ActivityParticipantProps extends WithStyles<typeof styles> {
	participant: FullParticipantData;
}

const PERSONAL_INFO_ITEMS = [
	{
		prop: 'firstName',
		label: 'First Name',
	},
	{
		prop: 'middleInitial',
		label: 'Middle Initial',
	},
	{
		prop: 'lastName',
		label: 'Last Name',
	},
	{
		prop: 'dateOfBirth',
		label: 'Date of Birth',
	},
]
const ADDRESS_ITEMS = [
	{
		prop: 'address1',
		label: 'Address Line 1',
	},
	{
		prop: 'address2',
		label: 'Address Line 2',
	},
	{
		prop: 'city',
		label: 'City',
	},
	{
		prop: 'state',
		label: 'State',
	},
	{
		prop: 'zip',
		label: 'Zip',
	},
]
const SECONDARY_ADDRESS_ITEMS = [
	{
		prop: 'mailingAddress1',
		label: 'Mailing Address Line 1',
	},
	{
		prop: 'mailingAddress2',
		label: 'Mailing Address Line 2',
	},
	{
		prop: 'mailingCity',
		label: 'Mailing City',
	},
	{
		prop: 'mailingState',
		label: 'Mailing State',
	},
	{
		prop: 'mailingZip',
		label: 'Mailing Zip',
	},
]
const ENROLLMENT_DATA = [
	{
		prop: 'citizen',
		label: 'Citizen',
	},
	{
		prop: 'veteran',
		label: 'Veteran',
	},
	{
		prop: 'ell',
		label: 'English Language Learner',
	},
	{
		prop: 'education',
		label: 'Education',
	},
	{
		prop: 'highestGrade',
		label: 'Highest level of education',
	},
	{
		prop: 'attendingSchool',
		label: 'Currently in School',
	},
	{
		prop: 'currentSchool',
		label: 'Current School',
	},
	{
		prop: 'working',
		label: 'Currently working',
	},
	{
		prop: 'employer',
		label: 'Employer name',
	},
	{
		prop: 'laidOff',
		label: 'Laid off, or notified of layoff',
	},
	{
		prop: 'layoffDate',
		label: 'Laid off date',
	},
	{
		prop: 'layoffEmployer',
		label: 'Layoff employer name',
	},
	{
		prop: 'higherWage',
		label: 'Have you ever earned higher wages than you earn now?',
	},
	{
		prop: 'betterSkills',
		label: 'Are you looking to make better use of your skills and/or education?',
	},
	{
		prop: 'familyDependent',
		label: 'Have you experienced a loss of income from a family member that is still impacting your financial situation?',
	},
  // {
	// 	prop: 'disabilityStatus',
	// 	label: 'Disability Status',
	// },
]
const yesNoNormalizer = (val?: any) => {
	if (val === null || val === undefined) {
		return ''
	}
	return val ? 'Yes' : 'No'
}

export class ActivityParticipant extends React.Component<ActivityParticipantProps, {}> {
	// @ts-ignore
	public readonly state: {} = {}

	enrollmentDataParser = (prop: any) => {
		const { participant } = this.props

		if (!participant) return

		let passValue

		switch (prop) {
			case 'layoffEmployer':
				passValue = participant[prop] || ''
				break
			case 'employer':
				passValue = participant[prop] || ''
				break
			case 'education':
				passValue = EducationMapper.get(participant[prop]) || ''
				break
			case 'highestGrade':
				passValue = participant[prop] || ''
				break
			case 'layoffDate':
				passValue = participant[prop] ? moment(participant[prop]).calendar() : ''
				break
			case 'employmentType':
				passValue = participant[prop] ? participant[prop] : ''
				break
			case 'currentSchool':
				passValue = participant[prop] || ''
				break
			default:
				passValue = SurveyResponseMapper.get(participant[prop]) || ''
		}

		return passValue
	}

	render() {
		const { classes, participant } = this.props
		const { email, ethnicity, gender, phoneNumber } = participant

		return (
			<div className={classes.root}>
				{participant && (
					<div>
						<Typography variant="h5" component="h2" className={classes.header}>
							{`${participant.lastName}, ${participant.firstName} ${participant.middleInitial || ''}`}
						</Typography>
						<div className={classes.infoContainer}>
							<InfoItem label={'DMS Activity Participant ID'} value={participant.activityParticipantId} dualCol={false} />
							<InfoItem label={'DMS Participant ID'} value={participant.id} dualCol={false} />
							{PERSONAL_INFO_ITEMS.map((pInfoItem: any, index: number) => (
								<InfoItem
									label={pInfoItem.label}
									value={pInfoItem.prop === 'dateOfBirth' ? moment(participant[pInfoItem.prop]).calendar() : participant[pInfoItem.prop]}
									key={index}
									dualCol={true}
								/>
							))}
							<InfoItem label={'Phone number'} value={phoneNumber ? phoneNumberFormatter(phoneNumber) : ''} dualCol={true} />
							<InfoItem label={'Email Address'} value={email || ''} dualCol={true} />
							<InfoItem label={'Gender'} value={GenderMapper.get(gender) || ''} dualCol={true} />
							<InfoItem label={'Ethnicity'} value={EthnicityMapper.get(ethnicity) || ''} dualCol={true} />
							<InfoItem label={'Race'} value={participant.races.map(r => RaceMapper.get(r)).join(' / ')} dualCol={true} />
						</div>
						<Divider style={{ margin: '12px 0' }} />
						<div className={classes.infoContainer}>
							<InfoItem label={'Homeless'} value={yesNoNormalizer(participant.homeless)} dualCol={true} />
							{ADDRESS_ITEMS.map((addressItem: any, index: number) => (
								<InfoItem key={index} label={addressItem.label} value={participant[addressItem.prop]} dualCol={true} />
							))}
						</div>
						<div className={classes.infoContainer}>
							{SECONDARY_ADDRESS_ITEMS.map((addressItem: any, index: number) => (
								<InfoItem key={index} label={addressItem.label} value={participant[addressItem.prop]} dualCol={true} />
							))}
						</div>

						<Divider style={{ margin: '12px 0' }} />

						<Typography variant="h5" component="h2" className={classes.header}>
							Enrollment Data
						</Typography>

						<div className={classes.infoContainer} style={{ paddingBottom: '16px' }}>
							{ENROLLMENT_DATA.map((enrollmentItem: any, index: number) => {
								const passValue = this.enrollmentDataParser(enrollmentItem.prop)

								return <InfoItem key={index} label={enrollmentItem.label} value={passValue} dualCol={true} />
							})}
						</div>
            {/* <Typography variant="h6"  component="h3" style={{ display: 'inline-block', paddingRight: '15px' }}>
              <p style={{ marginBottom:0 }}>I confirm that I want and am available for work (including being currently employed), and one or more of the following statements is true for me: </p> 
              <ul>
                <li>I am unemployed</li>
                <li>I have looked for work sometime in the past 12 months</li>
                <li>I am employed part-time but want and am available for full-time work</li>
                <li>I am employed but am seeking a position with greater opportunities for economic advancement</li>
              </ul>
						</Typography>

            <Typography variant="body1" component="div">
              {participant.covid === SurveyResponse.YES ? 'Yes' : participant.covid === SurveyResponse.NO ? 'No':'' }
            </Typography> */}

            {/* Quest */}
            {/* <Typography variant="h6" component="h3" style={{ display: 'inline-block', paddingRight: '15px' }}>
            <p style={{ marginBottom:0 }}>I confirm that one or more of the following statements is true for me:</p> 
              <ul>
                <li>At some point since the start of the pandemic (Jan. 27, 2020 – today), I lost my source of income or earned less money (For example: employer shut down/closed during pandemic, laid off from employer during COVID, hours cut at work, loss of second job, loss of overtime hours, loss of access to clients or contracts, loss of childcare, could not report work due to COVID health concerns, could not report to work to stay home and care for a family member, etc.)</li>
                <li>At some point since the start of the pandemic (Jan. 27, 2020 – today), I had difficulty being hired (For example: fewer job opportunities, lack of access to transit, inability to interview in an in-person environment, increased barriers to establishing my own business, impacted employment prospects for my skill set, etc.)</li>
              </ul>
						</Typography>

            <Typography variant="body1" component="div">
              {participant.quest === SurveyResponse.YES ? 'Yes' : participant.quest === SurveyResponse.NO ? 'No':'' }
            </Typography> */}
					</div>
				)}
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		header: {
			padding: `${theme.spacing.unit * 2}px 0`,
			textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
			fontWeight: 500,
			[theme.breakpoints.down('sm')]: {
				padding: `${theme.spacing.unit}px 0`,
			},
		},
		infoContainer: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			paddingLeft: `${theme.spacing.unit * 4}px`,
		},
		addressContainer: {
			flexBasis: '48%',
			[theme.breakpoints.down('sm')]: {
				flexBasis: '98%',
			},
		},
	})

export default withStyles(styles)(ActivityParticipant)
