import { ParticipantFormKeys } from '../interface/participantFormKeys';
import {validationConstants} from "./validation/validationConstants";

export const participantFormConstants: ParticipantFormKeys = {
	'ACTIVITY_ID_KEY': 'Cohort ID (Mandatory)',
	'ACTIVITY_ID_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'FIRST_NAME_KEY': 'First Name (Mandatory)',
	'FIRST_NAME_EXAMPLE': '',
	'MIDDLE_INITIAL_KEY': 'Middle Initial',
	'MIDDLE_INITIAL_EXAMPLE': '',
	'LAST_NAME_KEY': 'Last Name (Mandatory)',
	'LAST_NAME_EXAMPLE': '',
	'DATE_OF_BIRTH_KEY': 'Date of Birth',
	'DATE_OF_BIRTH_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'HOMELESS_KEY': 'Homeless',
	'HOMELESS_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'ADDRESS1_KEY': 'Address 1',
	'ADDRESS1_EXAMPLE': '123 Alphabet St',
	'ADDRESS2_KEY': 'Address 2',
	'ADDRESS2_EXAMPLE': '',
	'CITY_KEY': 'City',
	'CITY_EXAMPLE': 'Providence',
	'STATE_KEY': 'State',
	'STATE_EXAMPLE': validationConstants.STATE_INITIALS_EXAMPLE,
	'ZIP_KEY': 'Zipcode',
	'ZIP_EXAMPLE': validationConstants.ZIP_EXAMPLE,
	'MAILING_ADDRESS1_KEY': 'Mailing Address 1',
	'MAILING_ADDRESS1_EXAMPLE': '123 Alphabet St',
	'MAILING_ADDRESS2_KEY': 'Mailing Address 2',
	'MAILING_ADDRESS2_EXAMPLE': '',
	'MAILING_CITY_KEY': 'Mailing City',
	'MAILING_CITY_EXAMPLE': 'Providence',
	'MAILING_STATE_KEY': 'Mailing State',
	'MAILING_STATE_EXAMPLE': validationConstants.STATE_INITIALS_EXAMPLE,
	'MAILING_ZIP_KEY': 'Mailing Zip',
	'MAILING_ZIP_EXAMPLE': validationConstants.ZIP_EXAMPLE,
	'PHONE_KEY': 'Phone',
	'PHONE_EXAMPLE': '4011234567',
	'EMAIL_KEY': 'Email',
	'EMAIL_EXAMPLE': '',
	'GENDER_KEY': 'Gender',
	'GENDER_EXAMPLE': 'FEMALE | MALE | TRANS_FEMALE | TRANS_MALE | NON_BINARY | OTHER | DND',
	'ETHNICITY_KEY': 'Ethnicity',
	'ETHNICITY_EXAMPLE': 'LATINO | NOT-LATINO | NA',
	'RACES_KEY': 'Races',
	'RACES_EXAMPLE': 'ASIAN,BLACK,INDIGENOUS,ISLANDER,WHITE | NA',
	'PREFERRED_LANGUAGE_KEY': 'Preferred Language',
	'PREFERRED_LANGUAGE_EXAMPLE': 'ARABIC | CHINESE | ENGLISH | FRENCH | HAITIAN CREOLE | ITALIAN | KHMER | PORTUGUESE | SPANISH | OTHER',
	'CITIZEN_KEY': 'US Citizen',
	'CITIZEN_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'VETERAN_KEY': 'Veteran',
	'VETERAN_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
  'DISABILITY_STATUS_KEY':"Disability Status",
  'DISABILITY_STATUS_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
  'DISABILITIES_KEY': "Disabilities",
  'DISABILITIES_EXAMPLE': 'PHYSICAL,HEARING,VISION,SOCIAL/EMOTIONAL,INTELLECTUAL/DEVELOPMENTAL/COGNITIVE | DND',
	'ELL_KEY': 'English Language Learner (ELL)',
	'ELL_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'EDUCATION_KEY': 'Highest level of education (Mandatory)',
	'EDUCATION_EXAMPLE': 'NO_HIGHSCHOOL | HIGH_SCHOOL | GED | SOME_COLLEGE | VOCATIONAL | ASSOCIATE | BACHELORS | MASTERS | PHD | NA',
	'HIGHEST_GRADE_KEY': 'Last grade completed',
	'HIGHEST_GRADE_EXAMPLE': '',
	'IN_SCHOOL_KEY': 'Currently in school or training (Mandatory)',
	'IN_SCHOOL_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'CURRENT_SCHOOL_KEY': 'Current school name',
	'CURRENT_SCHOOL_EXAMPLE': '',
	'WORKING_KEY': 'Currently employed',
	'WORKING_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'EMPLOYER_KEY': 'Employer name',
	'EMPLOYER_EXAMPLE': '',
	'EMPLOYMENT_TYPE_KEY': 'Employment type',
	'EMPLOYMENT_TYPE_EXAMPLE': 'FULL_TIME | PART_TIME',
	'LAID_OFF_KEY': 'Have you been laid off, or received notice that you will be laid off',
	'LAID_OFF_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'LAYOFF_DATE_KEY': 'Layoff Date',
	'LAYOFF_DATE_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'LAYOFF_EMPLOYER_KEY': 'Layoff Employer Name',
	'LAYOFF_EMPLOYER_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'HIGHER_WAGES_KEY': 'Have you ever earned higher wages than you earn now',
	'HIGHER_WAGES_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'SKILLS_KEY': 'Are you looking to make better use of your skills and/or education',
	'SKILLS_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'FAMILY_DEPENDENT_KEY': 'Have you experienced a loss of income from a family member that is still impacting your financial situation',
	'FAMILY_DEPENDENT_EXAMPLE': validationConstants.SURVEY_EXAMPLE,
	'COVID_KEY': 'Meet ARPA Criteria?',
	'COVID_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
  'QUEST_KEY': 'QUEST Key',
	'QUEST_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	// 'ACCEPTED_COVID_KEY': 'COVID-19 has affected my work or my work search and that is why I am participating in the Back to Work RI program',
	// 'ACCEPTED_COVID_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'ERROR_KEY': 'Error',
}

export const exampleParticipantCsv = [{
	[participantFormConstants.ACTIVITY_ID_KEY]: participantFormConstants.ACTIVITY_ID_EXAMPLE,
	// Participant
	[participantFormConstants.FIRST_NAME_KEY]: participantFormConstants.FIRST_NAME_EXAMPLE,
	[participantFormConstants.MIDDLE_INITIAL_KEY]: participantFormConstants.MIDDLE_INITIAL_EXAMPLE,
	[participantFormConstants.LAST_NAME_KEY]: participantFormConstants.LAST_NAME_EXAMPLE,
	[participantFormConstants.DATE_OF_BIRTH_KEY]: participantFormConstants.DATE_OF_BIRTH_EXAMPLE,
  [participantFormConstants.EDUCATION_KEY]: participantFormConstants.EDUCATION_EXAMPLE,
	[participantFormConstants.HIGHEST_GRADE_KEY]: participantFormConstants.HIGHEST_GRADE_EXAMPLE,
	[participantFormConstants.IN_SCHOOL_KEY]: participantFormConstants.IN_SCHOOL_EXAMPLE,
	[participantFormConstants.CURRENT_SCHOOL_KEY]: participantFormConstants.CURRENT_SCHOOL_EXAMPLE,
	[participantFormConstants.HOMELESS_KEY]: participantFormConstants.HOMELESS_EXAMPLE,
	[participantFormConstants.ADDRESS1_KEY]: participantFormConstants.ADDRESS1_EXAMPLE,
	[participantFormConstants.ADDRESS2_KEY]: participantFormConstants.ADDRESS2_EXAMPLE,
	[participantFormConstants.CITY_KEY]: participantFormConstants.CITY_EXAMPLE,
	[participantFormConstants.STATE_KEY]: participantFormConstants.STATE_EXAMPLE,
	[participantFormConstants.ZIP_KEY]: participantFormConstants.ZIP_EXAMPLE,
	[participantFormConstants.MAILING_ADDRESS1_KEY]: participantFormConstants.MAILING_ADDRESS1_EXAMPLE,
	[participantFormConstants.MAILING_ADDRESS2_KEY]: participantFormConstants.MAILING_ADDRESS2_EXAMPLE,
	[participantFormConstants.MAILING_CITY_KEY]: participantFormConstants.MAILING_CITY_EXAMPLE,
	[participantFormConstants.MAILING_STATE_KEY]: participantFormConstants.MAILING_STATE_EXAMPLE,
	[participantFormConstants.MAILING_ZIP_KEY]: participantFormConstants.MAILING_ZIP_EXAMPLE,
	[participantFormConstants.PHONE_KEY]: participantFormConstants.PHONE_EXAMPLE,
	[participantFormConstants.EMAIL_KEY]: participantFormConstants.EMAIL_EXAMPLE,
	[participantFormConstants.GENDER_KEY]: participantFormConstants.GENDER_EXAMPLE,
	[participantFormConstants.ETHNICITY_KEY]: participantFormConstants.ETHNICITY_EXAMPLE,
	[participantFormConstants.RACES_KEY]: participantFormConstants.RACES_EXAMPLE,
	[participantFormConstants.PREFERRED_LANGUAGE_KEY]: participantFormConstants.PREFERRED_LANGUAGE_EXAMPLE,
	// Survey
	[participantFormConstants.CITIZEN_KEY]: participantFormConstants.CITIZEN_EXAMPLE,
	[participantFormConstants.VETERAN_KEY]: participantFormConstants.VETERAN_EXAMPLE,
  [participantFormConstants.DISABILITY_STATUS_KEY]: participantFormConstants.DISABILITY_STATUS_EXAMPLE,
  [participantFormConstants.DISABILITIES_KEY]:participantFormConstants.DISABILITIES_EXAMPLE,
	[participantFormConstants.ELL_KEY]: participantFormConstants.ELL_EXAMPLE,
	
	[participantFormConstants.WORKING_KEY]: participantFormConstants.WORKING_EXAMPLE,
	[participantFormConstants.EMPLOYER_KEY]: participantFormConstants.EMPLOYER_EXAMPLE,
	[participantFormConstants.EMPLOYMENT_TYPE_KEY]: participantFormConstants.EMPLOYMENT_TYPE_EXAMPLE,
	[participantFormConstants.LAID_OFF_KEY]: participantFormConstants.LAID_OFF_EXAMPLE,
	[participantFormConstants.LAYOFF_DATE_KEY]: participantFormConstants.LAYOFF_DATE_EXAMPLE,
	[participantFormConstants.LAYOFF_EMPLOYER_KEY]: participantFormConstants.LAYOFF_EMPLOYER_EXAMPLE,
	[participantFormConstants.HIGHER_WAGES_KEY]: participantFormConstants.HIGHER_WAGES_EXAMPLE,
	[participantFormConstants.SKILLS_KEY]: participantFormConstants.SKILLS_EXAMPLE,
	[participantFormConstants.FAMILY_DEPENDENT_KEY]: participantFormConstants.FAMILY_DEPENDENT_EXAMPLE,
	[participantFormConstants.COVID_KEY]: participantFormConstants.COVID_EXAMPLE,
  [participantFormConstants.QUEST_KEY]: participantFormConstants.QUEST_EXAMPLE,
	// [participantFormConstants.ACCEPTED_COVID_KEY]: participantFormConstants.ACCEPTED_COVID_EXAMPLE,
}];
