import { createStyles, IconButton, TableCell, TableRow, TextField, Theme, Tooltip, WithStyles, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'

import { ActivityReporter, ActivityReporterData } from '../../../services/model'
import { preventFormDefault } from '../../../utils/errorHandlers'

interface AddReporterState {
	email: string
	name?: string
}

interface AddReporterProps extends WithStyles<typeof styles> {
	currentReporter?: ActivityReporter
	onCancel: () => void
	onSubmit: (reporter: ActivityReporter | ActivityReporterData) => void
}

const INITIAL_STATE: AddReporterState = {
	email: '',
	name: '',
}

export class AddReporter extends React.Component<AddReporterProps, AddReporterState> {
	public readonly state: AddReporterState = INITIAL_STATE

	onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target
		this.setState((prevState: AddReporterState) => {
			return {
				...prevState,
				[name]: value,
			}
		})
	}

	onSave = (e: React.FormEvent<HTMLFormElement>) => {
		preventFormDefault(e)
		const { name, email } = this.state
		const localReporter = Object.assign({}, this.populateLocalReporter(), { name, email })

		this.props.onSubmit(localReporter)
		this.resetToOriginalState()
	}

	populateLocalReporter = (): ActivityReporter | {} => {
		return this.props.currentReporter ? Object.assign({}, { ...this.props.currentReporter }) : {}
	}

	onCancel = () => {
		this.resetToOriginalState()

		this.props.onCancel()
	}

	resetToOriginalState = () => {
		const name = this.props.currentReporter ? this.props.currentReporter.name : ''
		const email = this.props.currentReporter ? this.props.currentReporter.email : ''

		this.setState({
			name,
			email,
		})
	}

	componentDidMount() {
		if (this.props.currentReporter) {
			const { email, name } = this.props.currentReporter

			this.setState({ email, name })
		}
	}

	render() {
		const { classes, currentReporter } = this.props
		const { name, email } = this.state
		const isSaveDisabled = currentReporter && currentReporter.email === email && currentReporter.name === name

		return (
			<TableRow component="div" className={classes.row} hover={true}>
				<TableCell component="div" className={classes.cell} style={{ width: '30%' }}>
					<TextField
						value={name}
						variant="outlined"
						fullWidth={true}
						onChange={this.onInputChange}
						autoFocus={true}
						placeholder="Full Name"
						inputProps={{
							'data-auto': 'name-input',
							name: 'name',
							'aria-label': 'Full Name',
						}}
					/>
				</TableCell>
				<TableCell component="div" className={classes.cell} style={{ width: '38%' }}>
					<TextField
						aria-label="email"
						value={email}
						variant="outlined"
						required={true}
						fullWidth={true}
						onChange={this.onInputChange}
						placeholder="Email"
						inputProps={{
							'data-auto': 'email-input',
							name: 'email',
							title: 'Full Name',
						}}
					/>
				</TableCell>
				<TableCell component="div" variant="body">
					<div className={classes.btnCell}>
						<form onSubmit={this.onSave}>
							<Tooltip title="Save">
								<IconButton
									className={classes.button}
									aria-label={currentReporter ? 'Save' : 'Add'}
									color="default"
									data-auto="button-submit"
									disabled={isSaveDisabled}
									type="submit"
								>
									{currentReporter ? <SaveIcon aria-label="save" className={classes.saveIcon} /> : <AddIcon aria-label="add" className={classes.addIcon} />}
								</IconButton>
							</Tooltip>
							<Tooltip title="Cancel">
								<IconButton className={classes.button} aria-label="Cancel" color="default" onClick={this.onCancel} data-auto="button-cancel">
									<CancelIcon className={classes.cancelIcon} />
								</IconButton>
							</Tooltip>
						</form>
					</div>
				</TableCell>
			</TableRow>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		row: {},
		cell: {},
		btnCell: {
			display: 'flex',
			flexDirection: 'row',
		},
		button: {},
		saveIcon: {
			'&:hover, &:active': {
				color: theme.palette.primary.main,
				transition: 'all 0.5s ease',
			},
		},
		cancelIcon: {
			'&:hover, &:active': {
				color: theme.palette.secondary.main,
				transition: 'all 0.5s ease',
			},
		},
		addIcon: {
			'&:hover, &:active': {
				color: theme.palette.primary.main,
				transition: 'all 0.5s ease',
			},
		},
	})

export default withStyles(styles)(AddReporter)
