import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography, Zoom } from '@material-ui/core'
import { blue, grey } from '@material-ui/core/colors'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import AddIcon from '@material-ui/icons/Add'

import { createActivityReporter, updateActivityReporter } from '../../../services/addenda-activity.service'
import { Notification, NotificationInterface, withNotificationContext } from '../../../services/ContextService/NotificationService/NotificationContextService'
import { ActivityReporter, ActivityReporterData } from '../../../services/model'
import { getSorting, Sort, stableSort } from '../../../utils/sortingService'
import AddReporter from '../../Forms/AddReporter/AddReporter'
import ActivityReporterItem from '../../templates/ActivityReporterItem/ActivityReporterItem'
import EmptyTableTypography from '../../templates/EmptyTableTypography/EmptyTableTypography'

export type ReportersTableRouterProps = ActivityReportersTableProps & RouteComponentProps<{}> & WithStyles<{}>

interface LocalNotifications {
	addSuccess: Notification
	deleteSuccess: Notification
	notifySuccess: Notification
	success: Notification
	fail400: Notification
	fail404: Notification
	fail409: (email: string) => Notification
	fail0: Notification
}

interface ActivityReportersTableProps extends WithStyles<typeof styles> {
	reporters: ActivityReporter[]
	onReportersChange: () => void
	appContext: NotificationInterface
}

interface ActivityReportersTableState {
	showAddNewFields: boolean
	sortBy: string
	sortDirection: Sort
	sortedReporters: ActivityReporter[]
}

const NOTIFICATION: LocalNotifications = {
	addSuccess: { type: 'success', message: 'Reporter added successfully.' },
	deleteSuccess: { type: 'success', message: 'Reporter removed successfully.' },
	notifySuccess: { type: 'success', message: 'Notification sent.' },
	success: { type: 'success', message: 'Reporter updated successfully.' },
	fail400: { type: 'fail', message: 'Bad input, please try again' },
	fail404: { type: 'fail', message: 'Something went wrong, please try again.' },
	fail409: (email) => {
		return {
			type: 'fail',
			message: `Error: ${email} already a reporter for this Activity.`,
		}
	},
	fail0: { type: 'fail', message: 'Error, please try again.' },
}

const generateInitialState = (props: ActivityReportersTableProps) => ({
	showAddNewFields: false,
	sortBy: 'name',
	sortDirection: 'asc' as Sort,
	sortedReporters: props.reporters,
})

export class ActivityReportersTable extends React.Component<ReportersTableRouterProps, ActivityReportersTableState> {
	public readonly state: ActivityReportersTableState = generateInitialState(this.props)

	formatReporters = (reporters: ActivityReporter[]): ActivityReporter[] => {
		return reporters.map((reporter) => {
			reporter.name = reporter.name || ''
			return reporter
		})
	}

	handleAddReporter = () => {
		this.setState({ showAddNewFields: true })
	}

	onReporterSubmit = async (reporter: ActivityReporter | ActivityReporterData) => {
		if ('id' in reporter) {
			await this.updateReporter(reporter).catch((e) => this.setNotification(NOTIFICATION.fail0))

			this.props.onReportersChange()
		} else {
			try {
				await createActivityReporter(this.props.location.pathname, reporter)
				this.setNotification(NOTIFICATION.addSuccess)
				this.props.onReportersChange()
			} catch (e) {
				const { email } = reporter
				const err = e.message
				this.setNotification(err === '409' ? NOTIFICATION.fail409(email) : NOTIFICATION[`fail${err}`])
			}
		}
		this.hideAddReporterField()
	}

	setNotification = (notification: Notification) => {
		setTimeout(() => {
			this.props.appContext.handleNotification(notification)
		}, 300)
	}

	updateReporter = async (reporter: ActivityReporter) => {
		const { name, email, id } = reporter
		return await updateActivityReporter(this.props.location.pathname, id, { name, email })
	}

	handleCancelAddReporter = (): void => {
		this.hideAddReporterField()
	}

	hideAddReporterField = (): void => {
		this.setState({ showAddNewFields: false })
	}

	handleOnReporterDelete = (): void => {
		this.props.onReportersChange()
		this.setNotification(NOTIFICATION.deleteSuccess)
	}

	handleOnReporterEdit = async (reporter: ActivityReporter): Promise<void> => {
		const { name, email, id } = reporter

		try {
			await updateActivityReporter(this.props.location.pathname, id, { name, email })

			this.setNotification(NOTIFICATION.success)
			this.props.onReportersChange()
		} catch ({ message }) {
			this.setNotification(message === '409' ? NOTIFICATION.fail409(email) : NOTIFICATION[`fail${message}`])
		}
	}

	handleOnNotificationSent = (success: boolean): void => {
		success ? this.setNotification(NOTIFICATION.notifySuccess) : this.setNotification(NOTIFICATION.fail0)
	}

	handleSortClick = (prop: string) => (e: React.MouseEvent<HTMLInputElement>): void => {
		const sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
		this.setState({
			sortBy: prop,
			sortDirection,
			sortedReporters: stableSort(this.state.sortedReporters, getSorting(sortDirection, prop)),
		})
	}

	sortReporters = (reporters: ActivityReporter[], direction: Sort, sortBy: string) => {
		this.setState({
			sortedReporters: stableSort(reporters, getSorting(direction, sortBy)),
		})
	}

	componentDidMount() {
		const { sortBy, sortDirection, sortedReporters } = this.state

		this.sortReporters(this.formatReporters(sortedReporters), sortDirection, sortBy)
	}

	componentWillReceiveProps(nextProps: ActivityReportersTableProps) {
		if (this.props.reporters !== nextProps.reporters) {
			const { sortBy, sortDirection } = this.state

			this.sortReporters(this.formatReporters(nextProps.reporters), sortDirection, sortBy)
		}
	}

	render() {
		const { classes } = this.props
		const { showAddNewFields, sortBy, sortDirection, sortedReporters } = this.state

		return (
			<div className={classes.root} data-auto="activity-reporters-table">
				<Typography className={classes.title} variant="h5" component="h1" data-auto="activity-reporters">
					Enrollment Managers:
				</Typography>

				<Paper square={true} elevation={2} className={classes.tableWrapper} data-auto="enrollment-managers-table">
					{
						<Table component="div" className={classes.table} data-auto="main-table">
							<TableHead component="div" className={classes.tableHeader} data-auto="main-table-header">
								<TableRow component="div" className={classes.tableRow}>
									<TableCell
										scope="row"
										component="div"
										variant="head"
										className={classes.colHeader}
										data-auto={`table-header-row-item-name`}
										style={{ minWidth: '250px' }}
									>
										<Tooltip title="Sort" placement="right" enterDelay={300}>
											<TableSortLabel active={sortBy === 'name'} direction={sortDirection} onClick={this.handleSortClick('name')} style={{ color: grey[50] }}>
												<Typography variant="button" className={classes.typographyHeader} data-auto="header-name">
													Name
												</Typography>
											</TableSortLabel>
										</Tooltip>
									</TableCell>

									<TableCell component="div" variant="head" className={classes.colHeader}>
										<Tooltip title="Sort" placement="right" enterDelay={300}>
											<TableSortLabel active={sortBy === 'email'} direction={sortDirection} onClick={this.handleSortClick('email')}>
												<Typography variant="button" className={classes.typographyHeader} data-auto="header-email">
													Email
												</Typography>
											</TableSortLabel>
										</Tooltip>
									</TableCell>
									<TableCell component="div" variant="head" className={`${classes.colHeader} ${classes.colEmptyHeader}`} />
								</TableRow>
							</TableHead>
							<TableBody component="div" data-auto="table-body">
								{sortedReporters.map((reporter: ActivityReporter, index: number) => (
									<ActivityReporterItem
										contactDetails={reporter}
										key={index}
										onDelete={this.handleOnReporterDelete}
										onEdit={this.handleOnReporterEdit}
										onNotify={this.handleOnNotificationSent}
									/>
								))}

								{showAddNewFields && <AddReporter onSubmit={this.onReporterSubmit} onCancel={this.handleCancelAddReporter} />}
							</TableBody>
						</Table>
					}
					{!sortedReporters.length && <EmptyTableTypography label="reporters" />}
				</Paper>

				{!showAddNewFields && (
					<Zoom in={!showAddNewFields}>
						<Button variant="contained" className={classes.addButton} color="default" data-auto="add-activity-contact-button" onClick={this.handleAddReporter}>
							<AddIcon className={classes.buttonIcon} />
							Add Manager
						</Button>
					</Zoom>
				)}
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			padding: '10px',
		},
		title: {
			marginBottom: theme.spacing.unit * 4,
		},
		tableWrapper: {
			overflowX: 'scroll',
		},
		table: {
			padding: '0 20px',
		},
		tableHeader: {
			backgroundColor: blue[900],
			paddingLeft: theme.spacing.unit,
			textTransform: 'uppercase',
		},
		colEmptyHeader: {
			width: '32px',
			padding: '0',
		},
		tableRow: {
			flex: '1 1 100%',
		},
		colHeader: {},
		addButton: {
			marginTop: theme.spacing.unit * 2,
		},
		buttonIcon: {},
		typographyHeader: {
			color: grey[50],
		},
	})

export const styledActivityReportersTable = withStyles(styles)(ActivityReportersTable)

export default withRouter(withNotificationContext(styledActivityReportersTable))
