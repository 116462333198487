import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Hidden,
	Theme,
	Tooltip,
	WithStyles,
	withStyles,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import React from 'react'
import { changeCodeById, getActivityCodeById } from '../../services/code.service'

import TypographyHeader from '../templates/TypographyHeader/TypographyHeader'

interface CodeZoneProps extends WithStyles<typeof styles> {
	activityId: string
}

interface CodeZoneState {
	open: boolean
	code: string | null
}

const CODE_TOOLTIP =
	'The code may need to be changed if the ' +
	'previous code was distributed to individuals who should not be enrolling in ' +
	'this training. Once the code is reset, the previous code will be invalid and ' +
	'the new code will need to be redistributed.'

class CodeZone extends React.Component<CodeZoneProps, CodeZoneState> {
	public readonly state: CodeZoneState = {
		open: false,
		code: null,
	}

	setOpen = () => this.setState({ open: true })
	setClosed = () => this.setState({ open: false })
	setCode = (code: string) => this.setState({ code })

	componentDidMount() {
		getActivityCodeById(this.props.activityId)
			.then((code) => this.setCode(code))
			.catch((err) => console.log(err))
	}

	handleChangeCode = () => {
		this.setClosed()
		changeCodeById(this.props.activityId)
			.then((code) => this.setCode(code))
			.catch((err) => console.log(err))
	}

	render() {
		const { classes } = this.props
		const { code, open } = this.state

		return (
			<div className={classes.root}>
				{code && (
					<div className={classes.title}>
						<TypographyHeader label="Activity Code:" type={'subtitle'} inline={true} />
						<span style={{ marginLeft: '4px' }} />
						<TypographyHeader label={`${code.toLowerCase()}`} type={'subtitle'} inline={true} />
					</div>
				)}

				<Hidden smDown={true} smUp={!code}>
					<Tooltip title={CODE_TOOLTIP} aria-label="Reset Code" placement="right-start">
						<Button size="medium" onClick={this.setOpen} color="default" variant="contained">
							<RefreshIcon style={{ marginRight: '8px' }} />
							Reset Code
						</Button>
					</Tooltip>
					<Dialog open={open} onClose={this.setClosed}>
						<DialogTitle>{'Create new activity code?'}</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{'This will invalidate the existing activity code. Are you sure you wish to continue?'}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button size="small" onClick={this.setClosed} variant="contained">
								Cancel
							</Button>
							<Button size="small" onClick={this.handleChangeCode} variant="contained" color="primary">
								Confirm
							</Button>
						</DialogActions>
					</Dialog>
				</Hidden>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		title: {
			marginRight: '12px',
		}
	})

export default withStyles(styles)(CodeZone)
