import { State } from './Participant'

export interface Outcome extends OutcomeData {
	id: string
}

export interface OutcomeData {
	academicAwardDetail: string
	academicCredit: string
	benefitsProvided: boolean
	classroomHours: number
	credentialsEarned: string
	earnedDegreeOrCredit: boolean
	endDate: Date
	incumbentWorkerEmployerPlacement: string
	jobPlacementEmployer: string
	jobPlacementEmployerCity: string
	jobPlacementEmployerState: State
	jobPlacementIndustry: string
	jobPlacementOccupation: string
	jobPlacementWage: number
	jobPlacementWageFrequency: string
	jobPlacementWeeklyHours: number
	paidHours: number
	placedDirect: boolean
	placedJob: boolean
	placedJobDate: Date
	placedTraining: boolean
	promotion: boolean
	startDate: Date
	status: OutcomeStatus
	stipend: number
	trainingHours: number
	unpaidHours: number
	wageIncrease: boolean
	wblType: string
}

export enum OutcomeStatus {
	ENROLLED = "ENROLLED",
	COMPLETED = "COMPLETED",
	DROPPED_OUT = "DROPPED_OUT"
}

export const OutcomeStatusMapper = new Map<OutcomeStatus , string>([
	[OutcomeStatus.ENROLLED, 'Enrolled'],
	[OutcomeStatus.COMPLETED, 'Completed'],
	[OutcomeStatus.DROPPED_OUT, 'Dropped Out']
])

export const OutcomeCSVUploadHeaders: string[] = [
	'participantId',
	'firstName',
	'middleInitial',
	'lastName',
	'dateOfBirth',
	'startDate',
	'endDate',
	'trainingHours',
	'status',
	'placedJob',
	'placedTraining',
	'placedDirect',
	'placedJobDate',
	'incumbentWorkerEmployerPlacement',
	'jobPlacementEmployer',
	'jobPlacementEmployerCity',
	'jobPlacementEmployerState',
	'jobPlacementOccupation',
	'jobPlacementIndustry',
	'jobPlacementWage',
	'jobPlacementWageFrequency',
	'jobPlacementWeeklyHours',
	'benefitsProvided',
	'wageIncrease',
	'promotion',
	'earnedDegreeOrCredit',
	'wblType',
	'classroomHours',
	'unpaidHours',
	'paidHours',
	'stipendReceived',
	'stipendAmount',
	'academicCredit',
	'academicAwardDetail',
	'credential1',
	'credential2',
	'credential3',
	'credential4',
	'credential5',
	'creditsEarned',
	'dropReason',
	'internshipEmployer',
	'internshipOccupation',
	'positionBackfilled'
]
