import * as R from 'ramda'
import * as React from 'react'
import CustomLoader from '../../templates/CustomLoader/CustomLoader'

import {
	forceLogOutRedirect,
	getAuthCookies,
	refreshTTLCookie,
	timeTillRedirect,
} from '../../../services/Auth/auth.service'

interface AuthProviderProps {
	isAuthenticated?: boolean
}

interface AuthProviderState {
	loading: boolean
}

export const authProvider = <P extends object>(Component: React.ComponentType<P>) => {
	let TIMEOUT: any;

	return class AuthProvider extends React.Component<P & AuthProviderProps, AuthProviderState> {
		state: AuthProviderState = {
			loading: true,
		}

		componentDidMount() {
			this.checkForCookies()
			this.stopLoader()
			this.startTTLTimeout()
		}

		componentWillMount() {
			clearTimeout(TIMEOUT)
		}

		componentDidUpdate(prevProps: AuthProviderProps, prevState: AuthProviderState) {
			this.checkForCookies()
		}

		stopLoader = () => {
			setTimeout(() => {
				this.setState({
					loading: false,
				})
			}, 300)
		}

		startTTLTimeout = () => {
			TIMEOUT = this.setRefreshTimeout()
		}

		checkForCookies = () => {
			const cookies = getAuthCookies()

			if (R.any(R.isNil)(R.values(cookies)) || timeTillRedirect() < 0) {
				setTimeout(() => {
					forceLogOutRedirect()
				}, 100)
			}
		}

		setRefreshTimeout = () => {
			const timeToRedirect = timeTillRedirect()

			return setTimeout(
				async () => {
					await refreshTTLCookie()
				},
				timeToRedirect > 0 ? timeToRedirect : 0,
			)
		}

		render() {
			const { isAuthenticated, ...props } = this.props as AuthProviderProps
			const { loading } = this.state

			return loading ? <CustomLoader /> : <Component {...props as P} />
		}
	}
}
