import axios from 'axios'

import {Activity, ActivityData, ActivityReporter, ActivityReporterData, SurveyData} from './model'

import { authorizationCheck } from './Auth/auth.service'
import { PARTNERSHIPS_URL } from './partnerships.service'

const { REACT_APP_HOSTNAME } = process.env

export async function getAllActivities(partnershipID: string, addendumID: string): Promise<Activity[]> {
	const { data, status } = await axios.get('/', {
		baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities`,
	})
	authorizationCheck(status)

	return data
}

export async function createNewActivity(
	partnershipID: string,
	addendumID: string,
	activityDetails: ActivityData,
): Promise<Activity> {
	const { data, status } = await axios.post('/', activityDetails, {
		baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities`,
	})
	authorizationCheck(status)

	return data
}

export async function getActivityByID(
	partnershipID: string,
	addendumID: string,
	activityID: string,
): Promise<Activity> {
	const { data, status } = await axios.get('/', {
		baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities/${activityID}`,
	})
	authorizationCheck(status)

	return data
}

export async function getActivityReporters(
	partnershipID: string,
	addendumID: string,
	activityID: string,
): Promise<ActivityReporter[]> {
	try {
		const { data } = await axios.get('/', {
			baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities/${activityID}/reporters`,
		})

		return data
	} catch(e) {
		authorizationCheck(e.request.status)
		throw Error(e)
	}
}

export async function updateActivityReporter(
	location: string,
	reporterID: string,
	reporter: ActivityReporterData,
): Promise<ActivityReporter> {
	try {
		if (reporter.name === '') reporter.name = undefined
		const { data, status } = await axios.put(`/${reporterID}`, reporter, {
			baseURL: `${REACT_APP_HOSTNAME}${location}/reporters`,
		})

		authorizationCheck(status)

		return data
	} catch (e) {
		throw Error(e.request.status)
	}
}

export async function createActivityReporter(
	location: string,
	reporter: ActivityReporterData,
): Promise<ActivityReporter> {
	try {
		if (reporter.name === '') reporter.name = undefined
		const { data, status } = await axios.post(``, reporter, {
			baseURL: `${REACT_APP_HOSTNAME}${location}/reporters`,
		})

		authorizationCheck(status)

		return data
	} catch (e) {
		throw Error(e.request.status)
	}
}

export async function deleteActivityReporter(location: string, reporterID: string): Promise<string> {
	const { data, status } = await axios.delete(`/${reporterID}`, {
		baseURL: `${REACT_APP_HOSTNAME}${location}/reporters`,
	})

	authorizationCheck(status)

	return data
}

export async function getEnrollmentStatus(
	partnershipID: string,
	addendumID: string,
	activityID: string,
): Promise<boolean> {
	try {
		const { data, status } = await axios.get('/', {
			baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities/${activityID}/status`,
		})
		authorizationCheck(status)
		return data.enrollment
	} catch (e) {
		throw Error(e.request.status)
	}
}

export async function updateEnrollmentStatus(
	partnershipID: string,
	addendumID: string,
	activityID: string,
	newStatus: boolean,
): Promise<boolean> {
	try {
		const { data, status } = await axios.put(
			'/',
			{ enrollment: newStatus },
			{
				baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities/${activityID}/status`,
			},
		)
		authorizationCheck(status)
		return data.enrollment
	} catch (e) {
		throw Error(e.request.status)
	}
}

export async function sendEmailNotification(
	partnershipId: string,
	addendumId: string,
	activityId: string,
	reporterId: string,
): Promise<boolean> {
	let success = false
	try {
		const { status } = await axios.post('/', null, {
			baseURL: `${PARTNERSHIPS_URL}/${partnershipId}/addenda/${addendumId}/activities/${activityId}/reporters/${reporterId}/notifications`,
		})
		authorizationCheck(status)
		success = status === 202
	} catch (e) {
		console.log(e.message)
	}
	return success
}

export async function getActivityParticipantById(
	partnershipId: string,
	addendumId: string,
	activityId: string,
	participantId: string
): Promise<SurveyData> {
	const { data, status } = await axios.get(`/${participantId}`, {
		baseURL: `${REACT_APP_HOSTNAME}/partnerships/${partnershipId}/addenda/${addendumId}/activities/${activityId}/participants`,
	})

	authorizationCheck(status)

	return data
}

export async function getActivityActualsByActivityID(
	partnershipId: string,
	addendumId: string,
	activityId: string,
): Promise<any> {

	return axios.get(`/`, {
		baseURL: `${REACT_APP_HOSTNAME}/partnerships/${partnershipId}/addenda/${addendumId}/activities/${activityId}/targets`,
	}).then(response => {
 		return response.data;
 	}).catch(error => {
 		return [];
	});
}

export async function updateActivityParticipant(
	partnershipId: string,
	addendumId: string,
	activityId: string,
	participantId: string,
	updatedParticipantInfo: SurveyData
): Promise<SurveyData> {
	const { data, status } = await axios.put(`/${participantId}`, updatedParticipantInfo, {
		baseURL: `${REACT_APP_HOSTNAME}/partnerships/${partnershipId}/addenda/${addendumId}/activities/${activityId}/participants`,
	})

	authorizationCheck(status)

	return data
}

export async function createActivityParticipant(
	partnershipId: string,
	addendumId: string,
	activityId: string,
	info: any
): Promise<SurveyData> {
	const { data, status } = await axios.post(`${REACT_APP_HOSTNAME}/partnerships/${partnershipId}/addenda/${addendumId}/activities/${activityId}/participants`, info)

	authorizationCheck(status)

	return data
}
