import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service'
import { Partnership } from './model'

const {  REACT_APP_HOSTNAME } = process.env
export const PARTNERSHIPS_URL = `${REACT_APP_HOSTNAME}/partnerships`


export async function getAllPartnerships(): Promise<Partnership[]> {
	try {
		const { data } = await axios.get('/', {
			baseURL: PARTNERSHIPS_URL,
		})

		return data
	}catch (e) {
		authorizationCheck(e.response.status);
		return e;
	}
}

export async function getPartnershipByID(id: string): Promise<Partnership> {
	try {
		const { data } = await axios.get(`/${id}`, {
			baseURL: PARTNERSHIPS_URL,
		})

		return data
	} catch (e) {
		authorizationCheck(e.response.status);

		return e;
	}
}

  export async function getPartnershipsByName( name: string): Promise<Partnership[]> {

	try {
		  const params = {
			  Name: encodeURIComponent(name) 
		  }
  
		  const { data } = await axios.get(PARTNERSHIPS_URL, {params})
  
		  return data
	  }catch (e) {
		  authorizationCheck(e.response.status)
		  return e
	  }
  }