import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import React from 'react'

interface EmptyTableTypographyProps extends WithStyles<typeof styles> {
	label: string
}

export const EmptyTableTypography: React.SFC<EmptyTableTypographyProps> = (props: EmptyTableTypographyProps) => {
	const { classes, label } = props

	return (
		<Typography variant={'h6'} component={'h3'} className={classes.emptyTable} color="secondary">
			No {label} to display.
		</Typography>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		emptyTable: {
			padding: `${theme.spacing.unit * 2}px`,
			textShadow: '1px 1px 2px rgba(0,0,0,0.15)',
			fontWeight: 500,
			color: theme.palette.grey[800],
			[theme.breakpoints.down('sm')]: {
				padding: `${theme.spacing.unit}px 0`,
			},
		},
	})

export default withStyles(styles)(EmptyTableTypography)
