import {Collapse, createStyles, Divider, Grid, Theme, Typography, WithStyles, withStyles} from '@material-ui/core'
import React from 'react'
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic'
import {withRouter} from 'react-router-dom'

import {
    getActivityActualsByActivityID,
    getActivityByID,
    getActivityReporters
} from '../../services/addenda-activity.service'
import {
    Notification,
    NotificationInterface,
    withNotificationContext
} from '../../services/ContextService/NotificationService/NotificationContextService'
import {Activity, ActivityReporter, Addendum, Participant} from '../../services/model'
import {getParticipantsByActivityID} from '../../services/participants.service'
import {getAddendum} from '../../services/partnership-addenda.service'
import {genericReturnWithLogger, nullableReturn, simpleErrorLogger} from '../../utils/errorHandlers'
import ActivityDetailsHeader from '../ActivityDetailsHeader/ActivityDetailsHeader'
import Outcomes from '../Outcomes/Outcomes'
import ActivityParticipantsTable from '../Tables/ActivityParticipantsTable/ActivityParticipantsTable'
import ActivityReportersTable from '../Tables/ActivityReportersTable/ActivityReportersTable'

interface ActivityDetailsProps extends WithStyles<typeof styles> {
    activityID: string
    addendumID: string
    partnershipID: string
    appContext: NotificationInterface
}

interface ActivityDetailsState {
    participantsList: Participant[]
    activity?: Activity
    addendum?: Addendum
    reporters?: ActivityReporter[]
    loading: boolean
    openErollmentCSVDialog: boolean
    openOutcomeCSVDialog: boolean
    enrollmentCSVData: any[]
    outcomeCsvData: any[]
    outcomeActuals: any[]
}

const INITIAL_STATE: ActivityDetailsState = {
    participantsList: [],
    loading: false,
    openErollmentCSVDialog: false,
    openOutcomeCSVDialog: false,
    enrollmentCSVData: [],
    outcomeCsvData: [],
    outcomeActuals: [],
}

const PARTICIPANTS_TABLE_HEADERS = [
    {
        label: 'Name',
        prop: 'lastName',
    },
    {
        label: 'Date Enrolled',
        prop: 'enrollDate',
    },
    {
        label: 'Status',
        prop: 'status',
    },
    {
      label: 'Quest',
      prop: 'quest',
    },
    {
        label: 'Last Updated',
        prop: 'lastUpdatedDate',
    },
]

export class ActivityDetails extends React.Component<ActivityDetailsProps, ActivityDetailsState> {
    public readonly state: ActivityDetailsState = INITIAL_STATE

    // Enables page spinner
    startLoader = () => {
        this.setState({
            loading: true,
        })
    }

    // Disables page spinner
    endLoader = () => {
        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 100)
    }

    async componentDidMount() {
        this.startLoader()
        await this.fetchInitialData().catch(simpleErrorLogger)
        this.endLoader()
    }

    // Fetches activity, addendum, participant, outcome, and reporter data from API server
    fetchInitialData = async () => {
        const {activityID, addendumID, partnershipID} = this.props

        const activity = await getActivityByID(partnershipID, addendumID, activityID).catch(nullableReturn)
        const addendum = await getAddendum(partnershipID, addendumID).catch(nullableReturn)
        const outcomeActualData = await getActivityActualsByActivityID(partnershipID, addendumID, activityID).catch(genericReturnWithLogger([]))
        const participants = await getParticipantsByActivityID(partnershipID, addendumID, activityID).catch(genericReturnWithLogger([]))
        const reporters = await getActivityReporters(partnershipID, addendumID, activityID).catch(genericReturnWithLogger([]))
        this.setState((prevState: ActivityDetailsState) => {
            return {
                ...prevState,
                activity: activity || prevState.activity,
                addendum: addendum || prevState.addendum,
                participantsList: participants,
                outcomeActuals: outcomeActualData,
                reporters,
            }
        })
    }

    setNotification = (notification: Notification) => {
        setTimeout(() => {
            this.props.appContext.handleNotification(notification)
        }, 300)
    }

    onGeneralCsvError = (error: any) => {
        this.setNotification({
            type: 'fail',
            message: 'Unable to upload the selected CSV',
        })
    }

    onReportersChange = async () => {
        const {activityID, addendumID, partnershipID} = this.props
        const reporters = await getActivityReporters(partnershipID, addendumID, activityID).catch(genericReturnWithLogger([]))

        this.setState({
            reporters,
        })
    }

    render() {
        const {classes, partnershipID, addendumID} = this.props
        const {activity, addendum, loading, participantsList, reporters} = this.state

        let participantURL = ''
        if (activity) {
            participantURL = `/partnerships/${partnershipID}/addenda/${addendumID}/activities/${activity.id}/participants`
        }

        return (
            <div className={classes.root} data-auto="activity-wrapper">
                <Collapse in={!loading} timeout={{enter: 600}}>
                    {addendum && activity && participantsList && reporters && (
                        <React.Fragment>
                            <BreadcrumbsItem
                                to={`/partnerships/${partnershipID}/addenda/${addendumID}`}>{addendum.name}</BreadcrumbsItem>

                            <div className={classes.activitySection} data-auto="activity-details-header">
                                <BreadcrumbsItem
                                    to={`/partnerships/${partnershipID}/addenda/${addendumID}/activities/${activity.id}`}>{activity.name}</BreadcrumbsItem>
                                <ActivityDetailsHeader activity={activity} addendumId={addendumID}
                                                       partnershipId={partnershipID}/>
                            </div>

                            <Divider className={classes.divider}/>
                            <Grid container={true} justify="space-between">
                                <Grid item={true} xs={10}>
                                    <div className={classes.headerContainer}>
                                        <Typography variant="h5" component="h1" data-auto="activity-targets-title"
                                                    style={{display: 'inline-block'}}>
                                            Targets:
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>

                            <div className={classes.activitySection} data-auto="activity-details-outcomes">
                                <Outcomes activityID={activity.id} addendumID={addendumID} partnershipID={partnershipID}
                                          outcomes={this.state.outcomeActuals}/>
                            </div>

                            <Divider className={classes.divider}/>

                            <div className={classes.activitySection} data-auto="activity-details-participants">
                                <section className={classes.root} data-auto="activity-participants">
                                    <Grid container={true} justify="space-between">
                                        <Grid item={true} xs={12}>
                                            <div className={classes.headerContainer}>
                                                <Typography variant="h5" component="h1"
                                                            data-auto="activity-participants-title"
                                                            style={{display: 'inline-block'}}>
                                                    Participants:
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <ActivityParticipantsTable
                                                participants={this.state.participantsList}
                                                headers={PARTICIPANTS_TABLE_HEADERS}
                                                participantUrl={participantURL}
                                            />
                                        </Grid>
                                    </Grid>
                                </section>
                            </div>

                            <Divider className={classes.divider}/>

                            <div className={classes.activitySection} data-auto="activity-details-contacts">
                                <div className={classes.activityContacts}>
                                    <ActivityReportersTable reporters={reporters}
                                                            onReportersChange={this.onReportersChange}/>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Collapse>
            </div>
        )
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        activitySection: {},
        activityContacts: {},
        divider: {
            margin: `${theme.spacing.unit * 2}px 0`,
        },
        headerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            margin: `0 ${theme.spacing.unit}px`,
        },
    })
const styledOutcomes = withStyles(styles)(ActivityDetails)
export default withRouter(withNotificationContext(styledOutcomes))
