import { createStyles, IconButton, Snackbar, SnackbarContent, Theme, WithStyles, withStyles } from '@material-ui/core'
import { amber, green, red } from '@material-ui/core/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import * as React from 'react'

import { Notification } from '../../../services/ContextService/NotificationService/NotificationContextService'

interface SnackbarNotificationProps extends WithStyles<typeof styles> {
	notification: Notification
	isOpen: boolean
	handleClose: () => void
}

const variantIcon = {
	success: CheckCircleIcon,
	warn: WarningIcon,
	fail: ErrorIcon,
}

const SnackbarNotification: React.SFC<SnackbarNotificationProps> = (props: SnackbarNotificationProps) => {
	const { classes, isOpen, handleClose, notification } = props
	const { type, message } = notification
	const Icon = variantIcon[type]

	return (
		<div className={classes.root} data-auto="snackbar-wrapper">
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={isOpen}
				autoHideDuration={2000}
				onClose={handleClose}
			>
				<SnackbarContent
					className={classes[type]}
					message={
						<span id="message-id" data-auto="snackbar-message">
							<Icon className={classes.icon} />
							{message}
						</span>
					}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={handleClose}
							data-auto="close-button"
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>
			</Snackbar>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.grey[50],
		},
		close: {},
		fail: {
			backgroundColor: red[600],
		},
		success: {
			backgroundColor: green[600],
		},
		warn: {
			backgroundColor: amber[700],
		},
		icon: {
			fontSize: theme.typography.fontSize,
			marginRight: theme.spacing.unit,
			lineHeight: theme.spacing.unit,
		},
	})

export default withStyles(styles)(SnackbarNotification)
