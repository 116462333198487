import gPhoneUtil from "google-libphonenumber"
import moment from "moment"
import {BinaryChoice} from '../services/model'

const phoneUtil = gPhoneUtil.PhoneNumberUtil.getInstance()
const PNF = gPhoneUtil.PhoneNumberFormat

export function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  return match ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : phoneNumberString
}

export function formatDate(date: Date): string {
	return moment(date).format('L')
}

export const phoneNumberFormatter = (phoneNumber: string) => {
	return phoneUtil.format(phoneUtil.parseAndKeepRawInput(phoneNumber, 'US'), PNF.NATIONAL)
}

export const capitalize = (text: string = '') => {
	return text ? text.toLowerCase()
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ') : ''
}

export const mapUndefinedToBinaryChoice = (boolItem?: boolean) => {
	if(boolItem === undefined || boolItem === null){
		return '';
	}
	return boolItem ? BinaryChoice.YES : BinaryChoice.NO
}

export const nullifyEmptyString = (prop: any, key: any, obj: any) => (prop === '' ? null : prop)
