import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, WithStyles, withStyles } from '@material-ui/core'
import React from 'react'

import { Header, Main } from './layout/index'
import { NavigationLink } from './NavBar/NavBar'
import SnackbarNotification from './templates/SnackbarNotification/SnackbarNotification'
// TODO: Not sure why but the linter is not succeeding for any order of imports here
// tslint:disable
import { Notification, NotificationProvider } from '../services/ContextService/NotificationService/NotificationContextService'
// tslint:enable
import { isIE } from '../utils/utils'
import RealLogos from './REALLogos/RealLogos'

interface BootstrapProps extends WithStyles<typeof styles> {}
interface BootstrapState {
	isNotificationOpen: boolean
	notification?: Notification
	confirmedWarningDialog: boolean
}

export const NAV_LINKS: NavigationLink[] = [{ title: 'Partnerships', url: '/partnerships' }, { title: 'Participants', url: '/participants' }]

class Bootstrap extends React.Component<BootstrapProps, BootstrapState> {
	state: BootstrapState = {
		isNotificationOpen: false,
		confirmedWarningDialog: false,
	}

	handleNotification = (notification: Notification) => {
		this.setState({ notification, isNotificationOpen: true })
	}

	handleNotificationClose = () => {
		this.setState({ isNotificationOpen: false })
	}

	public render() {
		const { classes } = this.props
		const { isNotificationOpen, notification, confirmedWarningDialog } = this.state

		return (
			<div className={classes.root}>
				<div className={classes.siteContent}>
					<Header navLinks={NAV_LINKS} />
					<NotificationProvider value={{ handleNotification: this.handleNotification }}>
						<Main />
						{notification && <SnackbarNotification notification={notification} isOpen={isNotificationOpen} handleClose={this.handleNotificationClose} />}
					</NotificationProvider>
				</div>
				<div>
					<Dialog
						open={isIE && !confirmedWarningDialog}
						keepMounted={true}
						aria-labelledby="alert-dialog-slide-title"
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle id="alert-dialog-slide-title">Older Browser Detected</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								We have detected that you are using an older browser. As a result, you may encounter issues on this site, or may have difficulty with some of
								the site features. Please consider using a newer browser such as Chrome, Firefox, or Microsoft Edge.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									this.setState({ confirmedWarningDialog: true })
								}}
								color="primary"
							>
								OK
							</Button>
						</DialogActions>
					</Dialog>
				</div>
				<footer className={classes.footer}>
					<RealLogos />
				</footer>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			minHeight: '100vh',
			flexDirection: 'column',
		},
		siteContent: {
			flex: '1 0 0',
			flexGrow: 1,
		},
		footer: {
			display: 'flex',
			justifyContent: 'center',
		},
	})

export default withStyles(styles)(Bootstrap)