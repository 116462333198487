import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { createStyles, IconButton, TableCell, TableRow, Theme, Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core/'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SendIcon from '@material-ui/icons/Send'

import { deleteActivityReporter, sendEmailNotification } from '../../../services/addenda-activity.service'
import { ActivityReporter, ActivityReporterData } from '../../../services/model'
import AddReporter from '../../Forms/AddReporter/AddReporter'
import ConfirmAlertDialog from '../ConfirmAlertDialog/ConfirmAlertDialog'

interface ActivityReporterItemProps extends WithStyles<typeof styles> {
	contactDetails: ActivityReporter
	onDelete: () => void
	onNotify: (success: boolean) => void
	onEdit: (reporter: ActivityReporter) => void
}

interface ActivityReporterItemState {
	editing: boolean
	name?: string
	email: string
	openDeleteDialog: boolean
	openNotifyDialog: boolean
}

export type RouterProps = ActivityReporterItemProps & RouteComponentProps<{}> & WithStyles<{}>

const EM_REMINDER_MSG =
	'Are you sure you want to send this notification? The message will notify the selected partnership ' +
	'contact that enrollment targets have not been met and provide them with instructions on how to access the Self-Enrollment ' +
	'Site and Enrollment Manager Console.'

const setInitialState = (props: ActivityReporterItemProps): ActivityReporterItemState => {
	return {
		editing: false,
		email: props.contactDetails.email,
		name: props.contactDetails.name,
		openDeleteDialog: false,
		openNotifyDialog: false,
	}
}

export class ActivityReporterItem extends React.Component<RouterProps, ActivityReporterItemState> {
	public readonly state: ActivityReporterItemState = setInitialState(this.props)

	handleOnDelete = () => {
		this.setState({ openDeleteDialog: true })
	}

	handleOnEdit = () => {
		this.setState({ editing: !this.state.editing })
	}

	onSave = (reporter: ActivityReporter | ActivityReporterData) => {
		const localReporter = Object.assign({}, this.props.contactDetails, reporter)

		this.props.onEdit(localReporter)
		this.setState({ editing: !this.state.editing })
	}

	onCancel = () => {
		this.setState({ editing: !this.state.editing })
	}

	handleDeleteSubmit = async () => {
		try {
			await deleteActivityReporter(this.props.location.pathname, this.props.contactDetails.id)
			this.props.onDelete()
		} catch (e) {
			console.log(e)
		}

		this.closeDialog('openDeleteDialog')
	}

	handleNotifySubmit = async () => {
		const pathValues = this.props.location.pathname.split('/')
		const success = await sendEmailNotification(pathValues[2], pathValues[4], pathValues[6], this.props.contactDetails.id)
		this.closeDialog('openNotifyDialog')
		this.props.onNotify(success)
	}

	handleDeleteCancel = () => {
		this.closeDialog('openDeleteDialog')
	}

	handleNotifyCancel = () => {
		this.closeDialog('openNotifyDialog')
	}

	closeDialog = (stateProp: 'openDeleteDialog' | 'openNotifyDialog') => {
		const tempStateObj = {}
		tempStateObj[stateProp] = false

		this.setState(tempStateObj)
	}

	handleNotify = () => {
		this.setState({ openNotifyDialog: true })
	}

	componentDidUpdate(prevProps: ActivityReporterItemProps) {
		if (prevProps.contactDetails !== this.props.contactDetails) {
			const { email, name } = this.props.contactDetails
			this.setState({ email, name })
		}
	}

	render() {
		const { classes } = this.props
		const { editing, openDeleteDialog, openNotifyDialog, ...reporter } = this.state

		return (
			<React.Fragment>
				{editing ? (
					<AddReporter currentReporter={this.props.contactDetails} onCancel={this.onCancel} onSubmit={this.onSave} />
				) : (
					<TableRow component="div" hover={true}>
						<TableCell component="div" variant="body" style={{ width: '35%' }}>
							<Typography variant="subtitle2" color="textPrimary" data-auto="reporter-name">
								{reporter.name}
							</Typography>
						</TableCell>
						<TableCell component="div" variant="body" style={{ width: '50%' }}>
							<a href={`mailto:${reporter.email}`} data-rel="external" style={{ textDecoration: 'none' }}>
								<Tooltip title="Send Email" placement="bottom-start">
									<Typography variant="body1" color="textPrimary" data-auto="reporter-email" aria-label="reporter-email" className={classes.emailLink}>
										{reporter.email}
									</Typography>
								</Tooltip>
							</a>
						</TableCell>
						<TableCell component="div" variant="body">
							<div className={classes.actionContainer}>
								<Tooltip title="Send Notification">
									<IconButton className={classes.button} aria-label="Notify" color="default" data-auto="button-notify" onClick={this.handleNotify}>
										<SendIcon className={classes.icon} color="inherit" />
									</IconButton>
								</Tooltip>
								<Tooltip title="Edit">
									<IconButton className={classes.button} aria-label="Edit" color="default" onClick={this.handleOnEdit} data-auto="button-edit">
										<EditIcon className={classes.icon} color="inherit" />
									</IconButton>
								</Tooltip>
								<Tooltip title="Delete">
									<IconButton
										className={`${classes.button} ${classes.deleteIcon} `}
										aria-label="Delete"
										color="default"
										onClick={this.handleOnDelete}
										data-auto="button-delete"
									>
										<DeleteIcon className={`${classes.icon}`} color="inherit" />
									</IconButton>
								</Tooltip>
							</div>
						</TableCell>
					</TableRow>
				)}
				<ConfirmAlertDialog
					open={openDeleteDialog}
					handleConfirm={this.handleDeleteSubmit}
					handleCancel={this.handleDeleteCancel}
					dialogTitle="Confirm Delete"
					dialogContent="Are you sure you want to Delete this record?"
				/>

				<ConfirmAlertDialog
					open={openNotifyDialog}
					handleConfirm={this.handleNotifySubmit}
					handleCancel={this.handleNotifyCancel}
					dialogTitle="Confirm Send Notification"
					dialogContent={EM_REMINDER_MSG}
				/>
			</React.Fragment>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		row: {},
		actionContainer: {
			display: 'flex',
			flexDirection: 'row',
		},
		emailLink: {
			'&:hover': {
				color: theme.palette.primary.main,
			},
		},
		button: {
			textTransform: 'none',

			'&:hover, &:active': {
				color: theme.palette.primary.main,
				transition: 'all 0.5s ease',
			},
		},
		icon: {
			fontSize: theme.typography.fontSize * 1.5,
		},
		deleteIcon: {
			'&:hover, &:active': {
				color: theme.palette.secondary.main,
				transition: 'all 0.5s ease',
			},
		},
	})

export const StyledReporterItem = withStyles(styles)(ActivityReporterItem)
export default withRouter(StyledReporterItem)
