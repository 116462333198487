import { CircularProgress, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

interface CustomLoaderProps extends WithStyles<typeof styles> {}

const CustomLoader: React.SFC<CustomLoaderProps> = (props: CustomLoaderProps) => {
	const { classes } = props

	return (
		<div className={classes.loaderContainer}>
			<CircularProgress className={classes.progress} size={50} />
			<Typography align="center" variant="h6">
				Loading ...
			</Typography>
		</div>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		loaderContainer: {
			alignSelf: 'center',
			justifySelf: 'center',
			top: '40vh',
			left: '47.5vw',
			position: 'fixed',
		},
		progress: {
			marginLeft: '20%',
		},
	})

export default withStyles(styles)(CustomLoader)
