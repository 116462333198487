import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service'
import { Partner, PartnerData } from './model'

const { REACT_APP_HOSTNAME } = process.env
export const PARTNERS_URL = `${REACT_APP_HOSTNAME}/partners`

export async function getAllPartners(): Promise<Partner[]> {
	const { data, status } = await axios.get('/', {
		baseURL: PARTNERS_URL,
	})

	authorizationCheck(status)

	return data
}

export async function getPartnerByID(id: string): Promise<Partner> {
	const { data, status } = await axios.get(`/${id}`, {
		baseURL: PARTNERS_URL,
	})

	authorizationCheck(status)

	return data
}

export async function deletePartnerByID(id: string): Promise<{ status: number }> {
	return await axios.delete(`/${id}`, {
		baseURL: PARTNERS_URL,
	})
}

export async function createNewPartner(partnerDetails: PartnerData): Promise<Partner> {
	const { data, status } = await axios.post(`/`, partnerDetails)

	authorizationCheck(status)

	return data
}


export async function updatePartnerByID(newPartnerDetails: Partner): Promise<Partner> {
  const { data, status } = await axios.put(`/${newPartnerDetails.id}`, newPartnerDetails, {
    baseURL: PARTNERS_URL,
  })

	authorizationCheck(status)

	return data
}
