import { Card, CardContent, CardHeader, Modal } from '@material-ui/core/'
import { blue } from '@material-ui/core/colors'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import React from 'react'

import { Notification, NotificationInterface, withNotificationContext } from '../../../services/ContextService/NotificationService/NotificationContextService'
import { FullParticipantData, Participant } from '../../../services/model'
import { updateParticipant } from '../../../services/participants.service'
import EditParticipantForm from '../../Forms/EditParticipantForm/EditParticipantForm'

interface ParticipantModalProps extends WithStyles<typeof styles> {
	openModal: boolean
	handleClose: () => void
	handleSubmit: (participant: Participant) => void
	headerTitle: string
	participant: FullParticipantData
	appContext: NotificationInterface
}

interface ParticipantModalState {
	localParticipant: FullParticipantData
}

interface LocalNotifications {
	success: Notification
	fail: Notification
}

const NOTIFICATION: LocalNotifications = {
	success: { type: 'success', message: 'Participant updated successfully.' },
	fail: { type: 'fail', message: 'Something went wrong, please try again.' },
}

const setInitialState = (props: ParticipantModalProps): ParticipantModalState => {
	return {
		localParticipant: props.participant,
	}
}

export class EditParticipantModal extends React.Component<ParticipantModalProps, ParticipantModalState> {
	public readonly state: ParticipantModalState = setInitialState(this.props)

	handleFormSubmit = async (editedParticipant: Participant) => {
		if (editedParticipant !== this.props.participant) {
			try {
				const updatedParticipant: Participant = await updateParticipant(editedParticipant)

				this.props.handleSubmit(updatedParticipant)
				setTimeout(() => {
					this.props.appContext.handleNotification(NOTIFICATION.success)
				}, 300)
			} catch (e) {
				setTimeout(() => {
					this.props.appContext.handleNotification(NOTIFICATION.fail)
				}, 300)
				this.props.handleClose()
			}
		}
	}

	handleFormCancel = () => {
		this.props.handleClose()
	}

	componentWillReceiveProps(nextProps: ParticipantModalProps) {
		if (nextProps.participant !== this.state.localParticipant) {
			this.setState({ localParticipant: nextProps.participant })
		}
	}

	render() {
		const { classes, openModal, handleClose, headerTitle } = this.props
		const { localParticipant } = this.state

		return (
			<Modal
				aria-labelledby="edit-participant-modal"
				aria-describedby="Modal to edit participant information"
				open={openModal}
				onClose={handleClose}
				data-auto="edit-participant-modal"
			>
				<Card className={classes.modalContent} elevation={1}>
					<CardHeader
						title={`Editing ${headerTitle}`}
						className={classes.modalHeader}
						data-auto="edit-participant-modal-header"
						titleTypographyProps={{
							align: 'center',
							classes: { root: classes.modalTitle },
						}}
					/>
					<CardContent data-auto="edit-participant-modal-content">
						<EditParticipantForm participant={localParticipant} onCancel={this.handleFormCancel} onFormSubmit={this.handleFormSubmit} handleDialogOpen={this.handleFormCancel} />
					</CardContent>
				</Card>
			</Modal>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		modalContent: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			width: '70%',
			height: '80%',
			marginTop: '-40vh',
			marginLeft: '-35vw',
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			overflow: 'auto',

			[theme.breakpoints.down('sm')]: {
				width: '90%',
				height: '90%',
				marginTop: '-60%',
				marginLeft: '-45%',
				paddingBottom: theme.spacing.unit * 5,
			},
		},
		modalHeader: {
			backgroundColor: blue[900],
		},
		modalTitle: {
			color: 'white',
		},
	})

export default withStyles(styles)(withNotificationContext(EditParticipantModal))
