import * as R from 'ramda'
import { DateTarget, NumericTarget, StringTarget } from '../services/model/Addendum'

function uniqueTargetTypes(targets: Array<StringTarget | DateTarget | NumericTarget>) {
	const typeArray = targets.map((target) => target['@type'])

	return [...new Set(typeArray)]
}

function getTopLevelTargets(target: StringTarget | DateTarget | NumericTarget): boolean{
	return target.targetType === 'COMPLETED' || target.targetType === 'ENROLLED' || target.targetType === 'PLACED'
}

export const createTargetsMapper = (targets: Array<StringTarget | DateTarget | NumericTarget>): { [key: string]: any }=> {
	const types = uniqueTargetTypes(targets)
	const [topLevelTargets, expandableTargets] = R.partition(getTopLevelTargets, targets)
	const obj = {topLevelTargets}

	for (const t of types) {
		obj[t] = expandableTargets.filter((i) => i['@type'] === t)
	}

	return obj
}
