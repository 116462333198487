import axios from 'axios'

import { authorizationCheck } from './Auth/auth.service'
import { Participant, ParticipantActivity } from './model'
import {EnrollParticipantRequest} from "./model/EnrollParticipantRequest";
import { PARTNERSHIPS_URL } from './partnerships.service'

const { REACT_APP_HOSTNAME } = process.env
export const PARTICIPANTS_URL = `${REACT_APP_HOSTNAME}/participants`

export async function getParticipantsByActivityID(
  partnershipID: string, addendumID: string, activityID: string): Promise<Participant[]> {
  try {
		const { data } = await axios.get('/', {
			baseURL: `${PARTNERSHIPS_URL}/${partnershipID}/addenda/${addendumID}/activities/${activityID}/participants`,
		})

		return data
	}catch (e) {
		authorizationCheck(e.response.status)
    throw e
	}
}

export async function getParticipantByID(participantID: string): Promise<Participant> {
  try {
		const { data } = await axios.get('/', {
			baseURL: `${PARTICIPANTS_URL}/${participantID}`,
		})

		return data
	}catch (e) {
		authorizationCheck(e.response.status)
    return e
	}

}
export async function getParticipantActivities(participantID: string): Promise<ParticipantActivity[]> {
  try {
		const { data } = await axios.get('/', {
			baseURL: `${PARTICIPANTS_URL}/${participantID}/activities`,
		})

		return data
	}catch (e) {
    authorizationCheck(e.response.status)
		return e
	}
}
export async function getParticipantsByName( last: string, first?: string,): Promise<Participant[]> {

  try {
		const params = {
			firstName: first ? encodeURIComponent(first) : null,
			lastName: encodeURIComponent(last),
		}

		const { data } = await axios.get(PARTICIPANTS_URL, {params})

		return data
	}catch (e) {
		authorizationCheck(e.response.status)
		return e
	}
}

export async function updateParticipant(newParticipantDetails: Participant): Promise<Participant> {
  try {
		const { data, status } = await axios.put(`/${newParticipantDetails.id}`, newParticipantDetails, {
			baseURL: PARTICIPANTS_URL,
		})
		authorizationCheck(status)
		return data
	}catch (e) {
		throw e
	}
}

export async function createParticipant(newParticipantDetails: any): Promise<Participant> {
  try {
		const { data, status } = await axios.post(PARTICIPANTS_URL, newParticipantDetails)
		authorizationCheck(status)
		return data
	}catch (e) {
		throw e
	}
}

export async function enrollParticipant(enrollParticipantRequest: EnrollParticipantRequest): Promise<boolean> {
	try {
		const { data, status } = await axios.post(PARTICIPANTS_URL + '/enroll', enrollParticipantRequest)
		authorizationCheck(status)
		return data
	} catch (e) {
		throw e
	}
}
