import { Race } from '../model'

export enum BinaryChoice {
	YES = 'YES',
	NO = 'NO',
	NA = ''
}

export interface CheckboxItem extends RadioItem {
	checked: boolean
}

export interface RadioItem {
	label: string
	value: string | Race
}
