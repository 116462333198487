export interface Addendum {
    id: string
    name: string
    performanceEndDate: Date
    performanceStartDate: Date
}


export interface DateTarget extends Target{
	date: Date
}

export interface StringTarget extends Target{
	target: string
}

export interface NumericTarget extends Target{
	actual: number
	target: number
}

export interface Target {
	targetType: TargetType
}

export enum TargetType {
	ENROLLED = "ENROLLED",
	START_DATE = "START_DATE",
	END_DATE = "END_DATE",
	COMPLETED = "COMPLETED",
	PERFORMANCE_DETAILS = "PERFORMANCE_DETAILS",
	PLACEMENT_EMPLOYERS = "PLACEMENT_EMPLOYERS",
	TRAINING_PROVIDERS = "TRAINING_PROVIDERS",
	PLACEMENT_OCCUPATION = "PLACEMENT_OCCUPATION",
	CERTIFICATES = "CERTIFICATES",
	TRAINING_LOCATION = "TRAINING_LOCATION",
	SECTORS = "SECTORS",
	FUNDING_SOURCE = "FUNDING_SOURCE",
	WAGE = "WAGE",
	PLACED = "PLACED",
	PLACEMENT_TIMEFRAME = "PLACEMENT_TIMEFRAME",
	TRAINING_HOURS = "TRAINING_HOURS",
	PLACEMENT_GROUP = "PLACEMENT_GROUP", 
	INTERNSHIP_EMPLOYERS = "INTERNSHIP_EMPLOYERS", 
	INTERNSHIP_OCCUPATION = "INTERNSHIP_OCCUPATION", 
	SERVED_EMPLOYERS = "SERVED_EMPLOYERS", 
	POSITIONS_BACKFILLED = "POSITIONS_BACKFILLED"
}

export const TargetTypeMapper = new Map<TargetType, string>([
	[TargetType.ENROLLED, 'Enrolled'],
	[TargetType.START_DATE, 'Start Date'],
	[TargetType.END_DATE, 'End Date'],
	[TargetType.COMPLETED, 'Completed'],
	[TargetType.PERFORMANCE_DETAILS, 'Performance Details'],
	[TargetType.PLACEMENT_EMPLOYERS, 'Placement Employers'],
	[TargetType.TRAINING_PROVIDERS, 'Training Providers'],
	[TargetType.PLACEMENT_OCCUPATION, 'Placement Occupations'],
	[TargetType.CERTIFICATES, 'Certificates'],
	[TargetType.TRAINING_LOCATION, 'Training Location'],
	[TargetType.SECTORS, 'Population Sectors'],
	[TargetType.FUNDING_SOURCE, 'Funding Source'],
	[TargetType.WAGE, 'Wage'],
	[TargetType.PLACED, 'Placed'],
	[TargetType.PLACEMENT_TIMEFRAME, 'Placement Timeframe'],
	[TargetType.TRAINING_HOURS, 'Training Hours'],
	[TargetType.PLACEMENT_GROUP, 'Placement Group'],
	[TargetType.INTERNSHIP_EMPLOYERS, 'Internship Employers'],
	[TargetType.INTERNSHIP_OCCUPATION, 'Internship Occupations'],
	[TargetType.SERVED_EMPLOYERS, 'Served Employers'],
	[TargetType.POSITIONS_BACKFILLED, 'Positions Backfilled']
])