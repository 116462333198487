import {
	Button,
	createStyles,
	FormControl,
	Grid,
	Input,
	InputLabel,
	Theme,
	WithStyles,
	withStyles
} from '@material-ui/core'
import {CloudDownload, CloudUpload} from '@material-ui/icons'
import moment from 'moment'
import Papa from 'papaparse'
import React from 'react'
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic'
import {withRouter} from 'react-router-dom'
import {exampleParticipantCsv} from 'src/utils/participantFormConstants'
import {
	createOpcOutcome,
	getBulkActivityParticipantType,
	updateActivityParticipantType
} from '../../services/activity-participant.service'
import {getBulkActivityType} from "../../services/activity.service";
import {
	Notification,
	NotificationInterface,
	withNotificationContext
} from '../../services/ContextService/NotificationService/NotificationContextService'
import {BulkActivityParticipantTypes, Partnership, ProgramTypeMapper} from '../../services/model'
import {BulkActivityTypes} from "../../services/model/Participant";
import {enrollParticipant} from "../../services/participants.service";
import {getAllPartnerships, getPartnershipsByName} from '../../services/partnerships.service'
import {nullOutBlankFieldsOrTrim} from '../../utils/csvUtil'
import {simpleErrorLogger} from '../../utils/errorHandlers'
import {exampleOpcOutcomeCsv, exampleOutcomeCsv, outcomeFormConstants} from '../../utils/outcomeFormConstants'
import {getSorting, Sort, stableSort} from '../../utils/sortingService'
import TableWrapper from '../Tables/TableWrapper'
import TypographyHeader from '../templates/TypographyHeader/TypographyHeader'
import allPartershipHelpers from './allPartershipHelpers'
import SearchPartnershipsForm from "../Forms/SearchPartnershipsForm/SearchPartnershipsForm"

// import {createParticipants} from "../../services/participants.service";

export interface AllPartnershipsProps extends WithStyles<typeof styles> {
	appContext: NotificationInterface
}

interface AllPartnershipsState {
	loading: boolean
	partnershipsList: Partnership[]
	orderBy: string
	order: Sort
	fetchingPartnership: boolean,
	filteredPartnerShips: Partnership[]
}

export interface HeaderItem {
	label: string
	prop: string
}

const TABLE_HEADERS: HeaderItem[] = [
	{ label: 'Name', prop: 'name' },
  { label: 'Partner Name', prop: 'leadApplicantName' },
	{ label: 'Program Type', prop: 'programType' },
	{ label: 'Effective Date', prop: 'effectiveDate' },
	{ label: 'End Date', prop: 'endDate' },
]

const INITIAL_STATE: AllPartnershipsState = {
	loading: true,
	partnershipsList: [],
	orderBy: 'name',
	order: 'asc',
	fetchingPartnership: false,
	filteredPartnerShips: []
}

function mapCustomPartnerships(partnershipsList: Partnership[]): Partnership[] {
	return partnershipsList.map((partnership) => Object.assign({ name: `${partnership.name}` }, { ...partnership }))
}

export class AllPartnerships extends React.Component<AllPartnershipsProps, AllPartnershipsState> {
	public readonly state: AllPartnershipsState = INITIAL_STATE
	private fileInputRefOpc: React.RefObject<HTMLFormElement> = React.createRef()
	private fileInputRefOutcome: React.RefObject<HTMLFormElement> = React.createRef()
	private fileInputRefParticipant: React.RefObject<HTMLFormElement> = React.createRef()

	async componentDidMount() {
		await this.setInitialData()
	}

	setInitialData = async () => {
		await getAllPartnerships()
			.then((partnerships) => {
				this.setState({
					partnershipsList: partnerships,
					orderBy: TABLE_HEADERS[0].prop,
					loading: false,
					filteredPartnerShips: partnerships
				})
			})
			.catch((e) => {
				simpleErrorLogger(e)

				this.setState((prevState: AllPartnershipsState) => ({
					...prevState,
					loading: false,
				}))
			})
	}

	handleRequestSort = (currentOrder: string, sortLabel: string) => {
		this.setState({
			order: currentOrder === 'asc' ? 'desc' : 'asc',
			orderBy: sortLabel,
		})
	}

	terminateLoading = () => {
		setTimeout(() => {
			this.setState({
				loading: false,
			})
		}, 100)
	}

	populateFormItem = (item: Partnership) => {
		return {
			title: {
				label: item.name,
				url: `partnerships/${item.id}`,
			},
			content: [
        { isDate: false, value: item.leadApplicantName },
				{ isDate: false, value: ProgramTypeMapper.get(item.programType) },
				{ isDate: true, value: item.effectiveDate },
				{ isDate: true, value: item.endDate },
			],
		}
	}

	downloadErrorCsv = (data: any, name: string) => {

		data = data.map((row: any) => {
			const errors = row[outcomeFormConstants.ERROR_KEY];
			if (errors) {
				row[outcomeFormConstants.ERROR_KEY] = errors.join(', ');
			}
			return row;
		});

		this.downloadCsv(data, name);
	}

	downloadCsv = (data: any, name: string) => {

		const csv = Papa.unparse(data);
		const now = moment().unix().toString();

		const hiddenElement = document.createElement('a');
		hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
		hiddenElement.target = '_blank';
		hiddenElement.download = name + "_" + now + ".csv";
		hiddenElement.click();
	}

	uploadCsv = async (csv: any) => {

		const parser = new Promise(resolve => Papa.parse(csv, {
			header: true,
			complete(results) {
				resolve(results.data);
			},
			skipEmptyLines: true
		}))

		return await parser
	}

	downloadOutcomeCsvTemplate = async () => {
		this.downloadCsv(exampleOutcomeCsv, "outcome_template");
	}

	downloadOpcCsvTemplate = async () => {
		this.downloadCsv(exampleOpcOutcomeCsv, "opc_outcome_template");
	}

	downloadParticipantCsvTemplate = async () => {
		this.downloadCsv(exampleParticipantCsv, "participant_template");
	}

	uploadButtonOpc = () => {
		if (this.fileInputRefOpc && this.fileInputRefOpc.current) {
			this.fileInputRefOpc.current.click()
		}
	}

	uploadButtonOutcome = () => {
		if (this.fileInputRefOutcome && this.fileInputRefOutcome.current) {
			this.fileInputRefOutcome.current.click()
		}
	}

	uploadButtonParticipant = () => {
		if (this.fileInputRefParticipant && this.fileInputRefParticipant.current) {
			this.fileInputRefParticipant.current.click()
		}
	}

	validateParticipantCsv = async (csvData: any) => {

		const { cleanData, dirtyData } = csvData.map(nullOutBlankFieldsOrTrim)

			// Dedupe and validate Activity ID, First Name, Last Name, reduce to object keyed by Activity ID and First Last
		.reduce(allPartershipHelpers.reduceByActivityIdAndFirstLast, {
			cleanData: {},
			dirtyData: new Array<any>()
		});

		const activityIds = Object.keys(cleanData).map(val => parseInt(val, 10))
		let enrollParticipantRequests = new Array<any>();

		if (activityIds.length) {
			enrollParticipantRequests = await getBulkActivityType({activityIds})
				.then((bulkActivityTypes: BulkActivityTypes) => {

					bulkActivityTypes.notFound.forEach(allPartershipHelpers.sortActivityNotFound(cleanData, dirtyData));

					const validated = new Array<any>();

					bulkActivityTypes.found

						// Filter out disabled entries
						.filter(allPartershipHelpers.filterActivityDisabled(cleanData, dirtyData))

						// Validate remaining entries
						.forEach(allPartershipHelpers.participantValidate(cleanData, dirtyData, validated));

					return validated.map(value => allPartershipHelpers.buildEnrollParticipantRequest(value));
				});
		}

		return {
			requests: enrollParticipantRequests,
			dirty: dirtyData
		};
	}

	uploadParticipantCsv = async(e: React.FormEvent<HTMLDivElement>) => {

		const input = this.fileInputRefParticipant

		if (input && input.current && input.current.files) {
			const csv = input.current.files[0]
			const data = await this.uploadCsv(csv);
			const validated = await this.validateParticipantCsv(data)
			const ops: Array<Promise<boolean>> = []

			for (const request of validated.requests) {
				ops.push(enrollParticipant(request))
			}

			const totalCount = validated.requests.length + validated.dirty.length
			const uploadCount = validated.requests.length
			const errorCount = validated.dirty.length

			Promise.all(ops)
				.then((vals) => {
					let successCount = 0
					let failureCount = 0

					for (const v of vals) {
						if (v) {
							successCount++
						} else {
							failureCount++
						}
					}

					const notification: Notification = {
						type: 'success',
						message: 'Counted ' +
						totalCount + ' participant record(s),  uploaded ' +
						uploadCount + ', with ' +
						successCount + ' success(es) and ' +
						failureCount + ' failure(s). Please review ' +
						errorCount + ' error(s).',
					}

					this.setNotification(notification)
				})
				.catch(simpleErrorLogger)
				if (errorCount > 0) {
					this.downloadErrorCsv(validated.dirty, "participant_errors");
				}
		}
	}

	validateOpcCsv = async (csvData: any) => {

		const { cleanData, dirtyData } = csvData.map(nullOutBlankFieldsOrTrim)

		// Dedupe and validate Activity Participant ID, reduce to object keyed by Activity Participant ID
		.reduce(allPartershipHelpers.reduceByParticipantId, {
			cleanData: {},
			dirtyData: new Array<any>()
		});

		const foundCleanData = await getBulkActivityParticipantType({
			activityParticipantIds: Object.keys(cleanData).map(val => parseInt(val, 10))
		})
			.then((bulkActivityParticipantTypes: BulkActivityParticipantTypes) => {

				bulkActivityParticipantTypes.notFound.forEach(allPartershipHelpers.sortNotFound(cleanData, dirtyData));

				return bulkActivityParticipantTypes.found

					// Filter out disabled entries
					.filter(allPartershipHelpers.filterDisabled(cleanData, dirtyData))

					// Filter out invalid entries
					.filter(allPartershipHelpers.opcOutcomeValidate(cleanData, dirtyData))

					// Collect final list of valid entries for upload
					.map(allPartershipHelpers.buildOpcOutcomeRequest(cleanData));
			});

		return {
			clean: foundCleanData,
			dirty: dirtyData
		};
	}

	uploadOpcCsv = async(e: React.FormEvent<HTMLDivElement>) => {

		const input = this.fileInputRefOpc

		if (input && input.current && input.current.files) {

			const csv = input.current.files[0]

			const data = await this.uploadCsv(csv);

			const validated = await this.validateOpcCsv(data)

			const ops: Array<Promise<boolean>> = []

			for (const clean of validated.clean) {
				ops.push(createOpcOutcome(clean.activityParticipantId, clean.opcOutcomeRequest))
			}

			const totalCount = validated.clean.length + validated.dirty.length
			const uploadCount = validated.clean.length
			const errorCount = validated.dirty.length

			Promise.all(ops)
				.then((vals) => {
					let successCount = 0
					let failureCount = 0

					for (const v of vals) {
						if (v) {
							successCount++
						} else {
							failureCount++
						}
					}

					const notification: Notification = {
						type: 'success',
						message: 'Counted ' +
						totalCount + ' opc outcome record(s),  uploaded ' +
						uploadCount + ', with ' +
						successCount + ' success(es) and ' +
						failureCount + ' failure(s). Please review ' +
						errorCount + ' error(s).',
					}

					this.setNotification(notification)
				})
				.catch(simpleErrorLogger)
				if (errorCount > 0) {
					this.downloadErrorCsv(validated.dirty, "opc_outcome_errors");
				}
		}
	}

	validateOutcomeCsv = async (csvData: any) => {

		const { cleanData, dirtyData } = csvData.map(nullOutBlankFieldsOrTrim)
		// Dedupe and validate Activity Participant ID, reduce to object keyed by Activity Participant ID
		.reduce(allPartershipHelpers.reduceByParticipantId, {
			cleanData: {},
			dirtyData: new Array<any>()
		});

		const foundCleanData = await getBulkActivityParticipantType({
			activityParticipantIds: Object.keys(cleanData).map(val => parseInt(val, 10))
		})
		.then((bulkActivityParticipantTypes: BulkActivityParticipantTypes) => {
			bulkActivityParticipantTypes.notFound.forEach(allPartershipHelpers.sortNotFound(cleanData, dirtyData));
			return bulkActivityParticipantTypes.found
				// Filter out disabled entries
				.filter(allPartershipHelpers.filterDisabled(cleanData, dirtyData))
				// Filter out invalid entries
				.filter(allPartershipHelpers.outcomeValidate(cleanData, dirtyData))
				// Collect final list of valid entries for upload
				.map(allPartershipHelpers.buildUpdateOutcomeRequest(cleanData));
		});

    return {
			clean: foundCleanData,
			dirty: dirtyData
		};
	}

	uploadOutcomeCsv = async(e: React.FormEvent<HTMLDivElement>) => {

		const input = this.fileInputRefOutcome

		if (input && input.current && input.current.files) {
			const csv = input.current.files[0]
			const data = await this.uploadCsv(csv);
			if (data) {
				const validated = await this.validateOutcomeCsv(data);
				const ops: Array<Promise<boolean>> = []

				for (const clean of validated.clean) {
					ops.push(updateActivityParticipantType(clean.activityParticipantId, clean.updateOutcomeRequest));
				}

				const totalCount = validated.clean.length + validated.dirty.length
				const uploadCount = validated.clean.length
				const errorCount = validated.dirty.length

				Promise.all(ops)
					.then((vals) => {
						let successCount = 0
						let failureCount = 0

						for (const v of vals) {
							if (v) {
								successCount++
							} else {
								failureCount++
							}
						}

						const notification: Notification = {
							type: 'success',
							message: 'Counted ' +
							totalCount + ' outcome record(s),  uploaded ' +
							uploadCount + ', with ' +
							successCount + ' success(es) and ' +
							failureCount + ' failure(s). Please review ' +
							errorCount + ' error(s).',
						}

						this.setNotification(notification)
					})
					.catch(simpleErrorLogger)
					if (errorCount > 0) {
						this.downloadErrorCsv(validated.dirty, "outcome_errors");
					}
			}
		}
	}

	setNotification = (notification: Notification) => {
		setTimeout(() => {
			this.props.appContext.handleNotification(notification)
		}, 300)
	}

	setParticipants = (partnershipsArray: Partnership[]) => {
		this.setState({ partnershipsList: mapCustomPartnerships(partnershipsArray), fetchingPartnership: false })
	}

	initiateSearchState = () => {
		this.setState({
			fetchingPartnership: true,
			loading: false,
		})
	}

	performSearch = async (firstName: string) => {
		await setTimeout(async () => {
			try {
				const filteredParticipants: Partnership[] = await getPartnershipsByName(firstName)
				this.setParticipants(filteredParticipants)
			} catch (e) {
				this.setState({
					fetchingPartnership: false,
					loading: true,
				})

				return e
			}
		}, 300)
	}

	onSearch = (firstName: string) => {
		this.initiateSearchState()
		let filteredPartnerships = JSON.parse(JSON.stringify(this.state.partnershipsList))
		if (firstName && firstName.trim().length > 0) {
			filteredPartnerships = this.state.partnershipsList.filter((partnership) => partnership.name.toLowerCase().includes(firstName.trim().toLowerCase()))
		}
    filteredPartnerships = mapCustomPartnerships(filteredPartnerships)
		this.setState((state) => {
			return {
				...state,
				fetchingPartnership: false,
				filteredPartnerShips: filteredPartnerships
			}
		})
		/* this.performSearch(firstName ).then(() => {
			setTimeout(() => {
				this.setState(() => ({ loading: true }))
			}, 500)
		}) */
	}

	render() {
		const { classes } = this.props
		const { loading, order, orderBy, fetchingPartnership, filteredPartnerShips } = this.state
		const sortedPartnerships = stableSort(filteredPartnerShips, getSorting(order, orderBy)).map(this.populateFormItem)

		return (
			<div className={classes.root}>
				<BreadcrumbsItem to={'/partnerships'}>Partnerships</BreadcrumbsItem>
    

				<Grid container={true} justify="space-between">
					<Grid item={true} xs={6}>
						<TypographyHeader label={'Partnerships'} type="title" />
					<SearchPartnershipsForm onPartnershipsSearch={this.onSearch} searching={fetchingPartnership} />
					</Grid>
					<Grid item={true} xs={6}>
							<Grid container={true}>
								<Grid item={true} xs={6}>
									<div className={classes.buttonContainerLeft}>
										<Button
											className={classes.button}
											variant="contained"
											color="default"
											onClick={this.downloadOutcomeCsvTemplate}
											data-auto="outcome-download-button">
											<CloudDownload className={classes.buttonIcon} />
											Download Outcome CSV Template
										</Button>
									</div>
								</Grid>
								<Grid item={true} xs={6}>
									<div className={classes.buttonContainer}>
										<FormControl onChange={this.uploadOutcomeCsv} className={classes.buttonForm}>
											<InputLabel htmlFor="outcome-csv" style={{ display: 'none' }}>
												Outcome CSV
												<Input
													id="outcome-csv"
													aria-describedby="outcome-csv-upload"
													inputProps={{
														type: 'file',
														accept: 'text/csv',
														style: { display: 'none' },
														placeholder: 'OUTCOME CSV',
													}}
													value=""
													inputRef={this.fileInputRefOutcome}
												/>
											</InputLabel>

											<Button
												className={classes.button}
												variant="contained"
												color="default"
												onClick={this.uploadButtonOutcome}
												data-auto="outcome-upload-button">
												<CloudUpload className={classes.buttonIcon} />
												Upload Outcome CSV
											</Button>
										</FormControl>
									</div>
								</Grid>
							</Grid>
							<Grid container={true}>
								<Grid item={true} xs={6}>
									<div className={classes.buttonContainerLeft}>
										<Button
											className={classes.button}
											variant="contained"
											color="default"
											onClick={this.downloadOpcCsvTemplate}
											data-auto="opc-outcome-download-button">
											<CloudDownload className={classes.buttonIcon} />
											Download OPC Outcome CSV Template
										</Button>
									</div>
								</Grid>
								<Grid item={true} xs={6}>
									<div className={classes.buttonContainer}>
										<FormControl onChange={this.uploadOpcCsv} className={classes.buttonForm}>
											<InputLabel htmlFor="opc-outcome-csv" style={{ display: 'none' }}>
												OPC CSV
												<Input
													id="opc-outcome-csv"
													aria-describedby="opc-outcome-csv-upload"
													inputProps={{
														type: 'file',
														accept: 'text/csv',
														style: { display: 'none' },
														placeholder: 'OPC CSV',
													}}
													value=""
													inputRef={this.fileInputRefOpc}
												/>
											</InputLabel>

											<Button
												className={classes.button}
												variant="contained"
												color="default"
												onClick={this.uploadButtonOpc}
												data-auto="opc-outcome-upload-button">
												<CloudUpload className={classes.buttonIcon} />
												Upload OPC Outcome CSV
											</Button>
										</FormControl>
									</div>
								</Grid>
							</Grid>
							<Grid container={true}>
								<Grid item={true} xs={6}>
									<div className={classes.buttonContainerLeft}>
										<Button
											className={classes.button}
											variant="contained"
											color="default"
											onClick={this.downloadParticipantCsvTemplate}
											data-auto="participant-template-download-button">
											<CloudDownload className={classes.buttonIcon} />
											Download Participant CSV Template
										</Button>
									</div>
								</Grid>
								<Grid item={true} xs={6}>
									<div className={classes.buttonContainer}>
										<FormControl onChange={this.uploadParticipantCsv} className={classes.buttonForm}>
											<InputLabel htmlFor="participant-csv" style={{ display: 'none' }}>
												PARTICIPANT CSV
												<Input
													id="participant-csv"
													aria-describedby="participant-csv-upload"
													inputProps={{
														type: 'file',
														accept: 'text/csv',
														style: { display: 'none' },
														placeholder: 'PARTICIPANT CSV',
													}}
													value=""
													inputRef={this.fileInputRefParticipant}
												/>
											</InputLabel>

											<Button
												className={classes.button}
												variant="contained"
												color="default"
												onClick={this.uploadButtonParticipant}
												data-auto="participant-upload-button">
												<CloudUpload className={classes.buttonIcon} />
												Upload Participant CSV
											</Button>
										</FormControl>
									</div>
								</Grid>
							</Grid>
					</Grid>
				</Grid>

				<TableWrapper
					headers={TABLE_HEADERS}
					rowItems={sortedPartnerships}
					loading={loading}
					sortDirection={order}
					orderBy={orderBy}
					onSortClick={this.handleRequestSort}
					paginationFooter={sortedPartnerships.length > 20}
					itemsLabel="partnerships"
				/>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		headerContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		buttonContainerLeft: {
			display: 'flex',
			justifyContent: 'flex-end',
			paddingBottom: '5px',
			paddingRight: '5px',
			height: '100%'
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			paddingBottom: '5px',
			height: '100%'
		},
		button: {
			width: '100%',
			height: '100%'
		},
		buttonForm: {
			width: '100%'
		},
		buttonIcon: {
			paddingRight: '5px'
		},
		fileContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end'
		},
		textField: {
			display: 'flex',
			alignContent: 'center',
		},
		subtitle: {
		  display: 'inline-block',
		  textOverflow: 'ellipsis',
		  whiteSpace: 'nowrap',
		  overflow: 'hidden',
		  maxWidth: '55%'
		},
	})

const styledOutcomes = withStyles(styles)(AllPartnerships)
export default withRouter(withNotificationContext(styledOutcomes))
