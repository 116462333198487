import { createStyles, FormControl, FormLabel, TextField, WithStyles, withStyles } from '@material-ui/core'
import moment from 'moment'
import * as R from 'ramda'
import React from 'react'
import { generateYearsArray } from '../../../utils/utils'

interface DatePickerProps extends WithStyles<typeof styles> {
	required?: boolean
	label?: string
	date?: Date | string
	onDateChange: (e: any) => void
	noOfYears: number
	topYear?: number
	sortAsc?: boolean
}

interface DatePickerState {
	year?: number
	month?: string
	day?: number
}

export interface TemporaryDate {
	day?: number
	month?: string
	year?: number
}

const DAYS = R.range(1, 32)

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
	public readonly state: DatePickerState = {}

	private YEARS = this.props.sortAsc
		? generateYearsArray(this.props.noOfYears, this.props.topYear)
		: generateYearsArray(this.props.noOfYears, this.props.topYear).reverse()

	handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		const { onDateChange } = this.props

		this.setState((prevState: DatePickerState) => {
			const newState = {
				...prevState,
				[name]: value,
			}
			onDateChange(newState)

			return newState
		})
	}

	populateDate = (date: Date | string) => {
		const jsDate = moment(date).toDate()
		const day = jsDate.getDate()
		const month = MONTHS[jsDate.getMonth()]
		const year = jsDate.getFullYear()

		this.setState((prevState: DatePickerState) => ({
			...prevState,
			day,
			month,
			year,
		}))
	}

	componentDidMount() {

		const { date } = this.props

		if (date) this.populateDate(date)
	}

	public render() {
		const { classes, label } = this.props
		const { day, month, year } = this.state

		return (
			<FormControl variant="outlined" className={classes.root}>
				<FormLabel component="label">{label}</FormLabel>

				<div className={classes.formItems} data-auto="date-wrapper">
					<TextField
						required={this.props.required}
						name="day"
						id={`day-${label ? label.replace(/\s+/g, '') : ''}`}
						select={true}
						className={classes.day}
						value={day || ''}
						onChange={this.handleChange}
						SelectProps={{
							native: true,
							MenuProps: {
								className: classes.menu,
							},
						}}
						label="Day"
						margin="dense"
						variant="outlined"
						data-auto="day"
					>
						{!day && <option />}
						{DAYS.map((option: number) => (
							<option key={option} value={option} data-auto={`day-${option}`}>
								{option}
							</option>
						))}
					</TextField>
					<TextField
						required={this.props.required}
						id={`month-${label ? label.replace(/\s+/g, '') : ''}`}
						name="month"
						select={true}
						className={classes.month}
						value={month || ''}
						onChange={this.handleChange}
						SelectProps={{
							native: true,
							MenuProps: {
								className: classes.menu,
							},
						}}
						label="Month"
						margin="dense"
						variant="outlined"
						data-auto="month"
					>
						{!month && <option />}
						{MONTHS.map((m: string) => (
							<option key={m} value={m} data-auto={`month-${m}`}>
								{m}
							</option>
						))}
					</TextField>
					<TextField
						required={this.props.required}
						id={`year-${label ? label.replace(/\s+/g, '') : ''}`}
						name="year"
						select={true}
						className={classes.year}
						value={year || ''}
						onChange={this.handleChange}
						SelectProps={{
							native: true,
							MenuProps: {
								className: classes.menu,
							},
						}}
						label="Year"
						margin="dense"
						variant="outlined"
						data-auto="year"
					>
						{!year && <option />}
						{this.YEARS.map((y: number) => (
							<option key={y} value={y} data-auto={`year-${y}`}>
								{y}
							</option>
						))}
					</TextField>
				</div>
			</FormControl>
		)
	}
}

const styles = createStyles({
	root: {
		display: 'flex',
		flex: 1,
	},
	formItems: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
	},
	day: {
		flexBasis: '24%',
	},
	month: {
		flexBasis: '45%',
	},
	year: {
		flexBasis: '28%',
	},
	menu: {},
})

export default withStyles(styles)(DatePicker)
