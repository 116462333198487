import { createStyles, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import * as R from 'ramda'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Notification, NotificationInterface, withNotificationContext } from '../../services/ContextService/NotificationService/NotificationContextService'
import { DateTarget, NumericTarget, StringTarget, TargetTypeMapper } from '../../services/model/Addendum'
import { createTargetsMapper } from '../../utils/filters'
import TopLevelTarget from '../Forms/FormTemplates/TopLevelTarget/TopLevelTarget'

interface OutcomesProps extends WithStyles<typeof styles> {
	activityID: string
	addendumID: string
	partnershipID: string
	appContext: NotificationInterface
	outcomes: any[]
}

interface OutcomesState {
	loading: boolean
	outcomes: Array<StringTarget | DateTarget | NumericTarget>
}

const INITIAL_STATE: OutcomesState = {
	loading: false,
	outcomes: [],
}

export class Outcomes extends React.Component<OutcomesProps, OutcomesState> {
	public readonly state: OutcomesState = INITIAL_STATE

	setNotification = (notification: Notification) => {
		setTimeout(() => {
			this.props.appContext.handleNotification(notification)
		}, 300)
	}

	componentWillReceiveProps(nextProps: any) {
		this.setState({ outcomes: nextProps.outcomes })
	}

	async componentDidMount() {
		const actuals = this.props.outcomes
		this.setState({ outcomes: actuals })
	}

	targetFormatter = (targetObj: any) => {
		for (const key in targetObj) {
			if (targetObj.hasOwnProperty(key)) {
				if (targetObj[key] === 'NumericTarget') {
					return `${targetObj.actual} / ${targetObj.target}`
				} else if (targetObj[key] === 'DateTarget') {
					return moment(targetObj.date).format('MM/DD/YYYY')
				} else {
					return targetObj.target
				}
			}
		}
	}

	render() {
		const { classes } = this.props
		const { topLevelTargets, ...expandableTargets } = createTargetsMapper(this.state.outcomes)
		const AllRemainingTargets = R.flatten(Object.keys(expandableTargets).map((key) => expandableTargets[key]))

		return (
			<div className={classes.root}>
				<section data-auto="activity-header">
					<Grid container={true} justify="flex-start">
						{topLevelTargets.map((topLevelTarget: any) => {
							return (
								<Grid item={true} className={classes.topLevelTarget} key={topLevelTarget.targetType}>
									<TopLevelTarget target={topLevelTarget.target} targetHeader={topLevelTarget.targetType} actual={topLevelTarget.actual} />
								</Grid>
							)
						})}
					</Grid>
				</section>
				{!!expandableTargets && (
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="subtitle2">Additional Targets ...</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails style={{ paddingTop: '1em' }}>
							<Grid container={true} justify="flex-start" spacing={16}>
								{AllRemainingTargets.map((target: any) => {
									const label = TargetTypeMapper.get(target.targetType)
									const value = this.targetFormatter(target)

									return (
										<Grid item={true} xs={12} md={6} key={target.targetType} style={{ padding: 0 }}>
											<Typography variant="body1" style={{ fontWeight: 600, textTransform: 'uppercase' }} gutterBottom={true}>
												{label}&#58;
												<span style={{ marginLeft: '.5em', fontWeight: 400, textTransform: 'capitalize' }}>{value}</span>
											</Typography>
										</Grid>
									)
								})}
							</Grid>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				)}
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: `0 8px`,
			padding: `4px 0`,
		},
		container: {},
		targetsHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingBottom: `${theme.spacing.unit * 2}px`,
		},
		topLevelTarget: {
			margin: `5px`,
		},
	})

const styledOutcomes = withStyles(styles)(Outcomes)

export default withRouter(withNotificationContext(styledOutcomes))
