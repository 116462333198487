import { Collapse, createStyles, Divider, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import React from 'react'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { RouteComponentProps } from 'react-router-dom'
import { Participant, ParticipantActivity } from '../../services/model'
import { getParticipantActivities, getParticipantByID } from '../../services/participants.service'
import ParticipantModal from '../Modals/EditParticipant/EditParticipant'
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo'
import ParticipantActivitiesTable from '../Tables/ParticipantActivitiesTable/ParticipantActivitiesTable'

interface ParticipantProps extends WithStyles<typeof styles> {}
export type RouterProps = ParticipantProps &
	RouteComponentProps<{
		participantID: string
	}> &
	WithStyles<{}>

interface ParticipantState {
	participant?: Participant
	participantActivities: ParticipantActivity[]
	loading: boolean
	loadingActivities: boolean
	isModalOpen: boolean
}

const INITIAL_STATE: ParticipantState = {
	loading: true,
	loadingActivities: true,
	isModalOpen: false,
	participantActivities: [],
}

export class ParticipantComponent extends React.Component<RouterProps, ParticipantState> {
	public readonly state: ParticipantState = INITIAL_STATE

	async componentDidMount() {
		await this.setInitialData()
	}

	setInitialData = async () => {
		const { participant, participantActivities } = await this.fetchInitialData()

		this.setState({ participant, participantActivities, loading: false })
		this.setTableTimer()
	}

	async fetchInitialData() {
		const { participantID } = this.props.match.params
		const participant = await getParticipantByID(participantID).catch((e) => undefined)
		const participantActivities = await getParticipantActivities(participantID).catch((e) => [])

		return { participant, participantActivities }
	}

	setTableTimer = () => {
		setTimeout(() => {
			this.setState({ loadingActivities: false })
			this.handleModalClose()
		}, 100)
	}

	onEdit = (): void => {
		this.setState({ isModalOpen: true })
	}

	handleModalClose = () => {
		this.setState({ isModalOpen: false })
	}

	handleParticipantEdit = async () => {
		await this.setInitialData()
	}

	render() {
		const { classes } = this.props
		const { participant, participantActivities, loading, loadingActivities, isModalOpen } = this.state

		return (
			<div className={classes.root} data-auto="participant-content-wrapper">
				<BreadcrumbsItem to={'/participants'}>Participants</BreadcrumbsItem>
				<section className={classes.participantPersonalInfo} data-auto="participant-info-content">
					<Typography variant="h5" component="h1" style={{ display: 'inline-block' }} data-auto="participant-info-header">
						Participant Information
					</Typography>
					<Collapse in={!loading} timeout={{ enter: 600 }}>
						{participant && <ParticipantInfo participant={participant} handleEditClick={this.onEdit} />}
					</Collapse>
				</section>

				<Divider className={classes.divider} />
				<section className={classes.participantActivities}>
					<Typography variant="h5" component="h1" style={{ display: 'inline-block' }} data-auto="participant-activities-header">
						Activities:
					</Typography>
					<Collapse in={!loadingActivities} timeout={{ enter: 600 }}>
						<ParticipantActivitiesTable activities={participantActivities} />
					</Collapse>
				</section>
				{participant && (
					<ParticipantModal
						handleClose={this.handleModalClose}
						handleSubmit={this.handleParticipantEdit}
						openModal={isModalOpen}
						headerTitle={`${participant.firstName} ${participant.lastName}`}
						participant={participant}
					/>
				)}
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		participantPersonalInfo: {
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing.unit * 3,
			},
		},
		participantActivities: {
			marginTop: theme.spacing.unit * 3,
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing.unit * 3,
			},
		},
		divider: {
			marginTop: `${theme.spacing.unit * 2}px`,
		},
	})

export default withStyles(styles)(ParticipantComponent)
