import { Button, createStyles, TextField, Theme, WithStyles, withStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import * as React from 'react'

interface SearchParticipantFormProps extends WithStyles<typeof styles> {
	onParticipantSearch: (lastName: string, firstName?: string) => void
	searching: boolean
}

interface SearchParticipantFormState {
	firstName?: string
	lastName: string
}

export class SearchParticipantForm extends React.Component<SearchParticipantFormProps, SearchParticipantFormState> {
	state: SearchParticipantFormState = {
		lastName: '',
	}

	private formRef: React.RefObject<HTMLFormElement> = React.createRef()

	validate = () => {
		const form = this.formRef.current

		return form ? form.reportValidity() : false
	}

	handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { name, value } = e.target

		this.setState((prevState: SearchParticipantFormState) => Object.assign({}, prevState, { [name]: value }))
	}

	onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const isFormValid = this.validate()

		if (isFormValid) {
			const { firstName, lastName } = this.state

			this.props.onParticipantSearch(lastName, firstName)
		}
	}

	render() {
		const { classes, searching } = this.props
		const { firstName, lastName } = this.state

		return (
			<div className={classes.root}>
				<form onSubmit={this.onFormSubmit} ref={this.formRef} className={classes.searchForm} autoComplete="off" data-auto="search-participant-form">
					<TextField
						disabled={searching}
						value={firstName || ''}
						autoFocus={true}
						id="first-name"
						name="firstName"
						label="First Name"
						className={classes.textField}
						margin="dense"
						variant="outlined"
						inputProps={{
							maxLength: 25,
							'data-auto': 'input-firstName',
						}}
						onChange={this.handleTextChange}
					/>
					<TextField
						disabled={searching}
						value={lastName || ''}
						required={true}
						id="last-name"
						name="lastName"
						margin="dense"
						label="Last Name"
						className={classes.textField}
						variant="outlined"
						inputProps={{
							maxLength: 25,
							pattern: "^[A-z ,.'-]+$",
							'data-auto': 'input-lastName',
						}}
						onChange={this.handleTextChange}
					/>
					<Button type="submit" variant="contained" color="default" className={classes.searchButton} data-auto="search-participant-button">
						<SearchIcon className={classes.icon} />
						Search
					</Button>
				</form>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		searchForm: {
			display: 'flex',
			justifyContent: 'center',

			[theme.breakpoints.down('xs')]: {
				flexDirection: 'column',
				justifyContent: 'center',
			},
		},
		textField: {
			display: 'flex',
			marginRight: theme.spacing.unit * 3,

			[theme.breakpoints.down('xs')]: {
				width: '100%',
			},
		},
		searchButton: {
			height: '100%',
			alignSelf: 'center',
			[theme.breakpoints.down('xs')]: {
				marginTop: theme.spacing.unit * 1,
			},
		},
		icon: {
			fontSize: theme.typography.fontSize * 1.5,
			padding: `0 ${theme.spacing.unit * 2}`,
		},
	})

export default withStyles(styles)(SearchParticipantForm)
