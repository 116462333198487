import { indigo } from '@material-ui/core/colors'
import { createMuiTheme, createStyles, MuiThemeProvider, WithStyles, withStyles } from '@material-ui/core/styles/'
import React from 'react'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'

import { authProvider } from './components/HOCs/AuthProvider/AuthProvider'

import './App.css'
import Bootstrap from './components/Bootstrap'
import GlobalStyles from './GlobalStyles'

type AppProps = {} & WithStyles<typeof styles>

const BootstrapWrapper = authProvider(Bootstrap)

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
	},
	palette: {
		type: 'light',
		primary: indigo,
	},
})

class App extends React.Component<AppProps> {
	public render() {
		return (
			<MuiThemeProvider theme={theme}>
				<GlobalStyles />
				<div className={this.props.classes.root} data-auto="main-content-wrapper">
					<BreadcrumbsProvider>
						<BootstrapWrapper />
					</BreadcrumbsProvider>
				</div>
			</MuiThemeProvider>
		)
	}
}

const styles = createStyles({
	root: {
		width: '100vw',
		height: '100vh',
	},
})

export default withStyles(styles)(App)
