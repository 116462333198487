import { CircularProgress, Collapse, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

import { Participant } from '../../services/model'
import { getParticipantsByName } from '../../services/participants.service'
import SearchParticipantForm from '../Forms/SearchParticipantForm/SearchParticipantForm'
import ParticipantsTable, { CustomParticipant } from '../Tables/ParticipantsTable/ParticipantsTable'
import NotFound from '../templates/NotFound/NotFound'

interface ParticipantsProps extends WithStyles<typeof styles> {}
interface ParticipantsState {
	firstName: string
	lastName: string
	showParticipantsTable: boolean
	invalidLastName?: boolean
	validSearch: boolean
	participants?: CustomParticipant[]
	fetchingParticipants: boolean
	loadedList: boolean
}

function mapCustomParticipants(participants: Participant[]): CustomParticipant[] {
	return participants.map((participant) => Object.assign({ name: `${participant.lastName}, ${participant.firstName}` }, { ...participant }))
}

export class Participants extends React.Component<ParticipantsProps, ParticipantsState> {
	state: ParticipantsState = {
		showParticipantsTable: false,
		firstName: '',
		lastName: '',
		validSearch: false,
		fetchingParticipants: false,
		loadedList: false,
	}

	setParticipants = (participantsArray: Participant[]) => {
		this.setState({ participants: mapCustomParticipants(participantsArray), fetchingParticipants: false })
	}

	displayList = () => {
		setTimeout(() => {
			this.setState({
				loadedList: true,
			})
		}, 1100)
	}

	initiateSearchState = () => {
		this.setState({
			fetchingParticipants: true,
			loadedList: false,
		})
	}

	performSearch = async (lastName: string, firstName: string) => {
		await setTimeout(async () => {
			try {
				const filteredParticipants: Participant[] = await getParticipantsByName(lastName, firstName)
				this.setParticipants(filteredParticipants)
			} catch (e) {
				this.setState({
					fetchingParticipants: false,
					loadedList: true,
				})

				return e
			}
		}, 300)
	}

	onSearch = (lastName: string, firstName?: string) => {
		this.initiateSearchState()
		this.performSearch(lastName, firstName || '').then(() => {
			setTimeout(() => {
				this.setState(() => ({ loadedList: true }))
			}, 500)
		})
	}

	render() {
		const { classes } = this.props
		const { fetchingParticipants, participants, loadedList } = this.state

		return (
			<div className={classes.root}>
				<Typography variant="h5" component="h1">
					Participants
				</Typography>
				<div className={classes.formContainer}>
					<SearchParticipantForm onParticipantSearch={this.onSearch} searching={fetchingParticipants} />
				</div>

				<div className={classes.participantsContainer}>
					{fetchingParticipants && <CircularProgress className={classes.progress} />}
					{participants &&
						(participants.length ? (
							<Collapse in={loadedList} timeout={{ enter: 650 }}>
								<ParticipantsTable participants={participants} />
							</Collapse>
						) : (
							<NotFound item="Participants" />
						))}
				</div>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
		formContainer: {},
		participantsContainer: {
			width: '100%',
			margin: '0 auto',
		},
		progress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
		},
	})

export default withStyles(styles)(Participants)
