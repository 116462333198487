import { createStyles, Grid, Paper, Theme, Typography, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { RouteComponentProps } from 'react-router-dom'

import { Addendum, Partner, Partnership } from '../../services/model'
import { getPartnerByID } from '../../services/partners.service'
import { getAddendaByPartnershipID } from '../../services/partnership-addenda.service'
import { getPartnershipByID } from '../../services/partnerships.service'
import { getSorting, Sort, stableSort } from '../../utils/sortingService'
import { HeaderItem } from '../AllPartnerships/AllPartnerships'
import TableWrapper from '../Tables/TableWrapper'
import NotFound from '../templates/NotFound/NotFound'
import TypographyHeader from '../templates/TypographyHeader/TypographyHeader'

interface SinglePartnershipProps extends WithStyles<typeof styles> {}
interface SinglePartnershipState {
	addenda: Addendum[]
	loading: boolean
	displayNotFound: boolean
	orderBy: string
	order: Sort
	partnership?: Partnership
	leadPartner?: Partner
}

export type RouterProps = SinglePartnershipProps & RouteComponentProps<{ partnershipID: string }> & WithStyles<{}>

const HEADERS: HeaderItem[] = [
	{ label: 'Addenda', prop: 'name' },
	{ label: 'Performance Period Start', prop: 'performanceStartDate' },
	{ label: 'Performance Period End', prop: 'performanceEndDate' },
]

export class SinglePartnership extends React.Component<RouterProps, SinglePartnershipState> {
	state: SinglePartnershipState = {
		loading: true,
		addenda: [],
		orderBy: 'performanceStartDate',
		order: 'asc',
		displayNotFound: false,
	}

	async componentDidMount() {
		await this.setInitialData()
	}

	startLoadingTimeout = () => {
		setTimeout(() => {
			this.setState({
				loading: false,
			})
		}, 100)
	}

	setInitialData = async () => {
		try {
			const response = await this.fetchInitialData()
			const { partnership, addenda, leadPartner } = response

			if (partnership && addenda && leadPartner) {
				this.setState({
					partnership,
					addenda,
					leadPartner,
				})
			}

			this.startLoadingTimeout()
		} catch (e) {
			console.log('error on api call: ', e)
		}
	}

	fetchInitialData = async () => {
		const { partnershipID } = this.props.match.params
		try {
			const addendaPromise = getAddendaByPartnershipID(partnershipID)
			const partnershipPromise = getPartnershipByID(partnershipID)
			const [addenda, partnership] = await Promise.all([addendaPromise, partnershipPromise])
			const leadPartner = await Promise.resolve(getPartnerByID(partnership.leadApplicantId))

			return {
				addenda,
				partnership,
				leadPartner,
			}
		} catch (e) {
			console.log('e: ', e)

			this.setState({
				displayNotFound: true,
			})
			return {}
		}
	}

	handleRequestSort = (currentOrder: string, sortLabel: string) => {
		this.setState({
			order: currentOrder === 'asc' ? 'desc' : 'asc',
			orderBy: sortLabel,
		})
	}

	normalizeAddenda = (addendumArray: Addendum[]) => {
		const { match } = this.props

		return addendumArray.map((addenda: Addendum) => {
			return {
				title: {
					label: addenda.name,
					url: `${match.url}/addenda/${addenda.id}`,
				},
				content: [{ isDate: true, value: addenda.performanceStartDate }, { isDate: true, value: addenda.performanceEndDate }],
			}
		})
	}

	render() {
		const { classes } = this.props
		const { addenda, displayNotFound, leadPartner, loading, partnership, order, orderBy } = this.state
		const sortedAddenda = this.normalizeAddenda(stableSort(addenda, getSorting(order, orderBy)))

		return (
			<div className={classes.root}>
				{partnership && leadPartner && (
					<React.Fragment>
						<BreadcrumbsItem to={`/partnerships/${partnership.id}`}>{partnership.name}</BreadcrumbsItem>
						<section className={classes.partnershipSection}>
							<TypographyHeader label={partnership.name} type="title" />
							<Grid item={true} xs={4}>
								<Paper className={classes.partnershipDetails} elevation={1}>
									<Typography className={classes.inLine} variant="subtitle2" style={{ fontWeight: 500 }}>
										Grantee&#58;
									</Typography>

									<Typography variant="button" className={classes.leadApplicantLinkBtn}>
										{leadPartner.name}
									</Typography>
								</Paper>
							</Grid>
						</section>

						<TableWrapper
							headers={HEADERS}
							rowItems={sortedAddenda}
							loading={loading}
							sortDirection={order}
							orderBy={orderBy}
							onSortClick={this.handleRequestSort}
							itemsLabel="addendum"
							paginationFooter={sortedAddenda.length > 10}
						/>
					</React.Fragment>
				)}
				{displayNotFound && <NotFound item="Partnership" />}
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		partnershipSection: {
			marginBottom: `${theme.spacing.unit * 2}px`,
		},
		partnershipDetails: {
			boxShadow: 'none',
		},
		inLine: {
			display: 'inline-block',
		},
		leadApplicantLinkBtn: {
			textTransform: 'none',
			display: 'inline-block',
			marginLeft: theme.spacing.unit,
		},
	})

export default withStyles(styles)(SinglePartnership)
