export interface Partner extends PartnerData{
    id: string
}

export interface PartnerData {
	active: boolean,
  address: string
  city: string
	createdDate: Date
	description: string
	lastModifiedDate: Date
	name: string
	state: string
	zip: string
}


export enum PartnerStatus {
	ACTIVE = 'active',
	INACTIVE ='inactive'
}