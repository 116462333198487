import { createStyles, TextField, Theme, WithStyles, withStyles } from '@material-ui/core'
import * as React from 'react'

interface TextItemProps extends WithStyles<typeof styles> {
	label: string
	onChange: (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	otherInputProps?: { [key: string]: any }
	prop: string
	required?: boolean
	value: string
	textFieldProps?: { [key: string]: any }
	disabled?: boolean
}

const TextItem: React.SFC<TextItemProps> = (props: TextItemProps) => {
	const { classes, disabled, label, onChange, prop, required, textFieldProps, value, otherInputProps } = props

	return (
		<TextField
			label={label}
			value={value}
			id={prop}
			fullWidth={true}
			required={!!required}
			className={classes.personalInfoItem}
			placeholder={label}
			margin="normal"
			variant="outlined"
			InputProps={{
				inputProps: {
					title: `Please Provide A Valid ${label}.`,
					className: classes.input,
					name: prop,
					['data-auto']: label,
					...otherInputProps,
				},
				...textFieldProps,
			}}
			onChange={onChange(prop)}
			disabled={disabled}
		/>
	)
}

const styles = (theme: Theme) =>
	createStyles({
		personalInfoItem: {},
		input: {},
	})

export default withStyles(styles)(TextItem)
