import {
    createStyles,
    FormControlLabel,
    FormGroup,
    Switch,
    Theme,
    Typography,
    WithStyles,
    withStyles
} from '@material-ui/core'
import {green} from '@material-ui/core/colors'
import React from 'react'

import {
    Notification,
    NotificationInterface,
    withNotificationContext
} from '../../services/ContextService/NotificationService/NotificationContextService'
import CodeZone from "../CodeZone/CodeZone";
import {getEnrollmentStatus, updateEnrollmentStatus} from './../../services/addenda-activity.service'

interface EnrollmentStatusProps extends WithStyles<typeof styles> {
    partnershipID: string
    addendumID: string
    activityID: string
    appContext: NotificationInterface
}

interface EnrollmentStatusState {
    isStatusActive: boolean
}

export class EnrollmentStatusToggle extends React.Component<EnrollmentStatusProps, EnrollmentStatusState> {
    state: EnrollmentStatusState = {
        isStatusActive: false,
    }

    componentWillMount() {
        const {partnershipID, addendumID, activityID} = this.props
        getEnrollmentStatus(partnershipID, addendumID, activityID)
            .then((isStatusActive) => {
                this.setState({isStatusActive})
            })
            .catch((err) => {
                console.log(err)
            })
    }

    updateStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStatus = !(e.target.value === 'true')
        const {partnershipID, addendumID, activityID} = this.props

        updateEnrollmentStatus(partnershipID, addendumID, activityID, newStatus)
            .then((isStatusActive) => {
                this.notify({type: 'success', message: 'Enrollment status updated successfully.'})
                this.setState({isStatusActive})
            })
            .catch((err) => {
                this.notify({type: 'fail', message: 'Something went wrong, please try again.'})
                console.log(err)
            })
    }

    notify(notification: Notification) {
        setTimeout(() => {
            this.props.appContext.handleNotification(notification)
        }, 300)
    }

    render() {
        const {classes, activityID} = this.props
        const {isStatusActive} = this.state

        return (
        	<div>
				<div>
					<Typography variant="button" className={classes.typographyAlign}>
						Enrollment Status
					</Typography>
					<FormGroup row={true} className={classes.typographyAlign} style={{marginTop: '-12px'}}>
						<Typography
							component="span"
							className={`${!isStatusActive ? classes.offChecked : classes.label}`}
							style={{alignSelf: 'center', paddingRight: '16px'}}
						>
							OFF
						</Typography>
						<FormControlLabel
							control={
								<Switch
									checked={isStatusActive}
									value={isStatusActive}
									classes={{
										switchBase: classes.colorSwitchBase,
										checked: classes.colorChecked,
										bar: classes.colorBar,
									}}
									onChange={this.updateStatus}
									data-auto="status-toggle"
								/>
							}
							label={'ON'}
							className={`${classes.statusContainer} ${isStatusActive ? classes.colorChecked : classes.label}`}
							labelPlacement={'end'}
							classes={{
								label: `${classes.label} ${isStatusActive ? classes.active : ''}`,
							}}
						/>
					</FormGroup>
				</div>
				<div className={classes.dateItems}>
					{!!isStatusActive && (
						<CodeZone activityId={activityID} />
					)}
				</div>
            </div>
        )
    }
}

const styles = (theme: Theme) =>
    createStyles({
		root: {},
		statusContainer: {
			color: 'red',
		},
		colorSwitchBase: {
			'&$colorChecked': {
				color: green[800],
				'& + $colorBar': {
					backgroundColor: green[800],
				},
			},
		},
		colorBar: {},
		colorChecked: {},
		offChecked: {
			color: theme.palette.grey[900],
			fontWeight: 600,
		},
		label: {
			color: theme.palette.grey['600'],
			fontWeight: 500,
		},
		typographyAlign: {
			justifyContent: 'flex-end',
			textAlign: 'end',
			marginRight: `${theme.spacing.unit}px`,
		},
		active: {
			color: green[900],
			fontWeight: 600,
		},
		dateItems: {
			justifyContent: 'flex-end',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'flex-start',
				marginTop: `${theme.spacing.unit * -3}px`,
			},
		},
	})

export default withNotificationContext(withStyles(styles)(EnrollmentStatusToggle))
