import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { formValidityPolyfill } from './iePolyfills'

import App from './App'
import './index.css'
import { generateFakeTTLCookie, unregisterServiceWorker } from './utils/environmentActions'

if (process.env.NODE_ENV !== 'production') generateFakeTTLCookie()
if ('serviceWorker' in navigator) unregisterServiceWorker(navigator)
if (!HTMLFormElement.prototype.reportValidity) {
  formValidityPolyfill()
}

ReactDOM.render(
	<BrowserRouter basename={'/'}>
		<App />
	</BrowserRouter>,
	document.getElementById('root') as HTMLElement,
)
