import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import * as React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Partnership } from '../../services/model'
import { getPartnershipByID } from '../../services/partnerships.service'
import AddendaActivities from '../AddendaActivities/AddendaActivities'
import Addendum from '../Addendum/Addendum'
import ActivityParticipantWrapper from './ActivityParticipantWrapper/ActivityParticipantWrapper'

interface AddendumProps extends WithStyles<typeof styles> {}

interface AddendumLandingState {
	currentPartnership?: Partnership
}

export type RouterProps = AddendumProps &
	RouteComponentProps<{
		addendumID: string
		partnershipID: string
	}>

export class AddendumLanding extends React.Component<RouterProps, AddendumLandingState> {
	state: AddendumLandingState = {}

	async componentDidMount() {
		try {
			const partnership = await getPartnershipByID(this.props.match.params.partnershipID)

			this.setState({
				currentPartnership: partnership,
			})
		} catch (e) {
			console.log('e: ', e)
		}
	}

	render() {
		const { classes, match } = this.props
		const { currentPartnership } = this.state
		const { addendumID, partnershipID } = match.params
		const linkURL = `${match.url}/activities`
		const activitiesProps = { addendumID, partnershipID }

		return (
			<div className={classes.root} data-auto="addenda-content-wrapper">
				{currentPartnership && (
					<BreadcrumbsItem to={`/partnerships/${currentPartnership.id}`}>{currentPartnership.name}</BreadcrumbsItem>
				)}

				<Route exact={true} path={match.url} render={() => <Addendum {...activitiesProps} linkURL={linkURL} />} />
				<Route
					exact={true}
					path={`${match.url}/activities/:activityID`}
					render={(activityProps: any) => {
						return <AddendaActivities {...activityProps} {...activitiesProps} />
					}}
				/>
				<Route
					exact={true}
					path={`${match.url}/activities/:activityID/participants/:participantID`}
					render={(activityProps: any) => {
						return <ActivityParticipantWrapper {...activityProps} {...activitiesProps} />
					}}
				/>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {},
	})

export default withStyles(styles)(AddendumLanding)
