import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles/index'
import moment from 'moment'
import React from 'react'

import { ParticipantActivity, TableHeader, TableItem } from '../../../services/model'
import { Sort, sortArrayItems } from '../../../utils/sortingService'
import TableWrapper from '../TableWrapper'

interface TableProps extends WithStyles<typeof styles> {
	activities: ParticipantActivity[]
}
interface TableState {
	order: Sort
	orderBy: string
	page: number
	rowsPerPage: number
	sortedActivities: ParticipantActivity[]
	loading: boolean
}

export const TABLE_HEADERS: TableHeader[] = [
	{
		label: 'Activity Name',
		prop: 'activityName',
	},
	{
		label: 'Partnership',
		prop: 'partnershipName',
	},
	{
		label: 'Activity Date',
		prop: 'activityDate',
	},
]

const setInitialState = (props: any): TableState => {
	return {
		order: 'asc' as Sort,
		orderBy: 'activityName',
		page: 0,
		rowsPerPage: 10,
		sortedActivities: sortArrayItems(props.activities, 'asc', 'activityName', 0, 10),
		loading: true,
	}
}

const formatTableContent = (content: ParticipantActivity[]): TableItem[] => {
	const formattedContent = content.map((i: ParticipantActivity) => {
		return {
			title: {
				label: i.activityName,
				url: `/partnerships/${i.partnershipId}/addenda/${i.addendumId}/activities/${i.activityId}`,
			},
			content: [{ isDate: false, value: i.partnershipName }, { isDate: true, value: i.activityDate ? moment(i.activityDate).format() : '' }],
		}
	})

	return formattedContent
}

export class ParticipantActivitiesTable extends React.Component<TableProps, TableState> {
	public readonly state: TableState = setInitialState(this.props)

	handleRequestSort = (currentOrder: string, sortLabel: string) => {
		const { sortedActivities, page, rowsPerPage } = this.state
		const order: Sort = currentOrder === 'asc' ? 'desc' : 'asc'
		const orderBy: string = sortLabel
		const activities = sortArrayItems(sortedActivities, order, orderBy, page, rowsPerPage)

		this.updateSorting(activities, order, orderBy)
	}

	updateSorting = (activities: ParticipantActivity[], order: Sort, sortLabel: string) => {
		this.setState({
			order,
			orderBy: sortLabel,
			sortedActivities: activities,
		})
	}

	componentWillReceiveProps(nextProps: TableProps) {
		if (nextProps.activities !== this.props.activities) {
			const newState = setInitialState(nextProps)

			this.setState({ ...newState, loading: false })
		}
	}

	render() {
		const { classes } = this.props
		const { loading, order, orderBy, sortedActivities } = this.state
		const formattedParticipants = formatTableContent(sortedActivities)

		return (
			<div className={classes.root} data-auto="participant-activities-table">
				<TableWrapper
					headers={TABLE_HEADERS}
					rowItems={formattedParticipants}
					loading={loading}
					sortDirection={order}
					orderBy={orderBy}
					onSortClick={this.handleRequestSort}
					paginationFooter={sortedActivities.length > 10}
					itemsLabel="participants"
				/>
			</div>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			marginTop: theme.spacing.unit * 3,
			overflowX: 'auto',
			padding: '10px',
		},
	})

export default withStyles(styles)(ParticipantActivitiesTable)
