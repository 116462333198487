import { OutcomeFormKeys } from '../interface/outcomeFormKeys';
import { validationConstants } from './validation/validationConstants';

export const outcomeFormConstants: OutcomeFormKeys = {
	'ACTIVITY_PARTICIPANT_ID_KEY': 'Activity Participant ID (Mandatory)',
	'ACTIVITY_PARTICIPANT_ID_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
  'ACTIVITY_ID_KEY': 'Cohort ID',
	'ACTIVITY_ID_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'FIRST_NAME_KEY': 'First Name',
	'FIRST_NAME_EXAMPLE': '',
	'LAST_NAME_KEY': 'Last Name',
	'LAST_NAME_EXAMPLE': '',
	'STATUS_KEY': 'Status (Mandatory)',
	'STATUS_EXAMPLE': 'ENROLLED | COMPLETED | DROPPED_OUT',
	'DROP_REASON_KEY': 'Drop Reason',
	'DROP_REASON_EXAMPLE': 'UNKNOWN | ACCEPTED AN OUTSIDE/UNRELATED EMPLOYMENT OPPORTUNITY | ARRESTED, ON TRIAL OR INCARCERATED | CHALLENGE WITH GOVERNMENT BENEFIT/SOCIAL ASSISTANCE | CHANGE IN CHILD OR OTHER DEPENDENT CARE | CONFLICT OR CHANGE IN WORK SCHEDULE | DEATH OF A FAMILY MEMBER | DECEASED|DECIDED THE PROGRAM WASN’T FOR THEM | DISCHARGED DUE TO UNSATISFACTORY PERFORMANCE | EXTENDED ILLNESS (PARTICIPANT OR FAMILY MEMBER) | FINANCIAL DIFFICULTIES | MILITARY ACTIVATION OR RELOCATION (PARTICIPANT OR FAMILY MEMBER) | NO CALL/NO SHOW (ADDITIONAL INFORMATION UNAVAILABLE) | RELOCATION | TRANSPORTATION CHALLENGES | WITHDREW BEFORE START OF TRAINING',
	'ACTUAL_START_DATE_KEY': 'Actual Start Date (Mandatory)',
	'ACTUAL_START_DATE_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'ACTUAL_END_DATE_KEY': 'Actual End Date',
	'ACTUAL_END_DATE_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'WBL_TYPE_KEY': 'Outcome WBL Type',
	'WBL_TYPE_EXAMPLE': 'INTERNSHIP | INDUSTRY PROJECT | SERVICE LEARNING PROJECT | SCHOOL-BASED ENTERPRISE | APPRENTICESHIP | PRE-APPRENTICESHIP',
	'UNPAID_HOURS_KEY': 'Outcome Unpaid Hours (Mandatory)',
	'UNPAID_HOURS_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'PAID_HOURS_KEY': 'Outcome Paid Hours (Mandatory)',
	'PAID_HOURS_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'INTERNSHIP_OCCUPATION_KEY': 'Outcome Internship Occupation',
	'INTERNSHIP_OCCUPATION_EXAMPLE': validationConstants.OCCUPATION_EXAMPLE,
	'INTERNSHIP_EMPLOYER_KEY': 'Outcome Internship Employer',
	'INTERNSHIP_EMPLOYER_EXAMPLE': validationConstants.EMPLOYER_EXAMPLE,
	'INTERNSHIP_INDUSTRY_KEY': 'Outcome Internship Industry (Mandatory)',
	'INTERNSHIP_INDUSTRY_EXAMPLE': validationConstants.INDUSTRY_EXAMPLE,
	'TRAINING_HOURS_KEY': 'Actual Training Hours (Mandatory)',
	'TRAINING_HOURS_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'PLACED_JOB_KEY': 'Placed Into Job',
	'PLACED_JOB_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'PLACED_JOB_DATE_KEY': 'Job Placement Date',
	'PLACED_JOB_DATE_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'PLACED_JOB_EMPLOYER_KEY': 'Job Placement Employer',
	'PLACED_JOB_EMPLOYER_EXAMPLE': validationConstants.EMPLOYER_EXAMPLE,
	'PLACED_JOB_ADDRESS_1_KEY': 'Job Placement Address 1',
	'PLACED_JOB_ADDRESS_1_EXAMPLE': '123 Alphabet St',
	'PLACED_JOB_ADDRESS_2_KEY': 'Job Placement Address 2',
	'PLACED_JOB_ADDRESS_2_EXAMPLE': '',
	'PLACED_JOB_CITY_KEY': 'Job Placement City',
	'PLACED_JOB_CITY_EXAMPLE': 'Providence',
	'PLACED_JOB_STATE_KEY': 'Job Placement State',
	'PLACED_JOB_STATE_EXAMPLE': validationConstants.STATE_INITIALS_EXAMPLE,
	'PLACED_JOB_ZIP_KEY': 'Job Placement Zip',
	'PLACED_JOB_ZIP_EXAMPLE': validationConstants.ZIP_EXAMPLE,
	'PLACED_JOB_TITLE_KEY': 'Outcome Job Title',
	'PLACED_JOB_TITLE_EXAMPLE': 'Manager',
	'PLACED_JOB_OCCUPATION_KEY': 'Job Placement Occupation',
	'PLACED_JOB_OCCUPATION_EXAMPLE': validationConstants.OCCUPATION_EXAMPLE,
	'PLACED_JOB_INDUSTRY_KEY': 'Job Placement Industry',
	'PLACED_JOB_INDUSTRY_EXAMPLE': validationConstants.INDUSTRY_EXAMPLE,
	'PLACED_JOB_WAGE_KEY': 'Job Placement Wage',
	'PLACED_JOB_WAGE_EXAMPLE': validationConstants.FLOAT_EXAMPLE,
	'PLACED_JOB_WAGE_FREQUENCY_KEY': 'Job Placement Wage Frequency',
	'PLACED_JOB_WAGE_FREQUENCY_EXAMPLE': validationConstants.WAGE_FREQUENCY_EXAMPLE,
	'PLACED_JOB_WEEKLY_HOURS_KEY': 'Job Placement Weekly Hours',
	'PLACED_JOB_WEEKLY_HOURS_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'BENEFITS_KEY': 'Benefits Provided',
	'BENEFITS_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'PER_DIEM_KEY': 'Per Diem',
	'PER_DIEM_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'INCUMBENT_EMPLOYER_KEY': 'Incumbent Employer Placement',
	'INCUMBENT_EMPLOYER_EXAMPLE': validationConstants.EMPLOYER_EXAMPLE,
	'ACTUAL_WAGE_INCREASE_KEY': 'Actual Wage Increase',
	'ACTUAL_WAGE_INCREASE_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'ACTUAL_PROMOTION_KEY': 'Actual Promotion',
	'ACTUAL_PROMOTION_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'ACUTAL_POSITION_BACKFILLED_KEY': 'Actual Position Backfilled',
	'ACUTAL_POSITION_BACKFILLED_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'ACTUAL_EARNED_DEGREE_KEY': 'Actual Earned Degree or Credit (Mandatory)',
	'ACTUAL_EARNED_DEGREE_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'ACTUAL_EARNED_CREDITS_KEY': 'Actual Credits Earned',
	'ACTUAL_EARNED_CREDITS_EXAMPLE': validationConstants.FLOAT_EXAMPLE,
	'CREDIT_TYPE_KEY': 'Outcome Academic Credit',
	'CREDIT_TYPE_EXAMPLE': 'HIGH SCHOOL CREDIT | COLLEGE CREDIT',
	'CREDENTIAL_TYPE_KEY': 'Outcome Credential Type (Mandatory)',
	'CREDENTIAL_TYPE_EXAMPLE': 'UNKNOWN | INDUSTRY | NON-INDUSTRY | PRE-APPRENTICESHIP | APPRENTICESHIP | NONE',
	'CREDENTIAL_1_KEY': 'Outcome Credential 1',
	'CREDENTIAL_1_EXAMPLE': 'Certificate A',
	'CREDENTIAL_2_KEY': 'Outcome Credential 2',
	'CREDENTIAL_2_EXAMPLE': 'Degree B',
	'CREDENTIAL_3_KEY': 'Outcome Credential 3',
	'CREDENTIAL_3_EXAMPLE': '',
	'CREDENTIAL_4_KEY': 'Outcome Credential 4',
	'CREDENTIAL_4_EXAMPLE': '',
	'CREDENTIAL_5_KEY': 'Outcome Credential 5',
	'CREDENTIAL_5_EXAMPLE': '',
	'ACADEMIC_AWARD_KEY': 'Outcome Academic Award Detail',
	'ACADEMIC_AWARD_EXAMPLE': '',
	'STIPEND_RECEIVED_KEY': 'Outcome Stipend Received (Mandatory)',
	'STIPEND_RECEIVED_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'STIPEND_AMOUNT_KEY': 'Outcome Stipend Amount',
	'STIPEND_AMOUNT_EXAMPLE': validationConstants.FLOAT_EXAMPLE,
  'STIPEND_FREQUENCY_KEY': 'Outcome Stipend Frequency',
	'STIPEND_FREQUENCY_EXAMPLE': 'BEGINNING | MULTIPLE | COMPLETION',
	'INCENTIVE_EARNED_KEY': 'Outcome Incentive Earned (Mandatory)',
	'INCENTIVE_EARNED_EXAMPLE': 'INCENTIVE | STIPEND | WAGE',
	'INCENTIVE_TYPE_KEY': 'Outcome Incentive Type',
	'INCENTIVE_TYPE_EXAMPLE': 'BOOKS | CLOTHING | FOOD | GIFT_CARD | TRANSPORTATION',
	'WAGE_AMOUNT_KEY': 'Outcome Wage Amount',
	'WAGE_AMOUNT_EXAMPLE': validationConstants.FLOAT_EXAMPLE,
	'WAGE_FREQUENCY_KEY': 'Outcome Wage Frequency',
	'WAGE_FREQUENCY_EXAMPLE': validationConstants.WAGE_FREQUENCY_EXAMPLE,
	'PLACED_TRAINING_KEY': 'Placed Into Training',
	'PLACED_TRAINING_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'PLACED_DIRECT_KEY': 'Placed Direct',
	'PLACED_DIRECT_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'CLASSROOM_HOURS_KEY': 'Outcome Classroom Hours (Mandatory)',
	'CLASSROOM_HOURS_EXAMPLE': validationConstants.NUMBER_EXAMPLE,
	'SERVICE_PROVIDED_KEY': 'Service Provided',
	'SERVICE_PROVIDED_EXAMPLE': validationConstants.YES_NO_EXAMPLE,
	'SERVICE_START_DATE_KEY': 'Service Start Date',
	'SERVICE_START_DATE_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'SERVICE_END_DATE_KEY': 'Service End Date',
	'SERVICE_END_DATE_EXAMPLE': validationConstants.DATE_EXAMPLE,
	'SERVICES_KEY': 'Services',
	'SERVICES_EXAMPLE': 'APPEARANCE | BILLS | CHILDCARE | COUNSELING | FINANCE | FOOD | HOUSING | LEGAL | MEDICAL | OTHER | SNAP | SOCIAL | TECHNOLOGY | TRANSPORTATION',
	'ACTIVITY_PARTICIPANT_TYPE_KEY': 'ACTIVITY_PARTICIPANT_TYPE',
	'ACTIVITY_SERIVCE_TYPE_KEY': 'ACTIVITY_SERIVCE_TYPE',
	'ERROR_KEY': 'Error',
	'PARTICIPANT_ENROLLMENT_TYPE_KEY': 'PARTICIPANT_ENROLLMENT_TYPE'

}

export const exampleOutcomeCsv = [{
	[outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY]: outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_EXAMPLE,
  [outcomeFormConstants.ACTIVITY_ID_KEY]: outcomeFormConstants.ACTIVITY_ID_EXAMPLE,
  [outcomeFormConstants.FIRST_NAME_KEY]: outcomeFormConstants.FIRST_NAME_EXAMPLE,
	[outcomeFormConstants.LAST_NAME_KEY]: outcomeFormConstants.LAST_NAME_EXAMPLE,
	// Activity Information Section
	[outcomeFormConstants.STATUS_KEY]: outcomeFormConstants.STATUS_EXAMPLE,
	[outcomeFormConstants.DROP_REASON_KEY]: outcomeFormConstants.DROP_REASON_EXAMPLE,
	[outcomeFormConstants.ACTUAL_START_DATE_KEY]: outcomeFormConstants.ACTUAL_START_DATE_EXAMPLE,
	[outcomeFormConstants.ACTUAL_END_DATE_KEY]: outcomeFormConstants.ACTUAL_END_DATE_EXAMPLE,
	// Other Enrolled Activity Information
	[outcomeFormConstants.UNPAID_HOURS_KEY]: outcomeFormConstants.UNPAID_HOURS_EXAMPLE,
	[outcomeFormConstants.PAID_HOURS_KEY]: outcomeFormConstants.PAID_HOURS_EXAMPLE,
	[outcomeFormConstants.INTERNSHIP_INDUSTRY_KEY]: outcomeFormConstants.INTERNSHIP_INDUSTRY_EXAMPLE,
	// Training Hours
	[outcomeFormConstants.TRAINING_HOURS_KEY]: outcomeFormConstants.TRAINING_HOURS_EXAMPLE,
	// Academic Credit
	[outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY]: outcomeFormConstants.ACTUAL_EARNED_DEGREE_EXAMPLE,
	[outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY]: outcomeFormConstants.ACTUAL_EARNED_CREDITS_EXAMPLE,
	[outcomeFormConstants.CREDIT_TYPE_KEY]: outcomeFormConstants.CREDIT_TYPE_EXAMPLE,
	// Other Enrolled Academic Credit
	[outcomeFormConstants.CREDENTIAL_TYPE_KEY]: outcomeFormConstants.CREDENTIAL_TYPE_EXAMPLE,
	// Credentials
	[outcomeFormConstants.CREDENTIAL_1_KEY]: outcomeFormConstants.CREDENTIAL_1_EXAMPLE,
	// Stipend
	[outcomeFormConstants.STIPEND_RECEIVED_KEY]: outcomeFormConstants.STIPEND_RECEIVED_EXAMPLE,
	[outcomeFormConstants.STIPEND_AMOUNT_KEY]: outcomeFormConstants.STIPEND_AMOUNT_EXAMPLE,
	// Incentive
	[outcomeFormConstants.INCENTIVE_EARNED_KEY]: outcomeFormConstants.INCENTIVE_EARNED_EXAMPLE,
	[outcomeFormConstants.INCENTIVE_TYPE_KEY]: outcomeFormConstants.INCENTIVE_TYPE_EXAMPLE,
	[outcomeFormConstants.STIPEND_FREQUENCY_KEY]: outcomeFormConstants.STIPEND_FREQUENCY_EXAMPLE,
	[outcomeFormConstants.WAGE_AMOUNT_KEY]: outcomeFormConstants.WAGE_AMOUNT_EXAMPLE,
	[outcomeFormConstants.WAGE_FREQUENCY_KEY]: outcomeFormConstants.WAGE_FREQUENCY_EXAMPLE,
	// Supportive Services
	[outcomeFormConstants.SERVICE_PROVIDED_KEY]: outcomeFormConstants.SERVICE_PROVIDED_EXAMPLE,
	[outcomeFormConstants.SERVICE_START_DATE_KEY]: outcomeFormConstants.SERVICE_START_DATE_EXAMPLE,
	[outcomeFormConstants.SERVICE_END_DATE_KEY]: outcomeFormConstants.SERVICE_END_DATE_EXAMPLE,
	[outcomeFormConstants.SERVICES_KEY]: outcomeFormConstants.SERVICES_EXAMPLE,
	// Unknown
	[outcomeFormConstants.CLASSROOM_HOURS_KEY]: outcomeFormConstants.CLASSROOM_HOURS_EXAMPLE,
  [outcomeFormConstants.PLACED_TRAINING_KEY]: outcomeFormConstants.PLACED_TRAINING_EXAMPLE,
	[outcomeFormConstants.PLACED_DIRECT_KEY]: outcomeFormConstants.PLACED_DIRECT_EXAMPLE,
  // Job Placement
	[outcomeFormConstants.PLACED_JOB_KEY]: outcomeFormConstants.PLACED_JOB_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_DATE_KEY]: outcomeFormConstants.PLACED_JOB_DATE_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_EMPLOYER_KEY]: outcomeFormConstants.PLACED_JOB_EMPLOYER_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_ADDRESS_1_KEY]: outcomeFormConstants.PLACED_JOB_ADDRESS_1_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_ADDRESS_2_KEY]: outcomeFormConstants.PLACED_JOB_ADDRESS_2_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_CITY_KEY]: outcomeFormConstants.PLACED_JOB_CITY_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_STATE_KEY]: outcomeFormConstants.PLACED_JOB_STATE_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_ZIP_KEY]: outcomeFormConstants.PLACED_JOB_ZIP_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_TITLE_KEY]: outcomeFormConstants.PLACED_JOB_TITLE_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_OCCUPATION_KEY]: outcomeFormConstants.PLACED_JOB_OCCUPATION_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_INDUSTRY_KEY]: outcomeFormConstants.PLACED_JOB_INDUSTRY_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_WAGE_KEY]: outcomeFormConstants.PLACED_JOB_WAGE_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_WAGE_FREQUENCY_KEY]: outcomeFormConstants.PLACED_JOB_WAGE_FREQUENCY_EXAMPLE,
	[outcomeFormConstants.PLACED_JOB_WEEKLY_HOURS_KEY]: outcomeFormConstants.PLACED_JOB_WEEKLY_HOURS_EXAMPLE,
	[outcomeFormConstants.BENEFITS_KEY]: outcomeFormConstants.BENEFITS_EXAMPLE,
	[outcomeFormConstants.PER_DIEM_KEY]: outcomeFormConstants.PER_DIEM_EXAMPLE,

  // Incumbent Worker
	[outcomeFormConstants.INCUMBENT_EMPLOYER_KEY]: outcomeFormConstants.INCUMBENT_EMPLOYER_EXAMPLE,
	[outcomeFormConstants.ACTUAL_WAGE_INCREASE_KEY]: outcomeFormConstants.ACTUAL_WAGE_INCREASE_EXAMPLE,
	[outcomeFormConstants.ACTUAL_PROMOTION_KEY]: outcomeFormConstants.ACTUAL_PROMOTION_EXAMPLE,
	[outcomeFormConstants.ACUTAL_POSITION_BACKFILLED_KEY]: outcomeFormConstants.ACUTAL_POSITION_BACKFILLED_EXAMPLE,

  // Other Enrolled Activity Information
	[outcomeFormConstants.WBL_TYPE_KEY]: outcomeFormConstants.WBL_TYPE_EXAMPLE,
  [outcomeFormConstants.INTERNSHIP_OCCUPATION_KEY]: outcomeFormConstants.INTERNSHIP_OCCUPATION_EXAMPLE,
	[outcomeFormConstants.INTERNSHIP_EMPLOYER_KEY]: outcomeFormConstants.INTERNSHIP_EMPLOYER_EXAMPLE,

	// Credentials
  [outcomeFormConstants.CREDENTIAL_2_KEY]: outcomeFormConstants.CREDENTIAL_2_EXAMPLE,
	[outcomeFormConstants.CREDENTIAL_3_KEY]: outcomeFormConstants.CREDENTIAL_3_EXAMPLE,
	[outcomeFormConstants.CREDENTIAL_4_KEY]: outcomeFormConstants.CREDENTIAL_4_EXAMPLE,
	[outcomeFormConstants.CREDENTIAL_5_KEY]: outcomeFormConstants.CREDENTIAL_5_EXAMPLE,

  // Academic Award Detail
	[outcomeFormConstants.ACADEMIC_AWARD_KEY]: outcomeFormConstants.ACADEMIC_AWARD_EXAMPLE,
}];

export const exampleOpcOutcomeCsv = [{
	[outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY]: outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_EXAMPLE,
	[outcomeFormConstants.SERVICE_PROVIDED_KEY]: outcomeFormConstants.SERVICE_PROVIDED_EXAMPLE,
	[outcomeFormConstants.SERVICE_START_DATE_KEY]: outcomeFormConstants.SERVICE_START_DATE_EXAMPLE,
	[outcomeFormConstants.SERVICE_END_DATE_KEY]: outcomeFormConstants.SERVICE_END_DATE_EXAMPLE,
	[outcomeFormConstants.SERVICES_KEY]: outcomeFormConstants.SERVICES_EXAMPLE
}];
