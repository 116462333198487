import {validateDate} from '../csvUtil';
import {exampleOutcomeCsv, outcomeFormConstants} from '../outcomeFormConstants'
import { outcomeFormValidationConstants } from './outcomeFormValidationConstants';

export const validateOutcome = (data: any) => {
	let isValid: boolean = true;
	const errors: string[] = [];

	const templateKeys = Object.keys(exampleOutcomeCsv[0])

	// tslint:disable-next-line: forin
	for (const key in data) {
		const index = templateKeys.indexOf(key);
		if (index > -1) {
			templateKeys.splice(index, 1)
		}
    const credentialType1 = data[outcomeFormConstants.CREDENTIAL_TYPE_KEY];
    const serviceProvided2 = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
		switch(key) {
			case outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY:
				const activityParticipantId = data[outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY];
				if (activityParticipantId && !outcomeFormValidationConstants.ACTIVITY_PARTICIPANT_ID_REGEX.test(activityParticipantId)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTIVITY_PARTICIPANT_ID_ERROR);
				}
				if (!activityParticipantId) {
					isValid = false;
					errors.push(outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY + ' is required');
				}
				break;
			case outcomeFormConstants.STATUS_KEY:
				const status = data[outcomeFormConstants.STATUS_KEY];
				if (status && !outcomeFormValidationConstants.STATUS_REGEX.test(status)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.STATUS_ERROR);
				}
				if (!status) {
					isValid = false;
					errors.push(outcomeFormConstants.STATUS_KEY + ' is required');
				}
				break;
			case outcomeFormConstants.DROP_REASON_KEY:
				const dropReason = data[outcomeFormConstants.DROP_REASON_KEY];
        const status2 = data[outcomeFormConstants.STATUS_KEY];
				if (dropReason && !outcomeFormValidationConstants.DROP_REASON_REGEX.test(dropReason)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.DROP_REASON_ERROR);
				}
        if(['DROPPED_OUT'].includes(status2) && !dropReason){
          isValid = false;
					errors.push(outcomeFormConstants.DROP_REASON_KEY + ' is required when Status is DROPPED_OUT');
        }
				break;
			case outcomeFormConstants.ACTUAL_START_DATE_KEY:
        const actualStartDate = data[outcomeFormConstants.ACTUAL_START_DATE_KEY]
				if (validateDate(actualStartDate)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTUAL_START_DATE_ERROR);
				}
				break;
			case outcomeFormConstants.ACTUAL_END_DATE_KEY:
        const actualEndDate = data[outcomeFormConstants.ACTUAL_END_DATE_KEY]
        const status1 = data[outcomeFormConstants.STATUS_KEY];
				if (['COMPLETED','DROPPED_OUT'].includes(status1) && validateDate(actualEndDate)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTUAL_END_DATE_ERROR);
				}
        if(['COMPLETED','DROPPED_OUT'].includes(status1) && !actualEndDate){
          isValid = false;
					errors.push(outcomeFormConstants.ACTUAL_END_DATE_KEY + ' is required when Status is COMPLETED or DROPPED_OUT');
        }
        if(!['COMPLETED','DROPPED_OUT'].includes(status1) && actualEndDate){
          isValid = false;
					errors.push(outcomeFormConstants.ACTUAL_END_DATE_KEY + ' should be blank when Status is not COMPLETED or DROPPED_OUT');
        }
				break;
			case outcomeFormConstants.WBL_TYPE_KEY:
				const wblType = data[outcomeFormConstants.WBL_TYPE_KEY];
				if (wblType && !outcomeFormValidationConstants.WBL_TYPE_REGEX.test(wblType)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.WBL_TYPE_ERROR);
				}
				break;
			case outcomeFormConstants.UNPAID_HOURS_KEY:
				const unpaidHours = data[outcomeFormConstants.UNPAID_HOURS_KEY];
				if (unpaidHours && !outcomeFormValidationConstants.UNPAID_HOURS_REGEX.test(unpaidHours)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.UNPAID_HOURS_ERROR);
				}
        if(!unpaidHours){
          isValid = false;
					errors.push(outcomeFormConstants.UNPAID_HOURS_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.PAID_HOURS_KEY:
				const paidHours = data[outcomeFormConstants.PAID_HOURS_KEY];
				if (paidHours && !outcomeFormValidationConstants.PAID_HOURS_REGEX.test(paidHours)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PAID_HOURS_ERROR);
				}
        if(!paidHours){
          isValid = false;
					errors.push(outcomeFormConstants.PAID_HOURS_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.INTERNSHIP_OCCUPATION_KEY:
				const internshipOccupation = data[outcomeFormConstants.INTERNSHIP_OCCUPATION_KEY];
				if (internshipOccupation && !outcomeFormValidationConstants.INTERNSHIP_OCCUPATION_REGEX.test(internshipOccupation)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.INTERNSHIP_OCCUPATION_ERROR);
				}
				break;
			case outcomeFormConstants.INTERNSHIP_EMPLOYER_KEY:
				const internshipEmployer = data[outcomeFormConstants.INTERNSHIP_EMPLOYER_KEY];
				if (internshipEmployer && !outcomeFormValidationConstants.INTERNSHIP_EMPLOYER_REGEX.test(internshipEmployer)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.INTERNSHIP_EMPLOYER_ERROR);
				}
				break;
			case outcomeFormConstants.INTERNSHIP_INDUSTRY_KEY:
				const internshipIndustry = data[outcomeFormConstants.INTERNSHIP_INDUSTRY_KEY];
				if (internshipIndustry && !outcomeFormValidationConstants.INTERNSHIP_INDUSTRY_REGEX.test(internshipIndustry)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.INTERNSHIP_INDUSTRY_ERROR);
				}
        if(!internshipIndustry){
          isValid = false;
					errors.push(outcomeFormConstants.INTERNSHIP_INDUSTRY_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.TRAINING_HOURS_KEY:
				const trainingHours = data[outcomeFormConstants.TRAINING_HOURS_KEY];
				if (trainingHours && !outcomeFormValidationConstants.TRAINING_HOURS_REGEX.test(trainingHours)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.TRAINING_HOURS_ERROR);
				}
        if(!trainingHours){
          isValid = false;
					errors.push(outcomeFormConstants.TRAINING_HOURS_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.PLACED_JOB_KEY:
				const placedJob = data[outcomeFormConstants.PLACED_JOB_KEY];
				if (placedJob && !outcomeFormValidationConstants.PLACED_JOB_REGEX.test(placedJob)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_DATE_KEY:
				if (validateDate(data[outcomeFormConstants.PLACED_JOB_DATE_KEY])) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_DATE_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_EMPLOYER_KEY:
				const placedJobEmployer = data[outcomeFormConstants.PLACED_JOB_EMPLOYER_KEY];
				if (placedJobEmployer && !outcomeFormValidationConstants.PLACED_JOB_EMPLOYER_REGEX.test(placedJobEmployer)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_EMPLOYER_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_ADDRESS_1_KEY:
				const placedJobAddress1 = data[outcomeFormConstants.PLACED_JOB_ADDRESS_1_KEY];
				if (placedJobAddress1 && !outcomeFormValidationConstants.PLACED_JOB_ADDRESS_1_REGEX.test(placedJobAddress1)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_ADDRESS_1_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_ADDRESS_2_KEY:
				const placedJobAddress2 = data[outcomeFormConstants.PLACED_JOB_ADDRESS_2_KEY];
				if (placedJobAddress2 && !outcomeFormValidationConstants.PLACED_JOB_ADDRESS_2_REGEX.test(placedJobAddress2)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_ADDRESS_2_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_CITY_KEY:
				const placedJobCity = data[outcomeFormConstants.PLACED_JOB_CITY_KEY];
				if (placedJobCity && !outcomeFormValidationConstants.PLACED_JOB_CITY_REGEX.test(placedJobCity)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_CITY_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_STATE_KEY:
				const placedJobState = data[outcomeFormConstants.PLACED_JOB_STATE_KEY];
				if (placedJobState && !outcomeFormValidationConstants.PLACED_JOB_STATE_REGEX.test(placedJobState)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_STATE_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_ZIP_KEY:
				const placedJobZip = data[outcomeFormConstants.PLACED_JOB_ZIP_KEY];
				if (placedJobZip && !outcomeFormValidationConstants.PLACED_JOB_ZIP_REGEX.test(placedJobZip)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_ZIP_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_TITLE_KEY:
				const placedJobTitle = data[outcomeFormConstants.PLACED_JOB_TITLE_KEY];
				if (placedJobTitle && !outcomeFormValidationConstants.PLACED_JOB_TITLE_REGEX.test(placedJobTitle)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_TITLE_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_OCCUPATION_KEY:
				const placedJobOccupation = data[outcomeFormConstants.PLACED_JOB_OCCUPATION_KEY];
				if (placedJobOccupation && !outcomeFormValidationConstants.PLACED_JOB_OCCUPATION_REGEX.test(placedJobOccupation)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_OCCUPATION_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_INDUSTRY_KEY:
				const placedJobIndustry = data[outcomeFormConstants.PLACED_JOB_INDUSTRY_KEY];
				if (placedJobIndustry && !outcomeFormValidationConstants.PLACED_JOB_INDUSTRY_REGEX.test(placedJobIndustry)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_INDUSTRY_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_WAGE_KEY:
				const placedJobWage = data[outcomeFormConstants.PLACED_JOB_WAGE_KEY];
				if (placedJobWage && !outcomeFormValidationConstants.PLACED_JOB_WAGE_REGEX.test(placedJobWage)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_WAGE_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_WAGE_FREQUENCY_KEY:
				const placedJobWageFrequency = data[outcomeFormConstants.PLACED_JOB_WAGE_FREQUENCY_KEY];
				if (placedJobWageFrequency && !outcomeFormValidationConstants.PLACED_JOB_WAGE_FREQUENCY_REGEX.test(placedJobWageFrequency)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_WAGE_FREQUENCY_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_JOB_WEEKLY_HOURS_KEY:
				const placedJobWeeklyHours = data[outcomeFormConstants.PLACED_JOB_WEEKLY_HOURS_KEY];
				if (placedJobWeeklyHours && !outcomeFormValidationConstants.PLACED_JOB_WEEKLY_HOURS_REGEX.test(placedJobWeeklyHours)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_JOB_WEEKLY_HOURS_ERROR);
				}
				break;
			case outcomeFormConstants.BENEFITS_KEY:
				const benefits = data[outcomeFormConstants.BENEFITS_KEY];
				if (benefits && !outcomeFormValidationConstants.BENEFITS_REGEX.test(benefits)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.BENEFITS_ERROR);
				}
				break;
			case outcomeFormConstants.PER_DIEM_KEY:
				const perDiem = data[outcomeFormConstants.PER_DIEM_KEY];
				if (perDiem && !outcomeFormValidationConstants.PER_DIEM_REGEX.test(perDiem)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PER_DIEM_ERROR);
				}
				break;
			case outcomeFormConstants.INCUMBENT_EMPLOYER_KEY:
				const incumbentEmployer = data[outcomeFormConstants.INCUMBENT_EMPLOYER_KEY];
				if (incumbentEmployer && !outcomeFormValidationConstants.INCUMBENT_EMPLOYER_REGEX.test(incumbentEmployer)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.INCUMBENT_EMPLOYER_ERROR);
				}
				break;
			case outcomeFormConstants.ACTUAL_WAGE_INCREASE_KEY:
				const actualWageIncrease = data[outcomeFormConstants.ACTUAL_WAGE_INCREASE_KEY];
				if (actualWageIncrease && !outcomeFormValidationConstants.ACTUAL_WAGE_INCREASE_REGEX.test(actualWageIncrease)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTUAL_WAGE_INCREASE_ERROR);
				}
				break;
			case outcomeFormConstants.ACTUAL_PROMOTION_KEY:
				const actualPromotion = data[outcomeFormConstants.ACTUAL_PROMOTION_KEY];
				if (actualPromotion && !outcomeFormValidationConstants.ACTUAL_PROMOTION_REGEX.test(actualPromotion)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTUAL_PROMOTION_ERROR);
				}
				break;
			case outcomeFormConstants.ACUTAL_POSITION_BACKFILLED_KEY:
				const actualPositionBackfilled = data[outcomeFormConstants.ACUTAL_POSITION_BACKFILLED_KEY];
				if (actualPositionBackfilled && !outcomeFormValidationConstants.ACUTAL_POSITION_BACKFILLED_REGEX.test(actualPositionBackfilled)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACUTAL_POSITION_BACKFILLED_ERROR);
				}
				break;
			case outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY:
				const actualEarnedDegree = data[outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY];
				if (actualEarnedDegree && !outcomeFormValidationConstants.ACTUAL_EARNED_DEGREE_REGEX.test(actualEarnedDegree)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTUAL_EARNED_DEGREE_ERROR);
				}
        if(!actualEarnedDegree){
          isValid = false;
          errors.push(outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY:
				const actualEarnedCredits = data[outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY];
        const actualEarnedDegree1 = data[outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY];
				if (actualEarnedDegree1 === 'YES' && actualEarnedCredits && !outcomeFormValidationConstants.ACTUAL_EARNED_CREDITS_REGEX.test(actualEarnedCredits)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACTUAL_EARNED_CREDITS_ERROR);
				}
        if(actualEarnedDegree1 === 'YES' && !actualEarnedCredits){
          isValid = false;
          errors.push(outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY + ' is required when "Actual Earned Degree or Credit" is YES');
        }
        if(actualEarnedDegree1 === 'NO' && actualEarnedCredits){
          isValid = false;
          errors.push(outcomeFormConstants.ACTUAL_EARNED_CREDITS_KEY + ' should be blank when "Actual Earned Degree or Credit" is NO');
        }
				break;
			case outcomeFormConstants.CREDIT_TYPE_KEY:
				const creditType = data[outcomeFormConstants.CREDIT_TYPE_KEY];
        const actualEarnedDegree2 = data[outcomeFormConstants.ACTUAL_EARNED_DEGREE_KEY];
				if (actualEarnedDegree2 === 'YES' && creditType && !outcomeFormValidationConstants.CREDIT_TYPE_REGEX.test(creditType)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDIT_TYPE_ERROR);
				}
        if(actualEarnedDegree2 === 'YES' && !creditType){
          isValid = false;
          errors.push(outcomeFormConstants.CREDIT_TYPE_KEY + ' is required when "Actual Earned Degree or Credit" is YES');
        }
        if(actualEarnedDegree2 === 'NO' && creditType){
          isValid = false;
          errors.push(outcomeFormConstants.CREDIT_TYPE_KEY + ' should be blank "Actual Earned Degree or Credit" is NO');
        }
				break;
			case outcomeFormConstants.CREDENTIAL_TYPE_KEY:
				const credentialType = data[outcomeFormConstants.CREDENTIAL_TYPE_KEY];
				if (credentialType && !outcomeFormValidationConstants.CREDENTIAL_TYPE_REGEX.test(credentialType)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDENTIAL_TYPE_ERROR);
				}
        if(!credentialType){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_TYPE_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.CREDENTIAL_1_KEY:
        
				const credential1 = data[outcomeFormConstants.CREDENTIAL_1_KEY];
				if (credentialType1!== 'UNKNOWN' && credentialType1 !== 'No recognized credential' && credentialType1.toLowerCase() !== 'none'  && credential1 && !outcomeFormValidationConstants.CREDENTIAL_1_REGEX.test(credential1)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDENTIAL_1_ERROR);
				}
        if(credentialType1!== 'UNKNOWN' && credentialType1 !== 'No recognized credential' && credentialType1.toLowerCase() !== 'none'  && !credential1){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_1_KEY + ' is required when "Outcome Credential Type" is other than UNKNOWN or NONE');
        }
        if(['unknown','no recognized credential','none'].includes(credentialType1?.toLowerCase()) && credential1){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_1_KEY + ' should be blank when "Outcome Credential Type" is UNKNOWN or NONE');
        }
				break;
			case outcomeFormConstants.CREDENTIAL_2_KEY:
				const credential2 = data[outcomeFormConstants.CREDENTIAL_2_KEY];
				if (credentialType1!== 'UNKNOWN' && credentialType1 !== 'No recognized credential' && credentialType1.toLowerCase() !== 'none'  && credential2 && !outcomeFormValidationConstants.CREDENTIAL_2_REGEX.test(credential2)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDENTIAL_2_ERROR);
				}
        if(['unknown','no recognized credential','none'].includes(credentialType1?.toLowerCase()) && credential2){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_2_KEY + ' should be blank when "Outcome Credential Type" is UNKNOWN or NONE');
        }
				break;
			case outcomeFormConstants.CREDENTIAL_3_KEY:
				const credential3 = data[outcomeFormConstants.CREDENTIAL_3_KEY];
				if (credentialType1!== 'UNKNOWN' && credentialType1 !== 'No recognized credential' && credentialType1.toLowerCase() !== 'none'  && credential3 && !outcomeFormValidationConstants.CREDENTIAL_3_REGEX.test(credential3)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDENTIAL_3_ERROR);
				}
        if(['unknown','no recognized credential','none'].includes(credentialType1?.toLowerCase()) && credential3){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_3_KEY + ' should be blank when "Outcome Credential Type" is UNKNOWN or NONE');
        }
				break;
			case outcomeFormConstants.CREDENTIAL_4_KEY:
				const credential4 = data[outcomeFormConstants.CREDENTIAL_4_KEY];
				if (credentialType1!== 'UNKNOWN' && credentialType1 !== 'No recognized credential' && credentialType1.toLowerCase() !== 'none'  && credential4 && !outcomeFormValidationConstants.CREDENTIAL_4_REGEX.test(credential4)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDENTIAL_4_ERROR);
				}
        if(['unknown','no recognized credential','none'].includes(credentialType1?.toLowerCase()) && credential4){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_4_KEY + ' should be blank when "Outcome Credential Type" is UNKNOWN or NONE');
        }
				break;
			case outcomeFormConstants.CREDENTIAL_5_KEY:
				const credential5 = data[outcomeFormConstants.CREDENTIAL_5_KEY];
				if (credentialType1!== 'UNKNOWN' && credentialType1 !== 'No recognized credential' && credentialType1.toLowerCase() !== 'none'  && credential5 && !outcomeFormValidationConstants.CREDENTIAL_5_REGEX.test(credential5)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CREDENTIAL_5_ERROR);
				}
        if(['unknown','no recognized credential','none'].includes(credentialType1?.toLowerCase()) && credential5){
          isValid = false;
          errors.push(outcomeFormConstants.CREDENTIAL_5_KEY + ' should be blank when "Outcome Credential Type" is UNKNOWN or NONE');
        }
				break;
			case outcomeFormConstants.ACADEMIC_AWARD_KEY:
				const academicAward = data[outcomeFormConstants.ACADEMIC_AWARD_KEY];
				if (academicAward && !outcomeFormValidationConstants.ACADEMIC_AWARD_REGEX.test(academicAward)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.ACADEMIC_AWARD_ERROR);
				}
				break;
			case outcomeFormConstants.STIPEND_RECEIVED_KEY:
				const stipendReceived = data[outcomeFormConstants.STIPEND_RECEIVED_KEY];
				if (stipendReceived && !outcomeFormValidationConstants.STIPEND_RECEIVED_REGEX.test(stipendReceived)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.STIPEND_RECEIVED_ERROR);
				}
        if(!stipendReceived){
          isValid = false;
          errors.push(outcomeFormConstants.STIPEND_RECEIVED_KEY + ' is required');
        }
				break;
      case outcomeFormConstants.STIPEND_FREQUENCY_KEY:
        const stipendFrequency = data[outcomeFormConstants.STIPEND_FREQUENCY_KEY];
        const stipendReceived1 = data[outcomeFormConstants.STIPEND_RECEIVED_KEY];
        if (['YES'].includes(stipendReceived1) && stipendFrequency && !outcomeFormValidationConstants.STIPEND_FREQUENCY_REGEX.test(stipendFrequency)) {
          isValid = false;
          errors.push(outcomeFormValidationConstants.STIPEND_FREQUENCY_ERROR);
        }
        if(['YES'].includes(stipendReceived1) && !stipendFrequency){
          isValid = false;
          errors.push(outcomeFormConstants.STIPEND_FREQUENCY_KEY + ' is required when "Outcome Stipend Received" is YES');
        }
        if(['NO'].includes(stipendReceived1) && stipendFrequency){
          isValid = false;
          errors.push(outcomeFormConstants.STIPEND_FREQUENCY_KEY + ' should be blank when "Outcome Stipend Received" is NO');
        }
        break;
			case outcomeFormConstants.STIPEND_AMOUNT_KEY:
				const stipendAmount = data[outcomeFormConstants.STIPEND_AMOUNT_KEY];
        const stipendReceived2 = data[outcomeFormConstants.STIPEND_RECEIVED_KEY];
				if (['YES'].includes(stipendReceived2) && stipendAmount && !outcomeFormValidationConstants.STIPEND_AMOUNT_REGEX.test(stipendAmount)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.STIPEND_AMOUNT_ERROR);
				}
        if(['YES'].includes(stipendReceived2) && !stipendAmount){
          isValid = false;
          errors.push(outcomeFormConstants.STIPEND_AMOUNT_KEY + ' is required when "Outcome Stipend Received" is YES');
        }
        if(['NO'].includes(stipendReceived2) && stipendAmount){
          isValid = false;
          errors.push(outcomeFormConstants.STIPEND_AMOUNT_KEY + ' should be blank when "Outcome Stipend Received" is NO');
        }
				break;
			case outcomeFormConstants.INCENTIVE_EARNED_KEY:
				const incentiveEarned = data[outcomeFormConstants.INCENTIVE_EARNED_KEY];
				if (incentiveEarned && !outcomeFormValidationConstants.INCENTIVE_EARNED_REGEX.test(incentiveEarned)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.INCENTIVE_EARNED_ERROR);
				}
        if(!incentiveEarned){
          isValid = false;
					errors.push(outcomeFormConstants.INCENTIVE_EARNED_KEY + ' is required');
        }
				break;
			case outcomeFormConstants.INCENTIVE_TYPE_KEY:
				const incentiveType = data[outcomeFormConstants.INCENTIVE_TYPE_KEY];
        const incentiveEarned1 = data[outcomeFormConstants.INCENTIVE_EARNED_KEY];
				if (['INCENTIVE'].includes(incentiveEarned1) && incentiveType && !outcomeFormValidationConstants.INCENTIVE_TYPE_REGEX.test(incentiveType)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.INCENTIVE_TYPE_ERROR);
				}
        if(['INCENTIVE'].includes(incentiveEarned1) && !incentiveType){
          isValid = false;
					errors.push(outcomeFormConstants.INCENTIVE_TYPE_KEY + ' is required when "Outcome Incentive Earned" is INCENTIVE');
        }
        if(!['INCENTIVE'].includes(incentiveEarned1) && ['STIPEND','WAGE'].includes(incentiveEarned1) && incentiveType){
          isValid = false;
					errors.push(outcomeFormConstants.INCENTIVE_TYPE_KEY + ' should be blank when "Outcome Incentive Earned" is not INCENTIVE');
        }
				break;
			case outcomeFormConstants.WAGE_AMOUNT_KEY:
				const wageAmount = data[outcomeFormConstants.WAGE_AMOUNT_KEY];
        const incentiveEarned2 = data[outcomeFormConstants.INCENTIVE_EARNED_KEY];
				if (['WAGE'].includes(incentiveEarned2) && wageAmount && !outcomeFormValidationConstants.WAGE_AMOUNT_REGEX.test(wageAmount)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.WAGE_AMOUNT_ERROR);
				}
        if(['WAGE'].includes(incentiveEarned2) && ['STIPEND','INCENTIVE'].includes(incentiveEarned2) && !wageAmount){
          isValid = false;
					errors.push(outcomeFormConstants.WAGE_AMOUNT_KEY + ' is required when "Outcome Incentive Earned" is WAGE');
        }
        if(!['WAGE'].includes(incentiveEarned2) && wageAmount){
          isValid = false;
					errors.push(outcomeFormConstants.WAGE_AMOUNT_KEY + ' should be blank when "Outcome Incentive Earned" is not WAGE');
        }
				break;
			case outcomeFormConstants.WAGE_FREQUENCY_KEY:
				const wageFrequency = data[outcomeFormConstants.WAGE_FREQUENCY_KEY];
        const incentiveEarned3 = data[outcomeFormConstants.INCENTIVE_EARNED_KEY];
				if (['WAGE'].includes(incentiveEarned3) && wageFrequency && !outcomeFormValidationConstants.WAGE_FREQUENCY_REGEX.test(wageFrequency)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.WAGE_FREQUENCY_ERROR);
				}
        if(['WAGE'].includes(incentiveEarned3) && !wageFrequency){
          isValid = false;
					errors.push(outcomeFormConstants.WAGE_FREQUENCY_KEY + ' is required when "Outcome Incentive Earned" is WAGE');
        }
        if(!['WAGE'].includes(incentiveEarned3) && ['STIPEND','INCENTIVE'].includes(incentiveEarned3) && wageFrequency){
          isValid = false;
					errors.push(outcomeFormConstants.WAGE_FREQUENCY_KEY + ' should be blank when "Outcome Incentive Earned" is not WAGE');
        }
				break;
			case outcomeFormConstants.SERVICE_PROVIDED_KEY:
        const serviceProvided = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
        if(serviceProvided){
          const serviceProvidedError = validateServiceProvided(serviceProvided);
          if (serviceProvidedError) {
            isValid = false;
            errors.push(serviceProvidedError);
          }
        }
				break;
			case outcomeFormConstants.SERVICE_START_DATE_KEY:
        const serviceStartDate = data[outcomeFormConstants.SERVICE_START_DATE_KEY]
        const serviceProvided1 = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
        if(serviceProvided1 === 'YES' && !serviceStartDate){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_START_DATE_KEY + ' is required when Service Provided is YES');
        }
				if (serviceProvided1 === 'YES' && validateDate(serviceStartDate)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.SERVICE_START_DATE_ERROR);
				}
        if(serviceProvided1 === 'NO' && serviceStartDate){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_START_DATE_KEY + ' should be blank when Service Provided is NO');
        }
				break;
			case outcomeFormConstants.SERVICE_END_DATE_KEY:
        const serviceEndDate = data[outcomeFormConstants.SERVICE_END_DATE_KEY]
        if(serviceProvided2 === 'YES' && !serviceEndDate){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_END_DATE_KEY + ' is required when Service Provided is YES');
        }
				if (serviceProvided2 === 'YES' && validateDate(serviceEndDate)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.SERVICE_END_DATE_ERROR);
				}
        if(serviceProvided2 === 'NO' && serviceEndDate){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_END_DATE_KEY + ' should be blank when Service Provided is NO');
        }
				break;
			case outcomeFormConstants.SERVICES_KEY:
        const services = data[outcomeFormConstants.SERVICES_KEY]
        const serviceProvided3 = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
        if(serviceProvided3 === 'YES' && (!services || services.length === 0)){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICES_KEY + ' is required when Service Provided is YES');
        }
				const servicesError = validateServices(services);
				if (serviceProvided3 === 'YES' && servicesError) {
					isValid = false;
					errors.push(servicesError);
				}
        if(serviceProvided2 === 'NO' && services && services.length>0){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICES_KEY + ' should be blank when Service Provided is NO');
        }
				break;
			case outcomeFormConstants.PLACED_TRAINING_KEY:
				const placedTraining = data[outcomeFormConstants.PLACED_TRAINING_KEY];
				if (placedTraining && !outcomeFormValidationConstants.PLACED_TRAINING_REGEX.test(placedTraining)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_TRAINING_ERROR);
				}
				break;
			case outcomeFormConstants.PLACED_DIRECT_KEY:
				const placedDirect = data[outcomeFormConstants.PLACED_DIRECT_KEY];
				if (placedDirect && !outcomeFormValidationConstants.PLACED_DIRECT_REGEX.test(placedDirect)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.PLACED_DIRECT_ERROR);
				}
				break;
			case outcomeFormConstants.CLASSROOM_HOURS_KEY:
				const classroomHours = data[outcomeFormConstants.CLASSROOM_HOURS_KEY];
				if (classroomHours && !outcomeFormValidationConstants.CLASSROOM_HOURS_REGEX.test(classroomHours)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.CLASSROOM_HOURS_ERROR);
				}
        // if(classroomHours === null || classroomHours === undefined || (classroomHours+"").trim() === ""){
        //   isValid = false;
				// 	errors.push(outcomeFormConstants.CLASSROOM_HOURS_KEY + ' is required');
        // }
				break;
      case outcomeFormConstants.ACTIVITY_ID_KEY:
      case outcomeFormConstants.FIRST_NAME_KEY:
      case outcomeFormConstants.LAST_NAME_KEY:
        break;
			default:
				isValid = false;
				errors.push('The column (' + key + ') is not recognized, please compare to downloadable template');
		}
	}

	if (templateKeys.length) {
		isValid = false;
		templateKeys.forEach( key => {
			errors.push('The column (' + key + ') is missing, please compare to downloadable template');
		});
	}

	return {
		isValid,
		errors
	};
};

export const validateOpcOutcome = (data: any) => {
	let isValid: boolean = true;
	const errors: string[] = [];

	// tslint:disable-next-line: forin
	for (const key in data) {
		switch(key) {
			case outcomeFormConstants.ACTIVITY_PARTICIPANT_ID_KEY:
				break;
			case outcomeFormConstants.SERVICE_PROVIDED_KEY:
				const serviceProvided = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
        if(!serviceProvided){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_PROVIDED_KEY + ' is required');
        }
        else{
          const serviceProvidedError = validateServiceProvided(serviceProvided);
          if (serviceProvidedError) {
            isValid = false;
            errors.push(serviceProvidedError);
          }
        }
				break;
			case outcomeFormConstants.SERVICE_START_DATE_KEY:
				const serviceStartDate = data[outcomeFormConstants.SERVICE_START_DATE_KEY]
        const serviceProvided1 = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
        if(serviceProvided1 === 'YES' && !serviceStartDate){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_START_DATE_KEY + ' is required when Service Provided is YES');
        }
				if (validateDate(serviceStartDate)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.SERVICE_START_DATE_ERROR);
				}
				break;
			case outcomeFormConstants.SERVICE_END_DATE_KEY:
				const serviceEndDate = data[outcomeFormConstants.SERVICE_START_DATE_KEY]
        const serviceProvided2 = data[outcomeFormConstants.SERVICE_PROVIDED_KEY]
        if(serviceProvided2 === 'YES' && !serviceEndDate){
          isValid = false;
					errors.push(outcomeFormConstants.SERVICE_END_DATE_KEY + ' is required when Service Provided is YES');
        }
				if (validateDate(serviceEndDate)) {
					isValid = false;
					errors.push(outcomeFormValidationConstants.SERVICE_END_DATE_ERROR);
				}
				break;
			case outcomeFormConstants.SERVICES_KEY:
				const servicesError = validateServices(data[outcomeFormConstants.SERVICES_KEY]);
				if (servicesError) {
					isValid = false;
					errors.push(servicesError);
				}
				break;
			default:
				isValid = false;
				errors.push('The column (' + key + ') is not recognized, please compare to downloadable template');
		}
	}

	return {
		isValid,
		errors
	};
};

const validateServiceProvided = (serviceProvided: string) => {
	if (serviceProvided && !outcomeFormValidationConstants.SERVICE_PROVIDED_REGEX.test(serviceProvided)) {
		return outcomeFormValidationConstants.SERVICE_PROVIDED_ERROR;
	}
	return;
};

const validateServices = (servicesStr: string) => {
	if (servicesStr) {
		const services = servicesStr.split(',').map((service: string) => service.trim())
		if (services && !services.every((service: string) => outcomeFormValidationConstants.SERVICES_REGEX.test(service))) {
			return outcomeFormValidationConstants.SERVICES_ERROR;
		}
	}
	return;
};
